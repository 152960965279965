<template>
  <input type="file" accept="image/*" ref="fileInput" style="display: none" @change="loadImg" />
  <input type="file" accept="image/*" capture="camera" ref="fileInputAosCamera" style="display: none" @change="loadImg" />
  <div>
    <div v-if="imgUploadingState == null && osType !== 'aos'" class="img_area img_upload_ios" @click="guidePopupOpen = true">
      <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
        <path d="M68 34C68 52.7777 52.7777 68 34 68C15.2223 68 0 52.7777 0 34C0 15.2223 15.2223 0 34 0C52.7777 0 68 15.2223 68 34Z" fill="#1E2352"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.458 33.9989C17.458 32.4764 18.5856 31.2422 19.9765 31.2422H48.9395C50.3304 31.2422 51.458 32.4764 51.458 33.9989C51.458 35.5215 50.3304 36.7557 48.9395 36.7557H19.9765C18.5856 36.7557 17.458 35.5215 17.458 33.9989Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9985 50.5391C32.4759 50.5391 31.2417 49.4115 31.2417 48.0205L31.2417 19.0576C31.2417 17.6666 32.4759 16.5391 33.9985 16.5391C35.521 16.5391 36.7552 17.6666 36.7552 19.0576L36.7552 48.0205C36.7552 49.4115 35.521 50.5391 33.9985 50.5391Z" fill="white"/>
      </svg>
    </div>
    <div v-else-if="imgUploadingState == null && osType === 'aos'" class="img_area img_upload_aos">
      <div class="aos_img_select_button" @click="guidePopupOpen = true; imgUploadType = 'aos_gallery'">
        <img class="aos_button_img" src="../../assets/images/input_images/face_img_upload_images/aos_gallery.svg" alt=""/>
        사진첩
      </div>
      <div class="aos_img_select_button" @click="guidePopupOpen = true; imgUploadType = 'aos_camera'">
        <img class="aos_button_img" src="../../assets/images/input_images/face_img_upload_images/aos_camera.svg" alt=""/>
        카메라
      </div>
    </div>

    <div v-else-if="imgUploadingState === 'loadingImg'" class="img_area img_loading">
      <img style="height: 60px; margin-bottom: 16px;" src="../../../box_result/assets/images/Loading.gif" alt=""/>
      사진 등록 중
    </div>

    <div v-else-if="imgUploadingState === 'callAPI'||'done'"
         :class="{'img_area':true, 'api_loading': imgUploadingState === 'callAPI'}">
      <canvas style="z-index: -1" ref="canvas"/>
      <Lottie v-if="imgUploadingState==='callAPI'" class="face_loading_gif" :style="{width:'calc(100vw - 80px)'}" :options="options"/>
      <img v-else-if="imgUploadingState==='done'" class="change_img_button" @click="triggerFileInput(this.imgUploadType)"
           src="../../assets/images/input_images/face_img_upload_images/change_img_button.png" alt=""/>
    </div>
    <div v-if="imgUploadingState==='done'" class="face_detection_check_text">
      이목구비가 잘 인식되었는지 확인해 주세요.
    </div>

    <div class="guide_text"
         @click="imgUploadingState === 'loadingImg' || imgUploadingState === 'callAPI' ? null : guidePopupOpen = true">
      관상 얼굴 사진 가이드 보기
      <img :class="imgUploadingState === 'loadingImg' || imgUploadingState === 'callAPI' ? 'disabled_guide_button' : 'able_guide_button'"
           :src="imgUploadingState === 'loadingImg' || imgUploadingState === 'callAPI' ? require('@/tarot_box/pages/box_input/assets/images/input_images/face_img_upload_images/disabled_img_guide_button.png') : require('@/tarot_box/pages/box_input/assets/images/input_images/face_img_upload_images/able_img_guide_button.png')" alt=""/>
    </div>

    <FaceImgGuidePopup style="z-index: 3" v-if="guidePopupOpen" @close="guidePopupOpen = false" @uploadImg="triggerFileInput(this.imgUploadType)"/>
    <FaceImgUploadTypeSelectPopup style="z-index: 3" v-if="imgUploadTypeSelectPopupOpen" @close="imgUploadTypeSelectPopupOpen = false" @uploadImg="triggerFileInput"/>
    <AlertPopup style="z-index: 3" v-if="alertPopupOpen" @close="alertPopupOpen = false; alertMsg = null; imgUploadingState = null"
    :msg="alertMsg"/>
  </div>
</template>

<script>
import FaceImgGuidePopup from "../input_modules/FaceImgGuidePopup.vue";
import Lottie from 'vue-lottie';
import * as animationData from '@/tarot_box/pages/box_input/assets/images/input_images/face_img_upload_images/face_analysis.json';
import FaceImgUploadTypeSelectPopup from "../input_modules/FaceImgUploadTypeSelectPopup.vue";
import {getFaceDataByFetch, uploadImageFile} from "@/tarot_box/helper/mytarot_api";
import AlertPopup from "../input_modules/AlertPopup.vue";

export default {
  mounted() {
    this.osType = new URLSearchParams(window.location.search).get("os_type");
    if (this.initialInputData !== "") {
      const inputData = JSON.parse(this.initialInputData);
      this.imgUrl = inputData.image_url;
      this.initializeCanvas();
      this.imgUploadingState = 'callAPI';
    }
  },
  components: {AlertPopup, FaceImgUploadTypeSelectPopup, FaceImgGuidePopup, Lottie},
  props: {
    initialInputData: null,
  },
  emits: ['onInputDataChange'],
  data() {
    return {
      alertMsg: String,
      osType: null,
      imgUploadType: null,
      imgUploadingState: null,
      options: {
        animationData: animationData,
        loop: false,
        autoplay: true,
        animationSpeed: 1
      },
      imgUploadTypeSelectPopupOpen: false,
      guidePopupOpen: false,
      alertPopupOpen: false,
      imgUrl: null,
      imgResizeScale: null,
      faceData: null,
    };
  },
  watch: {
    async imgUploadingState(newValue) {
      let outputData;

      switch (newValue) {
        case null:
        case 'loadingImg':
          this.$emit('onInputDataChange', "");
          break;

        case 'callAPI':
          this.$emit('onInputDataChange', "");
          this.faceData = await getFaceDataByFetch(this.imgUrl);
          if (this.faceData.result === "fail") {
            this.alertMsg = this.faceData.message;
          } else if (this.faceData.result === "success") {
            this.imgUploadingState = 'done';
          } else {
            console.error("face detection api 호출 결과 오류");
            this.alertMsg = "알 수 없는 오류가 발생했어요. 다시 시도해주세요.";
          }
          break;

        case 'done':
          this.drawDetectedPoint();
          this.imgUploadType = null;
          outputData = {
            "image_url": this.imgUrl,
            "face_classification_data": this.faceData.data.face_classification_data,
            "vertex_data": this.faceData.data.vertex_data,
          };
          this.$emit('onInputDataChange', JSON.stringify(outputData));
          break;
      }
    },
    alertMsg(newValue) {
      if (newValue !== null) {
        this.alertPopupOpen = true;
      }
    }
  },
  methods: {
    triggerFileInput(imgUploadType) {
      this.imgUploadType = imgUploadType;
      if (this.imgUploadType == null && this.osType === 'aos') {
        this.imgUploadTypeSelectPopupOpen = true;
      }
      else if (this.imgUploadType === 'aos_camera')
        this.$refs.fileInputAosCamera.click();
      else
        this.$refs.fileInput.click();
    },
    async loadImg(event){
      if(this.handleFileUpload(event) === false){
        this.imgUploadingState = null;
        this.guidePopupOpen = false;
        this.imgUploadTypeSelectPopupOpen = false;
        return false;
      }

      this.imgUploadingState = 'loadingImg';
      this.guidePopupOpen = false;
      this.imgUploadTypeSelectPopupOpen = false;
    },
    handleFileUpload(event) {
      if(!event.target.files || event.target.files.length === 0) {
        event.target.value = "";
        return false;
      }
      const file = event.target.files[0];
      const maxFileSize = 10 * 1024 * 1024; // 10MB

      if (file.size > maxFileSize) {
        this.alertMsg= "사진 용량이 커요! 10MB 이하의 사진을 올려주세요.";
        event.target.value = "";
        return false;
      }

      this.removeExifData(file)
          .then((blob) => {
            uploadImageFile(blob)
                .then((res) => {
                  this.imgUrl = res.data;
                  this.initializeCanvas();
                  this.imgUploadingState = 'callAPI';
                  event.target.value = "";
                })
                .catch((err) => {
                  console.error(err);
                  this.alertMsg = "사진을 불러오지 못했어요. 다시 시도해주세요.";
                  event.target.value = "";
                });
          })
          .catch((err) => {
            console.error(err);
            this.alertMsg = "사진을 불러오지 못했어요. 다시 시도해주세요.";
            event.target.value = "";
          });
    },
    removeExifData(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
              resolve(blob);
            }, file.type);
          };
        };

        reader.onerror = (err) => {
          reject(err);
        };

        reader.readAsDataURL(file);
      });
    },
    initializeCanvas() {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = this.imgUrl;
        image.onload = () => {
          const canvas = this.$refs.canvas;
          const imgArea = document.querySelector('.img_area');
          const maxWidth = imgArea.offsetWidth;
          const maxHeight = imgArea.offsetHeight;

          this.imgResizeScale = Math.max(maxWidth / image.width, maxHeight / image.height);

          canvas.width = image.width * this.imgResizeScale;
          canvas.height = image.height * this.imgResizeScale;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          resolve();
        };
      });
    },
    drawDetectedPoint() {
      for (let key in this.faceData.data.vertex_data) {
        const vertex = this.faceData.data.vertex_data[key];
        const x = vertex.x * this.imgResizeScale;
        const y = vertex.y * this.imgResizeScale;
        this.drawPoint(x, y, "#656FDE");
      }
    },
    drawPoint(x, y, color) {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(x, y, 3, 0, 2 * Math.PI);
      ctx.fill();
    },
  },
};
</script>

<style>
.img_area {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: calc(100vw - 40px);
  max-height: calc(var(--vh, 1vh) * 100 - 90px - 60px - 74px - 94px - 78px - 20px);
  border-radius: 12px;
  overflow: hidden;
}

.img_upload_ios {
  box-shadow: 0 0 0 +1.5px #1E2352 inset;
  background: #EDEEFA;
}

.img_upload_aos {
  flex-flow: row nowrap;
  justify-content: center;
  gap: 16px;

  color: #1E2352;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.aos_img_select_button{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 11px;

  width: 114px;
  height: 114px;

  border-radius: 12px;
  border: +0.5px solid #DDD;
  background: #FFF;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
  color: #1E2352;
}

.img_loading {
  box-shadow: 0 0 0 1px #BEBEBE inset;
  background: rgba(0, 0, 0, 0.26);

  color: #FFF;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.api_loading {
  box-shadow: 0 0 0 1px #BEBEBE inset;
  background: rgba(0, 0, 0, 0.68);
}

.face_loading_gif {
  position: absolute;
}

.guide_text {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 64px;

  color: #7F7F7F;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.disabled_guide_button {
  height: 22px;
  padding: 6px 4px 6px 4px;
  margin-left: 6px;
}

.able_guide_button {
  height: 30px;
  padding-top: 4px;
  margin-left: 6px;
}

.change_img_button {
  position: absolute;
  width: 46px;
  right: 12px;
  bottom: 12px;
}

.face_detection_check_text {
  color: #F65D5D;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;

  margin: 16px 0 -30px 0;
}
</style>
