<template>
  <div class="content">
    <div class="section">
      <div class="header title3" style="justify-content: space-between">
        결제 정보
      </div>
      <div class="box-info-item">
        <ShimmerImage :src="thumbnailUrl"
                      :imageStyle="{ width: '60px', border: '1px solid #e0e0e0' }"
                      :heightRatio="1.3788"/>

        <div class="text-area">
          <h3 class="title5" v-html="parseText(boxData.title)"/>
          <span class="text3">{{ boxData.subTitle }}</span>
        </div>
      </div>
      <hr>
      <div class="buying-price">
        <div class="header title4">
          결제 금액
        </div>
        <div class="price">
          <span id="bigger-price">{{ addComma(boxData.originalPriceWon) }}원</span>
          <span id="smaller-price">{{ addComma(boxData.salePriceWon) }}원</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";

export default({
  name: "CheckoutItemInfo",
  components: {ShimmerImage,},
  props: {
    boxData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    thumbnailUrl() {
      return this.boxData && this.boxData.thumbnailImgUrl
          ? this.boxData.thumbnailImgUrl
          : 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/thumbnail_default.jpg?alt=media&token=cbd4c705-25c2-467c-a105-c48e0687e3a2';
    },
  },
  methods: {
    parseText(text) {
      if (text) {
        return text.replace(/\\n/g, '<br>');
      } else {
        return '';
      }
    },
    addComma(price) {
      if (price === null || price === undefined || isNaN(Number(price))) {
        // 유효하지 않은 값 처리
        return '0'; // 또는 오류 처리 로직
      }
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
})
</script>


<style scoped>
.content {
  width: 100%;
  overflow-y: auto; /* 내용이 넘칠 경우 스크롤 허용 */
  display: flex;
  flex-direction: column;
  min-height: 200px;
  justify-content: flex-start;
  align-items: center;

}
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 24px;
  box-sizing: border-box;
}
hr{
  height: 1px;
  width: 100%;
  border: 0;
  border-top: 0.5px solid var(--Grayscale-Gray7);
}
.box-info-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  text-align: left;
  gap: 8px; /* 영역 사이의 간격 */
  box-sizing: border-box;
  margin: 16px 0;

}
.buying-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 16px 0;
}

.price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

#bigger-price{
  color: rgba(30, 35, 82, 0.50);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  text-decoration: line-through;
}

#smaller-price{
  color: #1E2352;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}
</style>