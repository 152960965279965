<template>
  <div class="page">
    <header/>
    <div class="contents">
      <div class="contentsHeader">
        <div class="headerLabel" v-html="this.$addLineBreak(currentStepData.inputFormHeaderLabel)"></div>
        <PageIndex :index="currentIndex" :max_index="totalStepNumber"></PageIndex>
      </div>
      <div class="question" v-html="this.$addLineBreak(currentStepData.inputFormQuestion)"></div>
      <div class="inputForm">
        <div v-if="currentStepData.inputFormType==='ShortTextField'">
          <ShortTextField :msg="currentStepData.inputFieldPlaceholder" :initialInputData="currentStepData.value" :maxInputLength="currentStepData.maxLength"
                          @onInputDataChange="handleInputData"/>
        </div>
        <div v-else-if="currentStepData.inputFormType==='LongTextField'">
          <LongTextField :language="language" :msg="currentStepData.inputFieldPlaceholder" :initialInputData="currentStepData.value" :maxInputLength="currentStepData.maxLength"
                         @onInputDataChange="handleInputData"/>
        </div>
        <div v-else-if="currentStepData.inputFormType==='GenderGrid'">
          <GenderGrid :initialInputData="currentStepData.value"
                      @onInputDataChange="handleInputData"/>
        </div>
        <div v-else-if="currentStepData.inputFormType==='GenderBirthGridWithCity'">
          <GenderBirthGridWithCity :initialInputData="currentStepData.value"
                           @onInputDataChange="handleInputData"/>
        </div>
        <div v-else-if="currentStepData.inputFormType==='MbtiGrid'">
          <MbtiGrid :initialInputData="currentStepData.value"
                    @onInputDataChange="handleInputData"/>
        </div>
        <div v-else-if="currentStepData.inputFormType==='ZodiacGrid'">
          <ZodiacGrid :initialInputData="currentStepData.value"
                      @onInputDataChange="handleInputData"/>
        </div>
        <div v-else-if="currentStepData.inputFormType==='BirthGrid'">
          <BirthGrid :initialInputData="currentStepData.value"
                     @onInputDataChange="handleInputData"/>
        </div>
        <div v-else-if="currentStepData.inputFormType==='FaceImgUpload'">
          <FaceImgUpload :initialInputData="currentStepData.value"
                         @onInputDataChange="handleInputData"/>
        </div>
      </div>
    </div>
    <div class="input-footer">
      <PreButton :language="language" v-wave :index="currentIndex" @prev="preStage"> </PreButton>
      <NextButton :language="language" v-wave @next="nextStage" :draw-tarot-card="drawTarotCard" :currentStep="currentIndex" :totalStep="totalStepNumber" :able="currentStepData.buttonAble"></NextButton>
    </div>
  </div>
</template>

<script>
import PreButton from "./PreButton.vue";
import PageIndex from "./PageIndex.vue";
import NextButton from "./NextButton.vue";
import ShortTextField from "../input_form_types/ShortTextField.vue";
import LongTextField from "../input_form_types/LongTextField.vue";
import GenderGrid from "../input_form_types/GenderGrid.vue";
import MbtiGrid from "../input_form_types/MbtiGrid.vue";
import ZodiacGrid from "../input_form_types/ZodiacGrid.vue";
import BirthGrid from "../input_form_types/BirthGrid.vue";
import FaceImgUpload from "../input_form_types/FaceImgUpload.vue";
import GenderBirthGridWithCity from "@/tarot_box/pages/box_input/components/input_form_types/GenderBirthGridWithCity.vue";

export default {
  components: {
    GenderBirthGridWithCity,
    FaceImgUpload,
    BirthGrid,
    ZodiacGrid,
    MbtiGrid,
    GenderGrid,
    LongTextField,
    ShortTextField,
    PreButton,
    PageIndex,
    NextButton,
  },
  props: {
    language: {
      type: String,
      default: "ko",
    },
    totalStepNumber: Number,
    currentIndex: Number,
    currentStepData: {
      key: '',
      value: '',
      buttonAble: false,
      inputFormType: '',
      inputFormHeaderLabel: '',
      inputFormQuestion: '',
      inputFieldPlaceholder: '',
      minLength: 1,
      maxLength: 20,
    },
    drawTarotCard: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    nextStage(){
      this.$emit('next');
    },
    preStage(){
      this.$emit('prev');
    },
    closePage(){
      this.$emit('close');
    },
    handleInputData(inputData){
      this.$emit('onInputDataChange', this.currentIndex-1, inputData);
    },
  },
}
</script>

<style scoped>
.page{
  display:flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100vw;
  height: 100;

  background: #FAFAFA;
}

header{
  top: 0;
  height: 54px;
}

.contents{
  margin-bottom: 66px; /* footer height */
  max-width: 100vw;
  max-height: calc(var(--vh, 1vh) * 100 - 54px - 66px);

  flex:1;
  display:flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 0 20px 0 20px;
  z-index: 2;
}

.contentsHeader{
  display:flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: calc(100vw - 40px);
  height: 60px;
}

.headerLabel{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  word-break: keep-all;

  color: #1E2352;
}

.question{
  display:flex;
  padding-bottom: 20px;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 22px;
  text-align: left;
  word-break: keep-all;

  color: #000000;
}

.inputForm{
  height: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #d9d9d9;
}

.input-footer{
  position: fixed;
  bottom: 0;
  width: calc(100vw - 40px);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  background: rgba(255, 255, 255, 0.65);
  z-index: 100;
}
</style>
