<script>
export default {
  name: 'PreButton',
  props: {
    language: {
      type: String,
      default: "ko"
    },
    index: Number,
  },
  data(){
    return{
      pre_button_text:{
        ko: "이전",
        en: "Back",
      },
    }
  },
  methods:{
    handleClick(){
      this.$emit('prev');
    }
  }
}
</script>

<template>
<div class="button" v-if="index !==1" @click="handleClick">
  {{pre_button_text[language]}}
</div>
</template>

<style scoped>
.button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 40px;
  margin-right: 10px;

  border-radius: 8px;
  border: 1px solid #1E2352;
  background: #F4F4F4;

  color: #1E2352;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
}
</style>