<script>
export default {
  name: 'ShortTextField',
  mounted() {
    if(this.msg === ""){
      this.placeholderMsg = `${this.maxInputLength}자 이내로 입력해주세요`;
    } else {
      this.placeholderMsg = this.msg;
    }
  },
  props: {
    msg: String,
    initialInputData: String,
    maxInputLength: Number,
  },
  data() {
    return {
      placeholderMsg: "",
      input: this.initialInputData || '',
      exceed: 0
    };
  },
  watch: {
    input() {
      if(this.input.length > this.maxInputLength){
        this.exceed = 1;
      } else {
        this.exceed = 0;
      }
      this.$emit('onInputDataChange', this.input);
    }
  }

}
</script>

<template>
  <div class="text_field">
    <input type="text"
           v-model="input"
           :placeholder="placeholderMsg"/>
  </div>
  <div class="warning" v-if="exceed">{{maxInputLength}}자까지 입력할 수 있어요</div>
</template>

<style scoped>
.text_field {
  display: flex;
  width: 100%;
  height: 49px;

  border-bottom: 1px solid #BEBEBE;
}

input{
  display: flex;
  width: 100%;
  height: 47px;
  margin: 0 4px 0 4px;

  outline:none;
  border:none;
  background: none;


  font-style: normal;
  color: #000;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

input::placeholder {
  color: #8F8F8F;
  font-size: 16px;
  font-weight: 400;
}

.warning{
  padding-top: 16px;

  color: #FF6060;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  text-align: left;
}

</style>