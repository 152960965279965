<template>
  <div class="screen">
    <Loading v-model:active="isLoading"
             loader="dots"
             color="#1E2352"
             :opacity="1"
             :z-index="99">
      <template v-slot:default>
        <div class="custom-loader">
          <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/logo.svg')" alt="loader" class="loader-img"/>
          <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/moving.svg')" alt="moving" class="moving-svg"/>
        </div>
      </template>
    </Loading>
    <AlarmTimeSettingPopup
      :isActive="isAlarmPopupOpen"
      :initialTime="localAlarmTime"
      @save="setAlarmTime"
      @close="goInputPage"
    />
    <div class="wrapper">
      <div class="cover">
        <div class="back-button" @click="goToHomeTab">
          <img :src="require('@/tarot_box/pages/box_input_v2/assets/x-button-s.svg')" alt="back"/>
        </div>
      </div>
      <div class="cta" @click="handleCTA">{{ ctaButtonText }}</div>
      <div class="stamp">
        <div class="title2">출석도장</div>
        <div class="text3 gray6">오늘의 운세를 보면 출석 체크가 돼요</div>
        <div class="daily-stamp">
          <div v-for="(stamp, index) in stamps" :key="index" class="stamp-box">
            <div class="icon" :style="{ backgroundImage: `url(${getStampBackground(index)})` }">
              <div class="title2">{{ stamp }}</div>
              <img :src="require('@/tarot_box/pages/today_fortune/assets/clover-s.svg')" alt="stamp-clover"/>
            </div>
            <div class="text3">{{ index + 1 }}회차</div>
          </div>
        </div>
      </div>
      <div class="scores">
        <div class="title2" style="margin-bottom: 10px;">누적 출석</div>
        <div class="score">
          <div class="text1">출석 횟수</div>
          <div class="title3">{{totalRewardDays}}회</div>
        </div>
        <div class="score">
          <div class="text1">받은 클로버</div>
          <div class="title3">
            {{totalReward}}
            <img :src="require('@/tarot_box/pages/today_fortune/assets/clover-s.svg')" alt="clover"/>
          </div>
        </div>
      </div>
      <div class="disclaimer">
        <div class="title2 gray6">꼭 확인해주세요!</div>
        <ul>
          <li>본 이벤트는 사용자에게 별도의 고지 없이 변동, 종료될 수 있습니다.</li>
          <li>오늘의 운제 콘텐츠를 모두 보면 추석으로 인정되어 클로버를 지급합니다.</li>
          <li>1일 1회만 출석으로 인정되며, 부적절한 방법으로 출석을 조작할 경우 서비스 이용약관에 의거하여 불이익이 발생할 수 있습니다.</li>
          <li>출석 이벤트로 수령한 클로버는 환불의 대상이 되지 않습니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMyDailyFortune,
  getTarotBoxItemsByFetch,
  getTarotUserInfo,
  updateReminderTime
} from "@/tarot_box/helper/mytarot_api";
import {debounce} from "lodash";
import AlarmTimeSettingPopup from "@/tarot_box/pages/today_fortune/components/AlarmTimeSettingPopup.vue";
import Loading from "vue-loading-overlay";
import {goToMyTarotTab} from "@/common/app_webview_handler";
import {mapActions} from "vuex";
import {hideBottomBar} from "@/tarot_box/helper/native_api";

export default {
  name: "TodayFortune",
  inject: ['appListener', 'appCommander'],
  components: {Loading, AlarmTimeSettingPopup},
  data() {
    return {
      userId: 0,
      userPhoneNumber: '',
      buildNumber: 84,
      osType: null,
      stamps: [1,1,1,1,1],
      currentDay: 1,
      todayStepStatus: '',
      dailyTarotBox: null,
      targetDailyFortuneTarotBoxId: 320,
      stampImages: {
        done: require('@/tarot_box/pages/today_fortune/assets/stamp-done.svg'),
        today: require('@/tarot_box/pages/today_fortune/assets/stamp-today.svg'),
        undone: require('@/tarot_box/pages/today_fortune/assets/stamp-undone.svg')
      },
      totalReward: 0,
      totalRewardDays: 0,
      localAlarmTime: this.getCurrentTimeRoundedDown(),
      isAlarmAllowed: false,
      isAlarmPopupOpen: false,
      setAlarmTimeSubscription: null,
      isLoading: true,
      isUpdatingisUpdating: false,
    }
  },
  async mounted() {
    hideBottomBar();
    this.isLoading = true;
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    this.userId = parseInt(urlParams.get('tarot_user_id'));
    this.userPhoneNumber = urlParams.get('phone_number');
    this.osType = urlParams.get('os_type');
    this.buildNumber = parseInt(urlParams.get('build_number'));
    await this.getUserInfo();
    await this.getDailyFortuneInfo();
    this.getBoxItems();
  },
  unmounted() {
    this.unsubscribeUpdateAlarmTime();
  },
  computed: {
    ctaButtonText() {
      switch(this.todayStepStatus) {
        case 'finished':
          return '운세 결과 보러가기';
        case 'requested':
          return '운세 결과 보러가기';
        case 'paid_and_waiting':
          return '운세 결과 보러가기';
        default:
          return '운세 보러 가기';
      }
    }
  },
  methods: {
    ...mapActions([
      'setTarotBoxesData',
      'setPromotionBannersData',
      'setIconCategoriesData',
      'setHomeSectionsData',
      'setRankingSubjectTitlesData',
      'setDailyTarotBoxData'
    ]),
    async getDailyFortuneInfo(){
      const res = await getMyDailyFortune(this.userPhoneNumber, this.userId, this.buildNumber);
      if (res.result === 'success'){
        this.currentDay = res.data.today_step;
        this.todayStepStatus = res.data.today_step_status;
        this.stamps = res.data.reward_list;
        this.totalReward = res.data.acc_reward_clover;
        this.totalRewardDays = res.data.acc_count;
        this.dailyTarotBox = res.data.daily_fortune_tarot_box;
        this.targetDailyFortuneTarotBoxId = this.dailyTarotBox.id;
        console.log('setting_up_daily_tarot_box')
        this.setDailyTarotBoxData(res.data.daily_fortune_tarot_box);
        this.isLoading = false;

      }
    },
    async getUserInfo() {
      const res = await getTarotUserInfo(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        if (res.data.reminder_time !== null && res.data.reminder_time !== 'off') {
          this.isAlarmAllowed = true;
          this.localAlarmTime = this.convertTo12HourFormat(res.data.reminder_time);
        }
      }
    },
    convertTo12HourFormat(time24) {
      const [hours, minutes] = time24.split(':');
      const hour = parseInt(hours, 10);
      const ampm = hour >= 12 ? '오후' : '오전';
      const hour12 = hour % 12 || 12;
      return `${ampm} ${String(hour12).padStart(2, '0')}:${minutes}`;
    },
    async getBoxItems() {
      try {
        let isWeb = this.$isWeb();
        let res = await getTarotBoxItemsByFetch(this.userPhoneNumber, this.userId, isWeb);
        if (res.result === 'success'){
          const updatedTarotBoxes = res.data.tarot_boxes.filter(box => box.id !== this.dailyTarotBox?.id);
          if (this.dailyTarotBox) {
            updatedTarotBoxes.push(this.dailyTarotBox);
          }
          this.setTarotBoxesData(updatedTarotBoxes);
          this.setPromotionBannersData(res.data.promotion_banners);
          this.setIconCategoriesData(res.data.icon_categories);
          this.setHomeSectionsData(res.data.home_sections);
          this.setRankingSubjectTitlesData(res.data.ranking_subject_titles);
          this.totalCarousel = res.data.promotion_banners.length;
        }
      } catch (error){
        console.error("Error in refreshItems:", error);
      }
    },
    getStampBackground(index) {
      if (index < this.currentDay - 1 || (index === this.currentDay - 1 && this.todayStepStatus === 'finished')) {
        return this.stampImages.done;
      } else if (index === this.currentDay - 1) {
        return this.stampImages.today;
      } else {
        return this.stampImages.undone;
      }
    },
    getCurrentTimeRoundedDown() {
      const now = new Date();
      const hours = now.getHours();
      const ampm = hours >= 12 ? '오후' : '오전';
      const formattedHours = hours % 12 || 12;
      return `${ampm} ${String(formattedHours).padStart(2, '0')}:00`;
    },
    openAlarmTimePopup() {
      this.isAlarmPopupOpen = true;
    },
    closeAlarmTimePopup() {
      this.isAlarmPopupOpen = false;
    },
    setAlarmTime({ time12, time24 }) {
      this.unsubscribeUpdateAlarmTime();
      this.setAlarmTimeSubscription = this.appListener.subscribeSingleAction('set_noti_permission', async (result) => {
        if (result.result === true) {
          this.localAlarmTime = time12;
          await this.updateAlarmSettingsDebounced(time24);
          this.closeAlarmTimePopup();
        } else {
          this.closeAlarmTimePopup();
          this.navigateToInputPage();
        }
      });
      window.callNativeMethod(`{"action": "alarm"}`);
    },
    goToHomeTab() {
      this.appCommander.callAppAction('select_tab', {tab: 0});
    },
    unsubscribeUpdateAlarmTime() {
      this.setAlarmTimeSubscription?.unsubscribe();
    },
    updateAlarmSettingsDebounced: debounce(async function(time) {
      console.log(time)
      this.isUpdating = true;
      try {
        let res = await updateReminderTime(this.userPhoneNumber, this.userId, time);
        if (res.result === 'success') {
          this.isAlarmAllowed = true;
          this.navigateToInputPage();
        } else {
          console.error('Failed to update reminder time:', res);
        }
      } catch (error) {
        console.error('Error updating reminder time:', error);
      } finally {
        this.isUpdating = false;
        this.isAlarmPopupOpen = false;
      }
    }, 300),
    navigateToInputPage() {
      const insetTop = new URL(window.location.href).searchParams.get("inset_top");
      const insetBottom = new URL(window.location.href).searchParams.get("inset_bottom");
      this.$router.push(`/tarot_box/input_v2/${this.targetDailyFortuneTarotBoxId}?tarot_user_id=${this.userId}&phone_number=${this.userPhoneNumber}&os_type=${this.osType}&build_number=${this.buildNumber}&inset_top=${insetTop}&inset_bottom=${insetBottom}`)
    },
    handleCTA() {
      if (this.isUpdating) return;

      if(this.todayStepStatus === 'finished' || this.todayStepStatus === 'requested'|| this.todayStepStatus === 'paid_and_waiting'){
        goToMyTarotTab();
        return;
      }
      if(this.isAlarmAllowed) {
        this.checkSystemNotiPermission();
      } else {
        this.openAlarmTimePopup();
      }
    },
    checkSystemNotiPermission() {
      this.unsubscribeUpdateAlarmTime();
      this.setAlarmTimeSubscription = this.appListener.subscribeSingleAction('set_noti_permission', async (result) => {
        if (result.result === true) {
          this.navigateToInputPage();
        } else {
          this.navigateToInputPage();
        }
      });
      window.callNativeMethod(`{"action": "alarm"}`);
    },
    goInputPage(){
      const insetTop = new URL(window.location.href).searchParams.get("inset_top");
      const insetBottom = new URL(window.location.href).searchParams.get("inset_bottom");
      this.$router.push(`/tarot_box/input_v2/${this.targetDailyFortuneTarotBoxId}?tarot_user_id=${this.userId}&phone_number=${this.userPhoneNumber}&os_type=${this.osType}&build_number=${this.buildNumber}&inset_top=${insetTop}&inset_bottom=${insetBottom}`)
    }
  }
}
</script>

<style scoped>
.screen {
  width: 100vw;
  height: 100dvh;
  max-width: 480px;
  max-height: 1024px;
  margin: 0 auto;
  background-color: #fff;
}

.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.cover {
  display: block;
  width: 100vw;
  max-width: 480px;
  height: calc( (100vw / 720 * 620) + 40px );
  max-height: 460px;
  padding-top: 40px;
  box-sizing: border-box;
  background: #f3f5ec;
  background-image: url("@/tarot_box/pages/today_fortune/assets/fortune-cover.jpg");
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
}

.back-button {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 40px;
  left: 20px;
}

.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 48px);
  height: 60px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  background: var(--Color-palette-Primary-Default);
  color: #FFFFFF;
  border-radius: 8px;
  margin: 24px;
  box-sizing: border-box;
}

.stamp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 48px);
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  border: 1px solid var(--Grayscale-Gray11);
  background: #fff;
  gap: 4px;
}

.stamp-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daily-stamp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.icon{
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-top: 12px;
  background-size: cover;
  background-position: center;
}

.scores{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 48px);
  margin: 40px;
}
.score{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 16px 0;
  box-sizing: border-box;
}

.score:last-child {
  border-top: 1px solid var(--Grayscale-Gray10);
}

.disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  background: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray6);
  padding: 60px 16px 80px 16px;
  width: 100%;
  box-sizing: border-box;
}

.disclaimer ul {
  padding: 0 0 0 24px;
  margin: 0;
}

.disclaimer li {
  text-align: left;
  line-height: 150%;
}

</style>
