<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div v-if="alreadyMember" class="confirm-popup-contents-title">
          {{ name }} 님<br> 다시 돌아오셨군요!
        </div>
        <div v-else class="confirm-popup-contents-title">
          {{ name }} 님<br>환영합니다!
        </div>
        <div class="confirm-popup-contents-delete">
          함께 고민을 해결해봐요
        </div>
      </div>
      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="closePopup()">
          입장하기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
  },
  emits: ['go-home'],
  props: {
    alreadyMember: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '하하',
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    closePopup() {
      this.$emit('go-home');
    },
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
  z-index: 1001;
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 16px;
}

.confirm-popup-contents-title{
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.confirm-popup-contents-delete {
  display: block;
  text-align: center;
  line-height: 140%;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;

  background: #1E2352;
  color: #FFF;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
}

.confirm-popup-button-gray {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray3);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}
</style>