<template>
  <div class="page">
    <div v-show="!imgLoad">
      <img class="loadingGif" src="../../../box_result/assets/images/Loading.gif" alt=""/>
    </div>

    <img v-show="imgLoad" :src="this.pageInfo.backgroundImage" class="backgroundImage" alt=""
         @load="backgroundImageLoad = true"/>
    <div v-show="imgLoad" class="contents" :style="contentsStyle">
      <div :style="spaceStyle"/>
      <img v-if="hasHeaderImg" class="headerImg" :style="headerImgStyle" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/tarot_box_page_images%2Fdaily_header.png?alt=media&token=1e4cde8e-8d24-4ad9-8aee-049bef4ffa85" alt=""/>
      <img v-if="this.pageInfo.imageObject" class="symbol" :style="symbolStyle" :src="this.pageInfo.imageObject" @load="onSymbolImageLoad" alt="">
    </div>
    <div class="footer">
      <StartButton v-wave :language="language" :able="buttonAble" @next="nextStage"/>
    </div>
  </div>
</template>


<script>
import StartButton from "./StartButton.vue";

export default {
  data() {
    const appHeaderHeight = 90;
    const footerHeight = 78;
    const fixedHeightValue = `calc(var(--vh, 1vh) * 100 - ${appHeaderHeight}px - ${footerHeight}px)`;

    return {
      appHeaderHeight,
      footerHeight,
      symbolWidth: 360,
      spacePerSymbolWidth: 51 / 360,
      fixedHeightValue,
      backgroundImageLoad: false,
      symbolImageLoad: false,
    };
  },
  components: {
    StartButton,
  },
  props: {
    language: {
      type: String,
      default: "ko"
    },
    hasHeaderImg: {
      type: Boolean,
      default: false
    },
    pageInfo: {
      imageObject: "",
      backgroundImage: "",
    },
    buttonAble: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'pageInfo.imageObject': {
      immediate: true,
      handler(newVal) {
        if (newVal === '') {
          this.symbolImageLoad = true;
        }
      }
    }
  },
  methods: {
    nextStage(){
      this.$emit('next');
    },
    closePage(){
      this.$emit('close');
    },
    onSymbolImageLoad() {
      this.symbolImageLoad = true;
    }
  },
  computed: {
    imgLoad() {
      return this.backgroundImageLoad && this.symbolImageLoad;
    },
    symbolHeightPerSymbolWidth() {
      return this.hasHeaderImg ? 429 / 360 : 558 / 360;
    },
    headerImgHeightPerSymbolWidth() {
      return this.hasHeaderImg ? 122 / 360 : 0 / 360;
    },
    contentsStyle(){
      return{
        marginTop: `${this.appHeaderHeight}px`,
        marginBottom: `${this.footerHeight}px`,

        maxWidth: `100vw`,
        maxHeight: `calc(var(--vh, 1vh) * 100 - ${this.appHeaderHeight}px - ${this.footerHeight}px)`,
      }
    },
    spaceStyle() {
      const spaceHeight = `calc(${this.fixedHeightValue} * (${this.spacePerSymbolWidth} / (${this.spacePerSymbolWidth} + ${this.symbolHeightPerSymbolWidth} + ${this.headerImgHeightPerSymbolWidth})))`;
      return {
        height: spaceHeight,
      };
    },
    headerImgStyle() {
      const maxHeightValue = `calc(${this.fixedHeightValue} * (${this.headerImgHeightPerSymbolWidth} / (${this.spacePerSymbolWidth} + ${this.symbolHeightPerSymbolWidth} + ${this.headerImgHeightPerSymbolWidth})))`;
      return {
        maxWidth: '100vw',
        maxHeight: maxHeightValue,
      };
    },
    symbolStyle() {
      const symbolMaxHeight = `calc(${this.fixedHeightValue} * (${this.symbolHeightPerSymbolWidth} / (${this.spacePerSymbolWidth} + ${this.symbolHeightPerSymbolWidth} + ${this.headerImgHeightPerSymbolWidth})))`;
      return {
        maxWidth: '100vw',
        maxHeight: symbolMaxHeight
      }
    },
  },
}

</script>

<style scoped>
.page{
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

.backgroundImage{
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.loadingGif{
  width: 60px;
  height: 60px;
}

.contents{
  align-self: center;
  display: grid;
  z-index: 1;
}

.footer {
  z-index: 1;
  position: fixed;
  bottom: 0;
}
</style>
