<template>
  <div class="detail-screen" v-safe-area-top>
    <div v-if="wrapperVisible" :class="['white-wrapper', { 'fade-out': !nonClickableWrapper }]"></div>
    <TarotDetailHeaderBar
        :onBack="closeTarotDetail"
        :isFirstLanding="isFirstLanding"
        @disableWrapper="disableWrapper"/>
    <div class="detail-content-area" ref="detailContentArea" v-safe-area-bottom>
      <TarotDetailTitleArea
          @image-loaded="onTitleImageLoaded"
          :selectedTarotBox="selectedTarotBox"/>

      <TarotDetailRatingsChart
          v-if="selectedTarotBox && selectedTarotBox.feedbackSection && selectedTarotBox.feedbackSection.includes('reaction') && showRatingsChart()"
          :selectedTarotBox="selectedTarotBox"/>

      <TarotDetailReviews
          v-if="selectedTarotBox && selectedTarotBox.feedbackSection && selectedTarotBox.feedbackSection.includes('review')"
          :selectedTarotBox="selectedTarotBox"/>

      <TarotDetailDescArea
          :image-loaded="isTitleImageLoaded"
          @content-too-long="setContentTooLong"
          :selectedTarotBox="selectedTarotBox"/>
      <TarotDetailTableOfContents
          :selectedTarotBox="selectedTarotBox"/>
      <TarotDetailScreenShots
          :image-loaded="isTitleImageLoaded"
          @reset-back-button="$emit('reset-back-button')"
          :selectedTarotBox="selectedTarotBox"/>
      <TarotDetailRelatedBoxList
          :tarot-box-ids="selectedTarotBox?.relatedBoxIds ? selectedTarotBox.relatedBoxIds.slice(0, 6) : []"/>
      <TarotDetailFooterForWeb/>

    </div>
    <TarotDetailStartButton
        :bigger-price="biggerPrice"
        :smaller-price="smallerPrice"
        @go-tarot-box-input-page="goTarotBoxInputPage"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TarotDetailHeaderBar from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailHeaderBar.vue";
import TarotDetailTitleArea from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailTitleArea.vue";
import TarotDetailRatingsChart from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailRatingsChart";
import TarotDetailDescArea from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailDescArea.vue";
import TarotDetailTableOfContents from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailTableOfContents.vue";
import TarotDetailScreenShots from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailScreenShots.vue";

import {
  hideBottomBar,
  logEvent,
} from "@/tarot_box/helper/native_api";
import TarotDetailRelatedBoxList
  from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailRelatedBoxList.vue";
import TarotDetailReviews from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailReviews/TarotDetailReviews";
import TarotDetailStartButton
  from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailStartButton.vue";
import TarotDetailFooterForWeb from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailFooterForWeb.vue";
import {getTarotBoxInputDataByFetch} from "@/tarot_box/helper/mytarot_api";

export default {
  name: "TarotDetailPage",
  components: {
    TarotDetailStartButton,
    TarotDetailReviews,
    TarotDetailRatingsChart,
    TarotDetailRelatedBoxList,
    TarotDetailHeaderBar,
    TarotDetailTitleArea,
    TarotDetailDescArea,
    TarotDetailTableOfContents,
    TarotDetailScreenShots,
    TarotDetailFooterForWeb,
  },
  data() {
    return {
      tarotBoxId: null,
      selectedTarotBox: null,
      wrapperFadeOut: true,
      nonClickableWrapper: true,
      wrapperVisible: true,
      isTitleImageLoaded: true,
      showButton: false,
      isContentTooLong: false,
      biggerPrice: 0,
      smallerPrice: 0,
      isFirstLanding: false,
      isInputPageVersionV2: false,
    };
  },
  props: {
    closeTarotDetail: {
      type: Function,
    },
  },
  mounted() {
    this.initTarotBoxDetailPage();
    this.addSafeAreaColorTop('--Grayscale-Gray12');
    this.addSafeAreaColorBottom('#FFFFFFFF');
    //find url query parameter 'first=true'
  },
  beforeUnmount() {
    const startButton = document.querySelector('.start-button');
    if(startButton.classList.contains('slide-up')){
      this.previousSafeAreaColorBottom();
    }
    // this.previousSafeAreaColorBottom(); // 타이밍 문제로 HomePage.vue에서 처리
    this.previousSafeAreaColorTop();
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'promotionBanners', 'iconCategories', 'homeSections']),
    highestReaction() {
      const stats = this.selectedTarotBox.reactionStats;
      if (!stats) return null;

      return Object.keys(stats).reduce((a, b) => stats[a] > stats[b] ? a : b);
    },
  },
  watch: {
    $route() {
      let whiteWrapper = document.querySelector('.white-wrapper');
      if (whiteWrapper) {
        whiteWrapper.style.transition = 'none';
        this.nonClickableWrapper = true;

        if (this.$refs.detailContentArea) {
          this.$refs.detailContentArea.scrollTop = 0;
        }

        setTimeout(() => {
          whiteWrapper.style.transition = 'opacity 0.5s ease-in-out';
        }, 0);
      }

      this.initTarotBoxDetailPage();
    },
  },
  methods: {
    ...mapActions(['setUser','setCategories', 'setPromotions', 'setRecommendTabs', 'setTarotBoxes', 'addSafeAreaColorTop','addSafeAreaColorBottom', 'previousSafeAreaColorTop','previousSafeAreaColorBottom']),
    initTarotBoxDetailPage() {
      hideBottomBar();
      this.tarotBoxId = parseInt(this.$route.query.tarot_box_id);
      this.selectedTarotBox = this.tarotBoxes.find((tarotBox) => tarotBox.id === this.tarotBoxId);
      this.getInputPageData();
      this.handleScroll();
      setTimeout(() => {
        if (this.isTitleImageLoaded) {
          this.nonClickableWrapper = false;
        }
      }, 300);
    },
    async getInputPageData() {
      this.isInputPageVersionV2 = false;
      if(this.tarotBoxId){
        console.log('tarotBoxId', this.tarotBoxId)
        let res = await getTarotBoxInputDataByFetch(this.tarotBoxId);
        if(res.inputVersion && res.inputVersion === 'v2'){
          this.isInputPageVersionV2 = true;
        }
      }
    },
    showRatingsChart() {
      const highest = this.highestReaction;
      return highest === '재밌어요' || highest === '도움 돼요';
    },
    determinePrice(originalPrice, salePrice) {
      if (salePrice && originalPrice) {
        this.smallerPrice = Math.min(salePrice, originalPrice);
        this.biggerPrice = Math.max(salePrice, originalPrice);
      } else if (!originalPrice) {
        this.smallerPrice = salePrice;
        this.biggerPrice = '';
      } else {
        this.smallerPrice = originalPrice;
        this.biggerPrice = '';
      }
    },
    recordStartEvent() {
      logEvent("snack_detail_start_click", {
        tarot_box_id: this.selectedTarotBox?.id, // 옵셔널 체이닝 적용
        tarot_box_name: this.selectedTarotBox?.title, // 옵셔널 체이닝 적용
        price: this.smallerPrice, // smallerPrice는 기존 코드에 따라 변경 없음
      })
    },
    goTarotBoxInputPage() {
      this.recordStartEvent();

      let inputHost = 'input/'
      if(this.isInputPageVersionV2){
        inputHost = 'input_v2/'
      }

      let queryParams = { ...this.$route.query };
      if (queryParams.is_web === 'true') {
        queryParams.os_type = 'web';
        delete queryParams.is_web;
        delete queryParams.tarot_box_id;
      }
      if(this.$isWeb()){
        this.$router.push({ path: `${inputHost}${this.tarotBoxId}`, query: queryParams });
      } else {
        this.$router.push({ path: `${inputHost}${this.tarotBoxId}`, query: queryParams });
      }
    },
    onTitleImageLoaded() {
      this.isTitleImageLoaded = true;
      if(this.$isWeb() === true){
        this.determinePrice(this.selectedTarotBox.originalPriceWon, this.selectedTarotBox.salePriceWon);
      } else {
        this.determinePrice(this.selectedTarotBox.originalPrice, this.selectedTarotBox.salePrice);
      }
      setTimeout(() => {
        if (this.isTitleImageLoaded) {
          this.nonClickableWrapper = false;
          if(this.selectedTarotBox.id) {
            if(this.$isWeb()) {
              window.fbq('track', 'ViewContent', {
                content_ids: [this.selectedTarotBox.id],
                content_type: 'product',
                value: this.smallerPrice,
                currency: 'KRW'
              });
              console.log('fbq track ViewContent');
            }
            logEvent('snack_detail_into', {
              "tarot_box_id": this.selectedTarotBox.id,
              'tarot_box_name': this.selectedTarotBox.title,
              'long_enough': this.isContentTooLong
            });
          }
        }
      }, 500);
    },
    handleScroll() {
      const startButton = document.querySelector('.start-button');
      if (this.showButton === false) {
        this.showButton = true;
        setTimeout(() => {
          this.addSafeAreaColorBottom('--Color-palette-Primary-Default');
          startButton.classList.add('slide-up');
        }, 500);
      }
    },
    setContentTooLong(value) {
      this.isContentTooLong = value;
    },
    disableWrapper() {
      this.wrapperVisible = false;
    },
  },
}
</script>

<style scoped>
.detail-screen {
  position: fixed;
  top: 0;
  right: -100%; /* 초기에는 화면 오른쪽 밖에 위치 */
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--Grayscale-Gray12);
  transition: transform 0.5s ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 55px;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
}

.detail-screen::-webkit-scrollbar {
  display: none;
}

.white-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 1; /* 초기 상태 */
  transition: opacity 0.5s ease-in-out;
  z-index: 10; /* 다른 컨텐츠보다 상위에 위치 */
}
.fade-out {
  opacity: 0; /* 사라지는 효과 */
  pointer-events: none;
}

.detail-content-area{
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  margin-bottom: 60px;
  overflow-x: hidden;
}


</style>

