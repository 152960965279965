import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import { initSentry } from '@/api/sentry-init';

import App from './App.vue'
import store from "@/tarot_box/helper/store";
import utils from "@/tarot_box/helper/utils";
import Constants from "@/tarot_box/pages/box_input/assets/constants";
import Vue3TouchEvents from "vue3-touch-events";
import VWave from "v-wave";
import vueDebounce from 'vue-debounce';
import {LoadingPlugin} from 'vue-loading-overlay';
import {isNewAppVersion} from "@/utils/appVersion";
import AppListenerPlugin from '@/common/app_listener_plugin';


import HomePage from "./pages/home/HomePage.vue";
import SignInPage from "@/sign_in/SignInPage.vue";
import MyPage from "@/my_page/MyPageV2.vue";
import MyPageV2 from "@/my_page/MyPage.vue";
import CloverPaywall from "@/paywall/CloverPaywall.vue";
import MateChatSubscriptionPaywall from "@/paywall/MateChatSubscriptionPaywall.vue";

import InputPageTemplate from "./pages/box_input/InputPageTemplate.vue";
import OutputPageTemplate from "./pages/box_result/OutputPageTemplate2.vue";
import MyTarotPage from "@/tarot_box/pages/my_tarot/MyTarotPage.vue";

import mitt from "mitt";
import WriteReviewPage from "@/tarot_box/pages/box_result/WriteReviewPage.vue";
import WriteReviewCompletePage from "@/tarot_box/pages/box_result/WriteReviewCompletePage.vue";
import WebCheckoutPage from "@/tarot_box/pages/web_checkout/pages/WebCheckoutPage.vue";
import WebCheckoutCompletePage from "@/tarot_box/pages/web_checkout/pages/WebCheckoutCompletePage.vue";
import EventPage from "@/tarot_box/pages/event_page/EventPage.vue";
import EventPage2 from "@/tarot_box/pages/event_page/EventPage2.vue";
import OutputPageTemplate2 from "@/tarot_box/pages/box_result/OutputPageTemplate2.vue";
import OutputPage from "@/tarot_box/pages/box_result_v2/OutputPage.vue";
import InputPage from "@/tarot_box/pages/box_input_v2/InputPage.vue";
import TodayFortune from "@/tarot_box/pages/today_fortune/TodayFortune.vue";


import BridgePage from "@/tarot_box/pages/labs/pages/BridgePage.vue";
import AmericaPage from "@/test/AmericaPage.vue";
import MasonMainPage from "@/mason/MasonMainPage.vue";


const routes = [
    { path: "/tarot_box/home", component: HomePage },
    { path: "/tarot_box/mytarot", component: MyTarotPage },
    { path: "/tarot_box/input/:boxId/:language?", component: InputPageTemplate },
    { path: "/tarot_box/input_v2/:boxId/:language?", component: InputPage},
    { path: "/tarot_box/result/:boxId/:language?", component: OutputPageTemplate },
    { path: "/tarot_box/today_tarot", component: TodayFortune },
    //for the test
    { path: "/tarot_box/result/:boxId/:language?", component: OutputPageTemplate2},
    { path: "/tarot_box/result_v2/:boxId/:language?", component: OutputPage},


    { path: "/tarot_box/review/:boxId", component: WriteReviewPage, meta: { fromPath: '' } },
    { path: "/tarot_box/review_complete", component: WriteReviewCompletePage, meta: { fromPath: '' }  },
    { path: "/new_result/:boxId", redirect: to => {
            return `/tarot_box/result/${to.params.boxId}`;}
    },
    { path: "/tarot_box/checkout/:boxId", component: WebCheckoutPage},
    { path: "/tarot_box/purchase_complete", component: WebCheckoutCompletePage},

    //TEMP
    { path: "/tarot_box/event", component: EventPage},
    { path: "/tarot_box/event2", component: EventPage2},
    { path: "/tarot_box/labs/240703", component: BridgePage},
    { path: "/us", component: AmericaPage},

    { path: "/mason", component: MasonMainPage},
    { path: "/signin", component: SignInPage},
    { path: "/mypage_v1", component: MyPage},
    { path: "/mypage_v2", component: MyPageV2},
    { path: "/paywall", component: CloverPaywall},
    { path: "/subpaywall", component: MateChatSubscriptionPaywall},


];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach((to, from, next) => {
    to.meta.fromPath = from.fullPath;
    next();
});

// vh 단위 문제 해결
export function updateVH() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

updateVH();

const app = createApp(App);

initSentry(app, router);


const eventBus = mitt();

//웹마이타로 분기처리 전역 디렉티브, 데이터
const WebDirective = {
    beforeMount(el) {
        const params = new URLSearchParams(window.location.search);
        const isWeb = params.get('is_web');
        const osType = params.get('os_type');
        if (isWeb !== 'true' && osType !== 'web') {
            el.style.display = 'none';
        }
    }
};
const AppDirective = {
    beforeMount(el) {
        const params = new URLSearchParams(window.location.search);
        const isWeb = params.has('is_web');
        const osType = params.get('os_type');
        if (isWeb || osType === 'web') {
            el.style.display = 'none';
        }
    }
};

app.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

//앱빌드 83번 이상 상하단 패딩 확보
export const vSafeAreaTop = {
    mounted(el) {
        if (isNewAppVersion()) {
            const urlParams = new URLSearchParams(window.location.search);
            const insetTop = parseInt(urlParams.get('inset_top') || '0', 10);

            if (insetTop > 0) {
                el.style.paddingTop = `calc(${el.style.paddingTop || '0px'} + ${insetTop}px)`;
            }
        }
    }
};


export const vSafeAreaBottom = {
    mounted(el) {
        if (isNewAppVersion()) {
            const urlParams = new URLSearchParams(window.location.search);
            const insetBottom = parseInt(urlParams.get('inset_bottom') || '0', 10);

            if (insetBottom > 0) {
                el.style.paddingBottom = `calc(${el.style.paddingBottom || '0px'} + ${insetBottom}px)`;
            }
        }
    }
};

app.config.globalProperties.$isWeb = () => {
    const params = new URLSearchParams(window.location.search);
    const isWeb = params.get('is_web') === 'true';
    const osType = params.get('os_type') === 'web';
    return isWeb || osType;
};

app.config.errorHandler = (err, vm, info) => {
    if (err instanceof TypeError && err.message.includes("null is not an object (evaluating '_.value.contains')")) {
        console.warn('Ignored TypeError from vue-bottom-sheet:', err.message);
        return;
    }
    if (err instanceof TypeError && err.message.includes("reading 'contains'")) {
        console.warn('Ignored TypeError from vue-bottom-sheet:', err.message);
        return;
    }
    // 다른 에러 처리
    console.error('Unhandled error:', err, info);
};


app.directive('web', WebDirective);
app.directive('app', AppDirective);
app.directive('safe-area-top', vSafeAreaTop)
app.directive('safe-area-bottom', vSafeAreaBottom)

app.use(router);
app.use(store);
app.use(utils);
app.provide("eventBus", eventBus);
app.use(Constants);
app.use(Vue3TouchEvents,{
    swipeTolerance: 50,
});
app.use(VWave, {
    color: '#000000',
    initialOpacity: 0.3,
    duration: 0.3,
    easing: 'ease-in',
});
app.directive('debounce', vueDebounce({ lock: true, defaultTime: '500ms' }));

app.use(LoadingPlugin);
app.use(AppListenerPlugin);
app.use(AppCommanderPlugin);

app.mount("#app");

console.info('app: ', app);

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import AppCommanderPlugin from "@/common/app_commander_plugin";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
