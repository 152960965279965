<template>
  <div class="validation-area">
    <div class="section country" style="display: none;">
      <span class="title5 gray4">국가</span>
      <CountrySelect
          @open-bottom-sheet="openBottomSheet"
          :selectedCountry="designatedCountry"
          @update:selectedCountry="updateSelectedCountry"
      />
    </div>
    <div class="section phone-number" style="width: 100%">
      <span class="title5 gray4">휴대폰 번호</span>
      <NumberInput
          :modelValue="phoneNumber"
          @update:modelValue="updatePhoneNumber"
          :input-item="{inputFormType: 'phone', inputFormSubtype: 'phone'}"
          :max-length="11"
          @submit-answer="handleEnter"
          placeholder="01012345678"
          ref="numberInput"/>
    </div>
  </div>
</template>

<script>
import NumberInput from "@/tarot_box/pages/box_input_v2/components/Inputs/NumberInput.vue";
import CountrySelect from "@/sign_in/components/CountrySelect.vue";

export default {
  name: "PhoneValidation",
  components: {NumberInput, CountrySelect},
  data() {
    return {
      phoneNumber: '',
      designatedCountry: {
        flag: '🇰🇷',
        code: '+82'
      }
    };
  },
  props: ['selectedCountry'],
  emits: ['open-bottom-sheet', 'update:selectedCountry', 'update:phoneNumber', 'phone-validated', 'focus', 'blur'],
  methods: {
    handleEnter() {
      this.validateAndEmit();
    },
    openBottomSheet(type) {
      this.$emit('open-bottom-sheet', type);
    },
    updateSelectedCountry(country) {
      this.$emit('update:selectedCountry', country);
    },
    updatePhoneNumber(newValue) {
      this.phoneNumber = newValue;
      this.validateAndEmit();
    },
    validateAndEmit() {
      const isValid = this.phoneNumber.startsWith('01') && this.phoneNumber.length >= 10;
      this.$emit('phone-validated', isValid);
      this.$emit('update:phoneNumber', this.phoneNumber);
    },
    focusNumberInput() {
      this.$refs.numberInput.$refs.input.focus();
    }
  }
}
</script>

<style scoped>
.validation-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
}

.section span{
  text-align: left;
  margin: 0;
}

.section input {
  width: 100% !important;
}
</style>