<script>
export default {
  name: 'PreResult',
  methods:{
    handleClick(){
      this.$emit('prev');
    }
  }
}
</script>

<template>
  <div class="button" @click="handleClick">
    <img src="../../assets/images/output_images/pre_result_button.png" alt="" />
  </div>
</template>

<style scoped>
.button{
  display: flex;
  width: 60px;
  height: 40px;
}
.button > img{
  height:16px;
  width:10px;
}
</style>