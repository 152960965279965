<template>
  <div class="modal" v-if="isActive">
    <div class="overlay" @click="closePopup"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          {{ isProcessing ? '구독내역을 복원중이에요' : '구독내역 복원이 완료되었어요' }}
        </span>
        <span class="text2" style="margin-bottom: 20px;">
          {{ isProcessing ? '구독 내역을 불러오고 있습니다\n잠시만 기다려주세요' : '구독내역을 불러왔어요\n구독 상태를 확인해보세요' }}
        </span>
      </div>
      <div class="popup-content">
        <img src="@/my_page/assets/withdrawalIcon.svg">
      </div>

      <div class="popup-footer">
        <div
            class="btn2"
            @click="closePopup"
            :style="buttonStyle"
        >
          {{ isProcessing ? '복원 중' : '확인' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestorePopup",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isProcessing: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['close'],
  computed: {
    buttonStyle() {
      return {
        flex: 1,
        background: this.isProcessing ? 'var(--Grayscale-Gray10)' : 'var(--Color-palette-Primary-Default)',
        color: this.isProcessing ? 'var(--Grayscale-Gray6)' : 'var(--Grayscale-Gray12)'
      }
    }
  },
  methods: {
    closePopup() {
      if (!this.isProcessing) {
        this.$emit('close');
      }
    },
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 50px 16px 0 16px;
  justify-content: space-between;
}

.popup-content{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.btn2{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray6);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}


</style>