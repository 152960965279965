<template>
  <div class="screen">
    <Loading v-model:active="isLoading"
             loader="dots"
             color="#1E2352"
             :opacity="1"
             :z-index="99">
      <template v-slot:default>
        <div class="custom-loader">
          <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/logo.svg')" alt="loader" class="loader-img"/>
          <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/moving.svg')" alt="moving" class="moving-svg"/>
        </div>
      </template>
    </Loading>
    <div class="header" v-safe-area-top>
      <div class="title1">{{titleText}}</div>
      <div class="text2 gray4">{{descriptionText}}</div>
    </div>
    <div class="content">
      <ConsentForm
          v-if="currentIndex === 0"
          @input="checkValidation"
          v-model="consentFormValid"/>
      <PhoneValidation
          v-show="currentIndex === 1"
          @open-bottom-sheet="openBottomSheet"
          @phone-validated="handlePhoneValidation"
          @focus="showBottom = false"
          @blur="showBottom = true"
          v-model:phoneNumber="phoneNumber"
          v-model:selectedCountry="selectedCountry"
          :selectedCountry="selectedCountry"
          ref="phoneValidation"/>

      <BottomSheet
          ref="bottomSheet"
          :type="bottomSheetType"
          :country-code="selectedCountry.code"
          :phone-number="phoneNumber"
          @submit-auth="finishAuth"
          @re-auth="startAuth"
          @country-selected="updateSelectedCountry"/>
      <NameRegistration
          ref="nameRegistration"
          @name-validated="handleNameValidation"
          @update:name="updateName"
          v-if="currentIndex === 2"/>
      <FinishPopup
          v-if="isFinishPopupActive"
          :already-member="alreadyMember"
          :name="name"
          @go-home="finalizeAuth"/>
    </div>
    <div class="toast" v-show="showToast" :class="{ 'show': showToast }">{{toastMessage}}</div>

    <div class="fixed-bottom" v-show="showBottom">
      <button :disabled="!consentFormValid" @click="submit">{{ buttonText }}</button>
    </div>
  </div>
</template>

<script>
import ConsentForm from "@/sign_in/components/ConsentForm.vue";
import PhoneValidation from "@/sign_in/components/PhoneValidation.vue";
import NameRegistration from "@/sign_in/components/NameRegistration.vue";
import BottomSheet from "@/sign_in/components/BottomSheet.vue";
import {changeNickname, finishAppSMSAuth, getTarotUserInfo, startSMSAuth} from "@/tarot_box/helper/mytarot_api";
import FinishPopup from "@/sign_in/components/FinishPopup.vue";
import {logEvent} from "@/tarot_box/helper/native_api";
import Loading from "vue-loading-overlay";

export default {
  name: "SignInPage",
  inject: ['appListener', 'appCommander'],
  components: {Loading, FinishPopup, BottomSheet, ConsentForm, NameRegistration, PhoneValidation},
  data() {
    return {
      currentIndex: 0,
      consentFormValid: false,
      bottomSheetType: '',
      selectedCountry: {
        flag: '',
        code: '',
      },
      installReferrer: '',
      phoneNumber: '',
      phoneNumberValid: false,
      authResult: null,
      name: '',
      nameValid: false,
      alreadyMember: false,
      appTrackingTransparencyPermissionSubscription: null,
      isFinishPopupActive: false,
      showToast: false,
      showBottom: true,
      toastMessage: '',
      isLoading: true,
    };
  },
  mounted() {
    logEvent ('terms_into', {});
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    const urlParams = new URLSearchParams(window.location.search);
    this.installReferrer = urlParams.get('install_referrer');
  },
  unmounted() {
    this.appTrackingTransparencyPermissionSubscription?.unsubscribe();
  },
  computed: {
    titleText() {
      switch (this.currentIndex) {
        case 0:
          return '약관에 동의해주세요.';
        case 1:
          return '휴대폰 인증';
        case 2:
          return '이름을 입력해 주세요'
        default:
          return '';
      }
    },
    descriptionText() {
      switch (this.currentIndex) {
        case 0:
          return '개인정보와 서비스 이용권리를 보호하고\n마이타로를 잘 이용하기 위함이에요';
        case 1:
          return '휴대폰 번호는 회원가입 여부,\n본인 확인을 위한 용도로만 사용해요';
        case 2:
          return '타로 결과지에 이름을 넣어 알려드려요\n이름은 언제든 수정할 수 있습니다'
        default:
          return '';
      }
    },
    buttonText() {
      switch (this.currentIndex) {
        case 0:
          return '동의하고 시작하기';
        case 1:
          return '인증하기';
        case 2:
          return '확인'
        default:
          return '';
      }
    }
  },
  methods: {
    checkValidation(value) {
      this.consentFormValid = value;
    },
    openBottomSheet(type) {
      this.bottomSheetType = type;
      this.$refs.bottomSheet.open();
    },
    closeBottomSheet() {
      this.$refs.bottomSheet.close();
    },
    updateSelectedCountry(country) {
      this.selectedCountry = country;
    },
    handleNameValidation(isValid) {
      this.nameValid = isValid;
      this.consentFormValid = isValid;
    },
    updateName(newName) {
      this.name = newName;
    },
    submitName() {
      if (this.nameValid) {
        this.changeName(this.name);
      }
    },
    submit() {
      if(this.currentIndex === 0){
        logEvent ('terms_agree_btn_click', {});
        this.currentIndex++;
        logEvent ('phone_into', {});
        this.consentFormValid = false;
        this.$nextTick(() => {
          this.$refs.phoneValidation.focusNumberInput();
        });
      } else if(this.currentIndex === 1) {
        this.appTrackingTransparencyPermissionSubscription?.unsubscribe();
        this.appTrackingTransparencyPermissionSubscription = this.appListener.subscribeSingleAction(
          'app_tracking_transparency_permission_result',
          () => {
            this.appTrackingTransparencyPermissionSubscription?.unsubscribe();
            this.openBottomSheet('phone');
            this.startAuth();
          }
        );
        this.appCommander.callAppAction('app_tracking_transparency_permission');
      } else {
        this.consentFormValid = false;
        logEvent ('name_done_btn_click', {});
        this.changeName(this.name);
      }
    },
    async startAuth(){
      let res = await startSMSAuth(this.phoneNumber);
      if (res.result === 'success') {
        logEvent ('phone_gogo_btn_click', {});
        this.showToastMessage('인증번호가 발송되었습니다');
      }
    },
    async finishAuth(certNumber){
      let res = await finishAppSMSAuth(this.phoneNumber, certNumber, this.installReferrer);
      if (res.result === 'success') {
        this.authResult = res.data;
        if(this.authResult.is_login === false){
          this.$refs.bottomSheet.close();
          this.consentFormValid = false;
          this.currentIndex = 2;
          this.$refs.nameRegistration.focusInput();
          logEvent ('name_into', {});
        } else {
          this.name = this.authResult.display_name
          ;
          this.alreadyMember = true;
          this.$refs.bottomSheet.close();
          this.isFinishPopupActive = true;
        }
      } else {
        logEvent ('phone_wrongnum_popup', {});
        this.showToastMessage('인증번호가 일치하지 않습니다\n다시 시도해주세요');
      }
    },
    async changeName(newName) {
      let res = await changeNickname(this.phoneNumber, this.authResult.id, newName);
      if (res.result === 'success') {
        this.isFinishPopupActive = true;
      }
    },
    handlePhoneValidation(isValid) {
      this.phoneNumberValid = isValid;
      this.consentFormValid = isValid;
    },
    async updateUserInfo() {
      let res = await getTarotUserInfo(this.authResult.id, this.phoneNumber);
      if (res.result === 'success'){
        this.authResult = res.data;
      }
    },
    async finalizeAuth() {
      await this.updateUserInfo();
      this.name = this.authResult.display_name;
      this.appCommander.callAppAction('set_user', this.authResult);
      this.appCommander.callAppAction('select_tab', { 'tab': 0 });
    },
    showToastMessage(message) {
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    }
  }
}
</script>

<style scoped>
.screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100dvh;
  width: 100dvw;
  max-height: 1024px;
  max-width: 480px;
  margin: 0 auto;
  background-color: #FFFFFF;
  padding: 0 24px;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 40px 0 40px 0;
  box-sizing: border-box;
  text-align: left;
  gap: 12px;
}

.text2 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  line-height: 140%;
  text-align: left;
}

.content {
  width: 100%;
}

.fixed-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 10px 24px;
}

button {
  width: 100%;
  padding: 16px 12px;
  background-color: var(--Color-palette-Primary-Default);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  transition: 0.3s ease;
}

button:disabled {
  background-color: var(--Grayscale-Gray8);
  cursor: not-allowed;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 65vw;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 99999;
  transition: opacity 0.5s, transform 0.5s;
}
.toast.show {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s, transform 0.5s;
}
</style>
