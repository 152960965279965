<template>
  <div class="main-content">
    <div class="selection-area">
      <div class="section-box">
        <h2 class="section-title">표지 이미지 설정</h2>
        <div class="background-image-input">
          <input id="backgroundImage" v-model="backgroundImage" placeholder="표지 이미지 URL을 입력하세요" class="full-width-input">
        </div>
      </div>

      <div class="section-box">
        <h2 class="section-title">항목 설정</h2>
        <div class="section-selector">
          <div class="tabs">
            <button
                v-for="(section, index) in sections"
                :key="index"
                @click="activeTab = index"
                :class="{ active: activeTab === index }"
                class="tab-button"
            >
              항목 {{ index + 1 }}
              <span
                  v-if="sections.length > 1"
                  class="delete-btn"
                  @click.stop="deleteSection(index)"
              >×</span>
            </button>
            <button
                @click="addSection"
                :disabled="sections.length >= 8"
                class="add-button"
            >+</button>
          </div>
          <div class="section-type-selector">
            <label class="radio-label">
              <input type="radio" value="personalInfo" v-model="sections[activeTab].sectionType">
              나의 정보
            </label>
            <label class="radio-label">
              <input type="radio" value="partnerInfo" v-model="sections[activeTab].sectionType">
              상대방의 정보
            </label>
            <label class="radio-label">
              <input type="radio" value="additionalInfo" v-model="sections[activeTab].sectionType">
              추가 정보
            </label>
            <label class="radio-label">
              <input type="radio" value="custom" v-model="sections[activeTab].sectionType">
              직접 입력
            </label>
          </div>
          <div v-if="sections[activeTab].sectionType === 'custom'">
            <div class="input-group">
              <label for="customSectionType">항목 타입:</label>
              <input
                  id="customSectionType"
                  v-model="sections[activeTab].customSectionType"
                  placeholder="항목 타입 입력"
                  class="full-width-input"
              >
            </div>
          </div>
          <div v-if="sections[activeTab].sectionType">
            <div class="input-group">
              <label for="sectionTitle">섹션 제목:</label>
              <input id="sectionTitle" v-model="sections[activeTab].sectionTitle" placeholder="섹션 제목" class="full-width-input">
            </div>
            <div class="input-group">
              <label for="sectionDescription">섹션 설명:</label>
              <input id="sectionDescription" v-model="sections[activeTab].sectionDescription" placeholder="섹션 설명" class="full-width-input">
            </div>
          </div>
        </div>
      </div>

      <div class="section-box">
        <h2 class="section-title">질문 설정</h2>
        <div v-if="sections.length > 0" class="tab-content">
          <button @click="addQuestion" class="add-question-btn">+ 질문 추가</button>
          <div v-for="(question, qIndex) in sections[activeTab].inputs" :key="qIndex" class="question-input">
            <div class="question-header">
              <h3>질문 {{ qIndex + 1 }}</h3>
              <button @click="deleteQuestion(qIndex)" class="delete-question-btn">삭제</button>
            </div>
            <div class="input-group">
              <label for="inputFormType">Input Type:</label>
              <select id="inputFormType"
                      v-model="question.inputFormType"
                      @change="resetQuestionData(question)"
                      class="full-width-input dropdown">
                <option value="">선택하세요</option>
                <option value="textInput">Text Input</option>
                <option value="genderInput">Gender Input</option>
                <option value="dateInput">Date Input</option>
                <option value="timeInput">Time Input</option>
                <option value="multiChoiceGrid">Multi Choice Grid</option>
                <option value="longTextInput">Long Text Input</option>
                <option value="numberInput">Number Input</option>
                <option value="surveyScore">Survey Score</option>
              </select>
            </div>
            <div v-if="question.inputFormType">
              <div class="input-group">
                <label for="inputFormSubtype">Subtype:</label>
                <input id="inputFormSubtype" v-model="question.inputFormSubtype" placeholder="Subtype" class="full-width-input">
              </div>
              <div class="input-group">
                <label for="inputFormHeaderLabel">Header Label:</label>
                <input id="inputFormHeaderLabel" v-model="question.inputFormHeaderLabel" placeholder="Header Label" class="full-width-input">
              </div>
              <div class="input-group">
                <label for="inputFormQuestion">Question:</label>
                <input id="inputFormQuestion" v-model="question.inputFormQuestion" placeholder="Question" class="full-width-input">
              </div>
              <div class="input-group">
                <label for="key">Key:</label>
                <input id="key" v-model="question.key" placeholder="Key" class="full-width-input">
              </div>

              <!-- 추가 옵션 -->
              <div v-if="question.inputFormType === 'textInput' || question.inputFormType === 'longTextInput'">
                <div class="input-group">
                  <label for="placeholder">Placeholder:</label>
                  <input id="placeholder" v-model="question.options.placeholder" placeholder="Placeholder" class="full-width-input">
                </div>
                <div v-if="question.inputFormType === 'longTextInput'" class="input-group">
                  <label for="maxLength">Max Length:</label>
                  <input type="number" id="maxLength" v-model="question.options.maxLength" placeholder="Max Length" class="full-width-input">
                </div>
              </div>

              <div v-if="question.inputFormType === 'timeInput'" class="input-group">
                <label for="is24HourFormat">24시간 형식:</label>
                <input type="checkbox" id="is24HourFormat" v-model="question.options.is24HourFormat">
              </div>

              <div v-if="question.inputFormType === 'numberInput'">
                <div class="input-group">
                  <label for="min">Min Value:</label>
                  <input type="number" id="min" v-model="question.options.min" placeholder="Min Value" class="full-width-input">
                </div>
                <div class="input-group">
                  <label for="max">Max Value:</label>
                  <input type="number" id="max" v-model="question.options.max" placeholder="Max Value" class="full-width-input">
                </div>
              </div>

              <div v-if="question.inputFormType === 'multiChoiceGrid' || question.inputFormType === 'surveyScore'">
                <div class="input-group">
                  <label>Choices:</label>
                  <div v-for="(choice, index) in question.options.choices" :key="index" class="choice-input">
                    <input v-model="choice.value" placeholder="Choice value" class="full-width-input">
                    <input v-model="choice.label" placeholder="Choice label" class="full-width-input">
                    <button @click="removeChoice(question, index)" class="remove-btn">-</button>
                  </div>
                  <button @click="addChoice(question)" class="add-btn">+ Add Choice</button>
                </div>
              </div>

              <div v-if="question.inputFormType === 'surveyScore'">
                <div class="input-group">
                  <label for="scale">Scale:</label>
                  <input type="number" id="scale" v-model="question.options.scale" placeholder="Scale" class="full-width-input">
                </div>
                <div class="input-group">
                  <label for="scoreType">Score Type:</label>
                  <input id="scoreType" v-model="question.options.scoreType" placeholder="Score Type" class="full-width-input">
                </div>
                <div class="input-group">
                  <label for="isAscending">Is Ascending:</label>
                  <input type="checkbox" id="isAscending" v-model="question.options.isAscending">
                </div>
              </div>
            </div>
            <div v-if="sections[activeTab].inputs.length > 1" class="order-buttons">
              <button @click="moveQuestionUp(qIndex)" :disabled="qIndex === 0">▲</button>
              <button @click="moveQuestionDown(qIndex)" :disabled="qIndex === sections[activeTab].inputs.length - 1">▼</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="preview-area">
      <h2>프리뷰 화면</h2>
    </div>
    <div class="json-area">
      <h2>JSON 포맷</h2>
      <div class="json-editor">
        <pre>{{ JSON.stringify(jsonOutput, null, 2) }}</pre>
      </div>
      <button @click="copyToClipboard">JSON 복사</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "JsonEditor",
  data() {
    return {
      backgroundImage: "",
      sections: [{
        sectionType: "",
        customSectionType: "",
        sectionTitle: "",
        sectionDescription: "",
        inputs: []
      }],
      activeTab: 0,
      jsonOutput: {}
    }
  },
  methods: {
    addSection() {
      if (this.sections.length < 8) {
        this.sections.push({
          sectionType: "",
          customSectionType: "",
          sectionTitle: "",
          sectionDescription: "",
          inputs: []
        });
        this.activeTab = this.sections.length - 1;
      }
    },
    deleteSection(index) {
      if (this.sections.length > 1) {
        this.sections.splice(index, 1);
        if (this.activeTab >= this.sections.length) {
          this.activeTab = this.sections.length - 1;
        }
      }
    },
    addQuestion() {
      this.sections[this.activeTab].inputs.push({
        inputFormType: "",
        inputFormSubtype: "",
        inputFormHeaderLabel: "",
        inputFormQuestion: "",
        key: "",
        options: {}
      });
    },
    deleteQuestion(index) {
      this.sections[this.activeTab].inputs.splice(index, 1);
    },
    moveQuestionUp(index) {
      if (index > 0) {
        const inputs = this.sections[this.activeTab].inputs;
        [inputs[index - 1], inputs[index]] = [inputs[index], inputs[index - 1]];
      }
    },
    moveQuestionDown(index) {
      const inputs = this.sections[this.activeTab].inputs;
      if (index < inputs.length - 1) {
        [inputs[index], inputs[index + 1]] = [inputs[index + 1], inputs[index]];
      }
    },
    addChoice(question) {
      if (!question.options.choices) {
        question.options.choices = [];
      }
      question.options.choices.push({ value: '', label: '' });
    },
    removeChoice(question, index) {
      question.options.choices.splice(index, 1);
    },
    updateJson() {
      this.jsonOutput = {
        inputVersion: "v2",
        startPageImgUrl: {
          backgroundImage: this.backgroundImage
        },
        inputSections: this.sections.map(section => ({
          sectionType: section.sectionType === 'custom' ? section.customSectionType : section.sectionType,
          sectionTitle: section.sectionTitle,
          sectionDescription: section.sectionDescription,
          inputs: section.inputs
        }))
      };
    },
    copyToClipboard() {
      navigator.clipboard.writeText(JSON.stringify(this.jsonOutput, null, 2));
    },
    resetQuestionData(question) {
      question.inputFormSubtype = "";
      question.inputFormHeaderLabel = "";
      question.inputFormQuestion = "";
      question.key = "";
      question.options = {};
    }
  },
  watch: {
    sections: {
      handler: 'updateJson',
      deep: true
    },
    backgroundImage: {
      handler: 'updateJson'
    }
  }
}
</script>

<style scoped>
.main-content {
  display: flex;
  margin-left: 200px;
  margin-top: 50px;
  height: calc(100vh - 50px);
  background-color: #f5f5f5;
}

.selection-area,
.preview-area,
.json-area {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  background-color: #ffffff;
}

.json-area {
  border-right: none;
}

.json-editor {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.json-editor pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
}

.background-image-input {
  margin-bottom: 20px;
}

.section-box {
  border: 1px solid #000;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.section-title {
  font-size: 20px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.tab-button {
  padding: 10px 25px 10px 25px;
  border: none;
  background-color: #f0f0f0;
  border: 1px solid #000;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
  position: relative;
}

.tab-button.active {
  background-color: #000000;
  color: white;
}

.delete-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  color: #cc132e;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
}

.add-button {
  width: 38px;
  height: 38px;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
  margin-bottom: 4px;
}

.add-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.section-type-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.radio-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.section-type-selector input[type="radio"] {
  margin-right: 5px;
  width: 14px;
  height: 14px;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-group label {
  width: 150px;
  text-align: right;
  margin-right: 10px;
  font-size: 18px;
}

.full-width-input {
  flex: 1;
  padding: 5px;
  font-size: 16px;
  height: 20px;
}

.full-width-input::placeholder {
  font-size: 16px;
}

.dropdown {
  height: 32px;
}

.tab-content {
  border: 1px solid #ccc;
  padding: 20px;
}

.question-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}

.add-question-btn {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.delete-question-btn {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}

.order-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 10px;
}

.order-buttons button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.order-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.choice-input {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.remove-btn, .add-btn {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}

.add-btn {
  background-color: #4CAF50;
}
</style>