<template>
  <AppExitPopup v-if="isAppExitPopupActive" @close="isAppExitPopupActive = false"/>
  <div class="screen" v-safe-area-top>
    <div class="header">
      <img id="logo" :src="require('@/tarot_box/pages/home/assets/images/home-logo.png')" alt="logo">
    </div>
    <div class="body-container">
      <div class="section">
        <div class="my-info-box"><div class="my-info-name-container">
            <div class="nickname">
              <div>
               <img src="@/my_page/assets/v2/person-icon.svg" alt=""/>
              </div>
              <span>{{ this.myPageUserInfo.displayName }}</span>
            </div>
            <img @click="openNameChangePopup" :src="require('@/my_page/assets/v2/edit-icon.svg')" alt="" />
          </div>
          <div class="clover-container">
            <div class="clover-box">
              <div class="current-clover">
                <span>{{ formatNumber(this.myPageUserInfo.cloverBalance) }}</span>
                <img :src="require('@/my_page/assets/v2/clover-icon.svg')">
              </div>
              <div class="my-clover" @click="openCloverHistoryPopup">
                <span>내 클로버</span>
                <div>
                  <img :src="require('@/my_page/assets/v2/right-arrow-icon.svg')" alt=""/>
                </div>
              </div>
            </div>
            <button @click="goPaywall">클로버 충전</button>
          </div>
        </div>
        <div class="indepth-analysis-container">
          <div class="indepth-analysis-title">
            <img :src="require('@/my_page/assets/v2/book-icon.svg')" alt=""/>
            <span>심층풀이 내역</span>
          </div>
          <div class="indepth-analysis-count" @click="openExtraQuestionHistoryPopup">
            <span>잔여횟수 <strong>{{ extraQuestionTicket }}회</strong></span>
            <div>
              <img :src="require('@/my_page/assets/v2/right-arrow-icon.svg')" alt=""/>
            </div>
          </div>
        </div>
        <div class="service-menu">
          <div v-for="(item, index) in serviceMenu" :key="index" @click="item.link" >
            <img :src="item.icon" alt=""/>
            <span>{{item.title}}</span>
          </div>
        </div>
      </div>
      <div class="section">
          <div v-wave v-for="(item , idx) in policyMenu" :key="idx" class="policy-menu" @click="item.link">
            <div>
              <img :src="item.icon" alt="" class="icon"/>
              <span>{{item.title}}</span>
            </div>
          </div>
      </div>
    </div>
  </div>
  <NameChangePopup
      v-model="isNameChangePopupActive"
      :userName="userName"
      @change-name="changeName"
  />
  <CloverHistoryV2
      v-if="isCloverHistoryPopupActive"
      @close="isCloverHistoryPopupActive = false"
      :user-clover="userClover"
      :user-clover-history="userCloverHistory"/>
  <ExtraQuestionHistory
      v-if="isExtraQuestionHistoryPopupActive"
      @close="isExtraQuestionHistoryPopupActive = false"
      :extra-history="extraHistory"/>
  <AlarmSettings
      v-if="isAlarmSettingsPopupActive"
      :reminder-time="this.myPageUserInfo.reminderTime "
      @update-user="getmyInfo"
      @update-reminder-time="updateReminderTime"
      @close="isAlarmSettingsPopupActive = false"/>
  <WebViewOverlay
      :isVisible="isWebViewVisible"
      :url="webViewUrl"
      @close="closeWebView"
  />
  <WithdrawalPopup
      :isActive="isWithdrawalPopupActive"
      @close="isWithdrawalPopupActive = false"
      @withdraw="removeMember"/>
  <RestorePopup
      :isActive="isRestorePopupActive"
      :is-processing="isRestoreProcessing"
      @close="isRestorePopupActive = false"/>
  <FriendInvitePopup
      v-if="isFriendPopupActive"
      :referral-history="referralHistory"
      :referral-rewarded="referralRewarded"
      @share-kakao="shareKakao"
      @share-link="shareLink"
      @submit="requestReferral"
      @close="isFriendPopupActive = false"/>
  <CouponPopup
    v-if="isCouponPopupActive"
    :user-phone-number="userPhoneNumber"
    :user-id="userId"
    :event-data="eventData"
    @alert="showToastMessage"
    @update-user="getmyInfo"
    @close="isCouponPopupActive = false"/>
  <div class="toast" :style="toastStyle" v-show="showToast" :class="{ 'show': showToast }">
    {{ toastMessage }}
  </div>
</template>

<script>


import {logEvent} from "@/tarot_box/helper/native_api";
import NameChangePopup from "@/my_page/components/NameChangePopup.vue";
import {
  changeNickname, getEventList,
  getExtarQuestionPaywall, getExtraQuestionHistory,
  getInviteHistory,
  getTarotBoxItemsByFetch,
  getTarotUserInfo,
  getUserCloverHistory, makeReferral, removeUser, updateReminderTime,

} from "@/tarot_box/helper/mytarot_api";
import {mapActions, mapGetters} from "vuex";
import {remoteConfig} from "@/common/firebase";
import WebViewOverlay from "@/my_page/components/WebViewOverlay.vue";
import AlarmSettings from "@/my_page/components/AlarmSettings.vue";
import RestorePopup from "@/my_page/components/RestorePopup.vue";
import WithdrawalPopup from "@/my_page/components/WithdrawalPopup.vue";
import FriendInvitePopup from "@/my_page/components/FriendInvitePopup.vue";
import friendInvitePopup from "@/my_page/components/FriendInvitePopup.vue";
import CloverHistoryV2 from "@/my_page/components/CloverHistoryV2.vue";
import CouponPopup from "@/my_page/components/CouponPopup.vue";
import AppExitPopup from "@/common/AppExitPopup.vue";
import ExtraQuestionHistory from "@/my_page/components/ExtraQuestionHistory.vue";


export default {
  name: 'MyPage',
  components: {
    AppExitPopup,
    CloverHistoryV2,
    FriendInvitePopup,
    WithdrawalPopup,
    RestorePopup,
    AlarmSettings,
    WebViewOverlay,
    NameChangePopup,
    CouponPopup,
    ExtraQuestionHistory
  },
  data() {
    return {

      currentCloverBalance: 123,
      extraQuestionTicket: 3,
      serviceMenu: [
        { title: '고객센터', icon: require('@/my_page/assets/v2/counsel-icon.svg'), link: this.openCsMail },
        { title: '알림설정', icon: require('@/my_page/assets/v2/bell-icon.svg'), link: this.openAlarmSettingsPopup},
        { title: '친구초대', icon: require('@/my_page/assets/v2/friends-icon.svg'), link: this.openInvitePopup},
        { title: '쿠폰등록', icon: require('@/my_page/assets/v2/coupon-icon.svg'), link: this.openCouponPopup},
      ],
      policyMenu:[
        { title: '약관 및 정책', icon: require('@/my_page/assets/v2/policy-icon.svg'), link: () => this.openWebView('https://terms.1zlabs.com/mytarot_service')},
        { title: '구독내역 복원', icon: require('@/my_page/assets/v2/setting-icon.svg'), link: this.openRestorePopup},
        { title: '탈퇴하기', icon: require('@/my_page/assets/v2/resign-icon.svg'), link: this.openWithdrawalPopup},
      ],
      userId: 0,
      userName: '',
      userClover: 0,
      userPhoneNumber: '',
      osType: '',
      referralCode: '',
      reminderTime: '',
      userCloverHistory: [],
      webViewUrl: '',
      referralHistory: {},
      eventData: null,
      extraHistory: null,
      insetTop: 0,

      referralRewarded: false,
      isNameChangePopupActive: false,
      isCloverHistoryPopupActive: false,
      isAlarmSettingsPopupActive: false,
      isWithdrawalPopupActive: false,
      isRestorePopupActive: false,
      isRestoreProcessing: false,
      isWebViewVisible: false,
      isFriendPopupActive: false,
      isCouponPopupActive: false,
      isAppExitPopupActive: false,
      isExtraQuestionHistoryPopupActive: false,
      showToast: false,
      toastMessage: '',
    }
  },
  computed : {
    ...mapGetters(['myPageUserInfo']),
    friendInvitePopup() {
      return friendInvitePopup
    },
    formattedClover() {
      return this.userClover.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    toastStyle() {
      return {
        top: `${45 + this.insetTop}px`,
        left: '50%',
        transform: this.showToast ? 'translate(-50%, 0)' : 'translate(-50%, -100%)'
      }
    },
  },
  async mounted(){
    logEvent('mypage_into', {});
    const urlParams = new URLSearchParams(window.location.search);

    this.userId = parseInt(urlParams.get('tarot_user_id'));
    this.userPhoneNumber = urlParams.get('phone_number');
    this.osType = urlParams.get('os_type');

    window.onBackButtonClick = this.onBackButtonClick;

    if (this.userId && this.userPhoneNumber) {
      await this.getmyInfo();
      await this.getCloverhistory();
      this.getReferralHistory();
      await this.getPaywallInfo();
      this.getExtraQuestionTicketPaywallList();
      this.getExtraAnswerHistory();
      this.getTarotBoxesData();
      this.getEvents();
    }

    const openParam = urlParams.get('open');
    if (openParam === 'history') {
      urlParams.delete('open');
      const newQuery = Object.fromEntries(urlParams);
      await this.$router.replace({ query: newQuery });
      this.isCloverHistoryPopupActive = true;
    } else if (openParam === 'clover') {
      urlParams.delete('open');
      const newQuery = Object.fromEntries(urlParams);
      await this.$router.replace({ query: newQuery });
      this.goPaywall();
    }
  },
  beforeUnmount() {
    if (window.onBackButtonClick === this.onBackButtonClick) {
      window.onBackButtonClick = null;
    }
  },
  methods:{
    ...mapActions(['updatePaywall', 'setTarotBoxesData', 'updateMyPageUserInfo']),
    async getmyInfo() {
      let res = await getTarotUserInfo(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        const newUserInfo = {
          displayName: res.data.display_name,
          cloverBalance: res.data.clover_balance,
          referralCode: res.data.referral_code,
          reminderTime: res.data.reminder_time
        };
        await this.updateMyPageUserInfo(newUserInfo);
        this.userName = this.myPageUserInfo.displayName;
        this.userClover = this.myPageUserInfo.cloverBalance;
        this.referralCode = this.myPageUserInfo.referralCode;
        this.reminderTime = this.myPageUserInfo.reminderTime;
        this.referralRewarded = res.data.referral_rewarded;
        this.extraQuestionTicket = res.data.extra_question_ticket;
      }
    },
    async getTarotBoxesData() {
      let res = await getTarotBoxItemsByFetch(this.userPhoneNumber, this.userId, false);
      if (res.result === 'success') {
        this.setTarotBoxesData(res.data.tarot_boxes);
      }
    },
    async getReferralHistory() {
      let res = await getInviteHistory(this.userPhoneNumber, this.userId);
      if (res.result === 'success') {
        this.referralHistory = res.data;
      }
    },
    async getExtraQuestionTicketPaywallList(){
      let res = await getExtarQuestionPaywall(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        this.paywallData = res.data;
      }
    },
    async changeName(newName) {
      let res = await changeNickname(this.userPhoneNumber,this.userId, newName);
      if (res.result === 'success') {
        await this.getmyInfo();
        this.userName = newName;
        this.isNameChangePopupActive = false;
      }
    },
    async getCloverhistory() {
      let res = await getUserCloverHistory(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        this.userCloverHistory = res.data;
      }
    },
    async getExtraAnswerHistory() {
      let res = await getExtraQuestionHistory(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        this.extraHistory = res.data;
      }
    },
    async getEvents() {
      let res = await getEventList();
      if (res.result === 'success') {
        this.eventData = res.data;
      }
    },
    async updateReminderTime(time) {
      this.reminderTime = time;
      let res = await updateReminderTime(this.userPhoneNumber, this.userId, this.reminderTime);
      if (res.result === 'success') {
        await this.getmyInfo();
      }
    },
    async removeMember() {
      let res = await removeUser(this.userPhoneNumber, this.userId);
      if (res.result === 'success') {
        window.callNativeMethod(`{"action": "withdrawal"}`);
      }
    },
    async requestReferral(code) {
      let res = await makeReferral(this.userPhoneNumber, this.userId, code);
      if (res.result === 'success') {
        logEvent('invite_friends_success_popup', {})
        this.showToastMessage('친구 초대가 완료되었습니다');
        await this.getReferralHistory();
        await this.getmyInfo();
      } else if (res.message === 'already_exist') {
        logEvent('invite_friends_success_popup', {})
        this.showToastMessage('이미 초대한 친구입니다');
      } else {
        this.showToastMessage('유효하지 않은 초대 코드입니다');
        logEvent('invite_friends_fail_popup', {})
      }
    },
    async getPaywallInfo() {
      const configValue = remoteConfig.getValue('paywall_banner_list_v2');
      const parsedValue = JSON.parse(configValue.asString());
      this.updatePaywall(parsedValue);
    },
    shareKakao() {
      logEvent('mypage_kakao_share_click', {})
      window.callNativeMethod(`{"action" : "kakao_share"}`);
    },
    shareLink() {
      logEvent('mypage_link_copy_click', {})
      const shareCode = this.referralCode;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(shareCode)
            .then(() => {
              this.showToastMessage('코드가 복사되었습니다');
            })
            .catch(err => {
              console.error('Error in copying text: ', err);
            });
      } else {
        // Fallback: 기존 방식 사용
        const textarea = document.createElement('textarea');
        textarea.value = shareCode;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 9999);
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    },
    openWebView(url) {
      logEvent ('mypage_terms_click', {});
      this.webViewUrl = url;
      this.isWebViewVisible = true;
    },
    closeWebView() {
      this.isWebViewVisible = false;
    },
    openInvitePopup() {
      logEvent('mypage_invite_click', {});
      this.isFriendPopupActive = true;
    },
    openAlarmSettingsPopup() {
      logEvent ('mypage_invite_friends_click', {});
      this.isAlarmSettingsPopupActive = true;
    },
    openNameChangePopup() {
      logEvent ('mypage_rename_click', {});
      this.isNameChangePopupActive = true;
      logEvent ('mypage_rename_popup', {});
    },
    openWithdrawalPopup() {
      logEvent ('mypage_delete_click', {});
      this.isWithdrawalPopupActive = true;
    },
    openRestorePopup() {
      logEvent ('mypage_restore_click', {});
      this.isRestorePopupActive = true;
      window.callNativeMethod(`{"action" : "restore_subscription"}`);
      this.isRestoreProcessing = true;
      setTimeout(() => {
        this.isRestoreProcessing = false;
      }, 2000);
    },
    openCsMail(){
      logEvent ('mypage_voc_click', {});
      window.callNativeMethod(`{"action" : "voc"}`); // 앱에서 고객센터기능 연결 요청
    },
    openCloverHistoryPopup() {
      logEvent ('mypage_clover_history_click', {});
      this.isCloverHistoryPopupActive = true;
    },
    openCouponPopup() {
      logEvent ('mypage_coupon_click', {});
      this.isCouponPopupActive = true;
    },
    openExtraQuestionHistoryPopup() {
      logEvent ('mypage_extra_question_click', {});
      this.isExtraQuestionHistoryPopupActive = true;
    },
    formatNumber(num) {
      if (num === 0) {
        return "-";
      }
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); //3자리마다 끊어서 쉼표
    },
    goPaywall() {
      logEvent('mypage_buy_click', {})
      const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
      const params = urlParams.toString();
      this.$router.push(`/paywall?${params}`);
    },
    showToastMessage(message){
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
    closeAllPopups() {
      const popups = [
        'isNameChangePopupActive',
        'isCloverHistoryPopupActive',
        'isExtraQuestionHistoryPopupActive',
        'isAlarmSettingsPopupActive',
        'isWithdrawalPopupActive',
        'isRestorePopupActive',
        'isWebViewVisible',
        'isFriendPopupActive',
        'isCouponPopupActive',
        'isAppExitPopupActive'
      ];

      const isAnyPopupActive = popups.some(popup => this[popup]);

      if (isAnyPopupActive) {
        popups.forEach(popup => {
          this[popup] = false;
        });
        this.isRestoreProcessing = false;
        console.log('All popups have been closed.');
      } else if(this.isAppExitPopupActive === true){
        this.isAppExitPopupActive = false;
      } else {
        this.isAppExitPopupActive = true;
      }
    },
    onBackButtonClick() {
      this.closeAllPopups();
    }
  }
}
</script>

<style scoped>
div{
  box-sizing: border-box;
}
a{
  text-decoration: none;
}
.screen {
  position: relative;
  width: 100%;
  max-width: 480px;
  height: 100svh;
  overflow-y: auto;
  margin: 0 auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.screen::-webkit-scrollbar {
  display: none;
}
.header{
  width: 100%;
  height: 68px;
  padding-left: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}

.header #logo{
  height: 44px;
  width: auto;
}

.body-container{
  background-color: #F5F5F5;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.section{
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.section:first-child{
  padding-top: 16px;
  padding-bottom: 30px;
}
/*첫번째 섹션 */
.my-info-box{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #ECECEC;
  padding: 20px 16px;
}
.my-info-name-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ECECEC;
  padding-bottom: 20px;
}
.nickname{
  display: flex;
  column-gap: 6px;
  align-items: center;
}
.nickname span{
  font-size: 18px;
  line-height: 120%;
  font-weight: 700;
  color: #121212;
}
.clover-container{
  width:100%;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clover-box{
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.current-clover{
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
  color: #121212;
  display: flex;
  column-gap: 6px;
  align-items: center;
}
.my-clover{
  font-size: 14px;
  font-weight: 400;
  color: #777;
  line-height: 120%;
  display: flex;
  align-items: center;
  column-gap: 4px;
}
.clover-container button{
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  color: white;
  background-color: #1E2352;
  border-radius: 50px;
  padding: 10.5px 20px;
}
.indepth-analysis-container{
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  background-color: #EBEEF4;
  margin-top: 16px;
  margin-bottom: 20px;
}
.indepth-analysis-title{
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  color: #2D3E73;
  display: flex;
  align-items: center;
  column-gap: 4px;
}
.indepth-analysis-title img{
  vertical-align: center;
}
.indepth-analysis-count{
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: #2D3E73;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.indepth-analysis-count img{
  vertical-align: center;
}
.indepth-analysis-count strong{
  font-weight: 700;
  color: #1E2352;
}
.service-menu{
  width: calc(100% - 16px);
  display: flex;
  justify-content: space-between;
}
.service-menu  div{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  color:#2D3E73;
}
.service-menu a:visited{
  color:#2D3E73;
}
.service-menu  div img{
  padding: 7px;
}

.section:nth-child(2){
  padding-top: 16px;
  row-gap: 8px;
}
.policy-menu{
  width: 100%;
  border-radius: 8px;
  padding: 10px 5px;
}
.policy-menu > div{
  display: flex;
  align-items: center;
  column-gap: 13px;
}
.policy-menu > div span{
  font-size: 14px;
  font-weight: 700;
  color: #555555;
}
.policy-menu .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.toast {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 65vw;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 9999;
  transition: opacity 0.5s, transform 0.5s;
}

.toast.show {
  opacity: 1;
  transform: translate(-50%, 0);
}
</style>
