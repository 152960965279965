export async function hideBottomBar() {
    let snakeCaseData = { action: 'hide_bottom_bar' };
    window.callNativeMethod(JSON.stringify(snakeCaseData));
    console.log("hideBottomBar", JSON.stringify(snakeCaseData));
}

export async function showBottomBar() {
    let snakeCaseData = { action: 'show_bottom_bar' };
    window.callNativeMethod(JSON.stringify(snakeCaseData));
    console.log("showBottomBar", JSON.stringify(snakeCaseData));
}

export function logEvent(eventName, properties = null) {
    const queryParams = new URLSearchParams(window.location.search);
    const isWeb = queryParams.get('is_web') === 'true';
    const osWeb = queryParams.get('os_type') === 'web';
    const isShare = queryParams.get('is_share') === 'true';
    if (isWeb || osWeb || isShare) {
        //웹이벤트일 경우 기존 이벤트명에 'w_'를 붙여서 전송
        window.gtag('event', 'w_' + eventName, properties);
        console.log("%clogWebEvent:", "background-color: red; color: white; font-weight: bold;", 'w_'+eventName, properties);

        if(eventName === 'purchase_completed_into'){
            window.gtag('event', 'purchase', properties);
            console.log("%clogWebEvent:", "background-color: red; color: white; font-weight: bold;", 'GA4-purchase', properties);
        }
    } else {
        let dataToSend = { eventName, param: properties };
        window.callAppAction('log_event', dataToSend);
        console.log("%clogAppEvent:", "background-color: blue; color: white; font-weight: bold;", dataToSend);
    }
}
