<script>
import Loading from "vue-loading-overlay";
import {mapActions} from "vuex";
import {getTarotBoxItemsByFetch} from "@/tarot_box/helper/mytarot_api";

export default {
  name: "BridgePage",
  components: {Loading},
  data() {
    return {
      isLoading: true,
      tarotBoxId: 220,
    };
  },
  mounted() {
    const UrlParams = new URLSearchParams(window.location.search);
    this.tarotBoxId = parseInt(UrlParams.get('tarot_box_id'));
    console.log("tarotBoxId:", this.tarotBoxId)
    this.refreshItems();
  },
  methods: {
    ...mapActions(['setTarotBoxesData']),
    async refreshItems() {
      try {
        let res = await getTarotBoxItemsByFetch('', '', true);
        if (res.result === 'success'){
          await this.setTarotBoxesData(res.data.tarot_boxes);
        }
      } catch (error){
        console.error("Error in refreshItems:", error);
      }
    },
    openUpPage() {
      this.isLoading = false;
    },
    goTarotBoxInputPage() {
      this.$router.push(`/tarot_box/input/${this.tarotBoxId}?os_type=web`);
    },
  }
}
</script>

<template>
   <Loading v-model:active="isLoading"
            loader="dots"
            color="#1E2352"
            :opacity="1"
            :z-index="99">
     <template v-slot:default>
       <div class="custom-loader">
         <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/logo.svg')" alt="loader" class="loader-img"/>
         <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/moving.svg')" alt="moving" class="moving-svg"/>
       </div>
     </template>
   </Loading>
    <div class="image-container">
      <img v-if="this.tarotBoxId === 220"
           @load="openUpPage()"
           src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2FID_220_bridge-min.jpg?alt=media&token=2de86ab4-25bc-477c-ac3c-fdfe37aab65c" />
      <img v-if="this.tarotBoxId === 43"
            @load="openUpPage()"
            src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2FID_43_bridge-min.jpg?alt=media&token=6e33599b-755c-4841-8926-d2ae6e22bc19" />
    </div>
   <div class="button-container" @click="goTarotBoxInputPage">
     지금 바로 체험하기 >
   </div>
</template>

<style scoped>
.image-container{
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 56px 0;
  box-sizing: border-box;
  background: #000;
  overflow-y: scroll;
}

.image-container img{
  width: 100%;
  overflow: scroll;
}

.button-container{
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #252846;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
</style>