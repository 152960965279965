<template>
  <Loading v-model:active="isLoading"
           loader="dots"
           color="#1E2352"
           :opacity="1"
           :z-index="9999">
    <template v-slot:default>
      <div class="custom-loader">
        <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/logo.svg')" alt="loader" class="loader-img"/>
        <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/moving.svg')" alt="moving" class="moving-svg"/>
      </div>
    </template>
  </Loading>
  <div class="screen" ref="screen">
    <div class="button-container">
      <div class="bttn" @click="scrollToFirstEvent">
        더 많은 사주 보기
      </div>
      <div class="bttn pink" @click="scrollToAstrologer">
        추천 사주
      </div>
    </div>
    <div class="wrapper" ref="wrapper">
      <div class="image-container" ref="imageContainer">
        <div class="padding-stuff" style="width: 100%; max-width: 480px; height: 30px; background-color: #e396a6;"/>
        <img :src="imgUrls[0]" style="width: 100%;" />
        <div class="video-container">
          <video
              ref="autoPlayVideo"
              class="video-js vjs-default-skin"
              preload="auto"
          ></video>
        </div>
        <div style="position: relative; width: 100%; font-size: 0px; padding: 0; margin: 0;">
          <img
              v-for="(imgUrl, index) in secondImgUrls"
              :src="imgUrl"
              :key="index"
              style="width: 100%;"
          />
<!--          <img-->
<!--              @click="scrollToFirstEvent"-->
<!--              src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mastro%2Fevent2%2Fevent_%20scroll_btn-min.png?alt=media&token=b526bff3-bfb7-4672-a340-8c2600b73928"-->
<!--              style="position: absolute; bottom: 16%; left: 50%; transform:translateX(-50%); width: 90%;">-->
        </div>
<!--        <div class="video-container">-->
<!--          <video-->
<!--              ref="scrollPlayVideo"-->
<!--              class="video-js vjs-default-skin"-->
<!--              preload="auto"-->
<!--          ></video>-->
<!--        </div>-->
        <div class="box-banner">
          <img
              v-for="(imgUrl, index) in bannerImgUrls"
              :src="imgUrl"
              :key="index"
              style="width: 82.5%;"
              @click="handleBannerClick(index)" />
          <img src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2FGroup%20628-min.png?alt=media&token=2e4d8a99-ffdc-46d2-8616-3d4858087b41" id="border-frame">

        </div>
<!--        <div style="margin:0; position: relative; height: 100%; font-size:0px;">-->
<!--          <img-->
<!--              v-for="(imgUrl, index) in fourthImgUrls"-->
<!--              :src="imgUrl"-->
<!--              :key="index + fourthImgUrls.length"-->
<!--              style="width: 100%;"-->
<!--          />-->
<!--          <div @click="goInstagram"-->
<!--               style="position: absolute; background: transparent; width: 82%; height: 70px; bottom: 64px; left: 50%; transform: translateX(-50%);"/>-->
<!--        </div>-->
        <img
            v-for="(imgUrl, index) in fifthImgUrls"
            :src="imgUrl"
            :key="index + fifthImgUrls.length"
            style="width: 100%; z-index: 1;"/>
        <div class="padding-stuff" style="width: 100%; max-width: 480px; height: 60px; background-color: #919294;"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {
  changeBottomSafeAreaColor,
  changeTopSafeAreaColor,
  logEvent,
} from "@/tarot_box/helper/native_api";
import videojs from 'video.js';
import Loading from "vue-loading-overlay";
import 'video.js/dist/video-js.css';
import {hideBottomBar} from "@/avatar/helper/native_api";
import {getTarotBoxItemsByFetch} from "@/tarot_box/helper/mytarot_api";

export default {
  name: "EventPage",
  components: {
    Loading,
  },
  data() {
    return {
      imgUrls: [
        'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/%EB%A7%88%EC%9D%B4%ED%83%80%EB%A1%9C%20temp%2F%EA%B0%9C%EB%B0%9C_LP1-min.jpg?alt=media&token=8a3a994f-5690-4a36-bdde-60f0606d35ee',
        'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2F%EA%B0%9C%EB%B0%9C_LP2-min.jpg?alt=media&token=6badfe14-c17d-48cf-a36e-824de24c6c9e',
        'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2F%EA%B0%9C%EB%B0%9C_LP3-min.jpg?alt=media&token=1735374f-f12b-4b96-a047-0086c0d7a376',
        'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/%EB%A7%88%EC%9D%B4%ED%83%80%EB%A1%9C%20temp%2F%EA%B0%9C%EB%B0%9C_LP4-min.jpg?alt=media&token=d65269d1-9259-446d-ab3b-421b8362cdc6',
        'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2F%EA%B0%9C%EB%B0%9C_LP3-min.jpg?alt=media&token=1735374f-f12b-4b96-a047-0086c0d7a376',
        'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/%EB%A7%88%EC%9D%B4%ED%83%80%EB%A1%9C%20temp%2F%EA%B0%9C%EB%B0%9C_LP6-min.jpg?alt=media&token=f2487c5e-8abc-462a-acd8-ed5a215e5916',
        'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2F%EA%B0%9C%EB%B0%9C_LP3-min.jpg?alt=media&token=1735374f-f12b-4b96-a047-0086c0d7a376',
        'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2F%EA%B0%9C%EB%B0%9C_LP7_%EC%9C%A0%EC%9D%98%EC%82%AC%ED%95%AD-min.jpg?alt=media&token=85aead0b-cf68-48a1-a661-1dece92a4fe1',
      ],
      bannerImgUrls:[
          'https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2F1_40-min.png?alt=media&token=3ee32b15-4080-4d82-af0c-8ef364451e7d',
          'https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2F2_130-min.png?alt=media&token=dead4113-2e9d-4b79-868d-9065fa3eb106',
          'https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2F3_139-min.png?alt=media&token=62f47a6a-0c08-4a61-a9ae-80e32cc73ff0',
          'https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2F4_60-min.png?alt=media&token=48cd4871-1032-4c28-ab89-b86d2d1b0c54',
          'https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2F5_66-min.png?alt=media&token=d86b4e28-dd17-4048-8599-17438a9a3de7',
          'https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2F6_217-min.png?alt=media&token=76c04e7c-47ea-4d4b-881b-1c7f6e2db7db',
          'https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2F7_48-min.png?alt=media&token=81306f5f-3132-4b63-aa59-2d81170c1d3e',

      ],
      autoPlayVideo: null,
      scrollPlayVideo: null,
      isAutoPlayVideoMuted: true,
      isScrollPlayVideoMuted: true,
      isDragging: false,
      isLoading: true,
      utm: 'not set'
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "tarotBoxes",
      "promotionBanners",
      "iconCategories",
      "homeSections",
    ]),
    secondImgUrls() {
      return this.imgUrls.slice(1, 3);
    },
    thirdImgUrls() {
      return this.imgUrls.slice(5, 6);
    },
    fourthImgUrls() {
      return this.imgUrls.slice(6, 7);
    },
    fifthImgUrls() {
      return this.imgUrls.slice(7, 8);
    },
  },
  mounted() {
    changeTopSafeAreaColor("#FF010217");
    window.onBackButtonClick = this.handleButtonClick;
    this.$refs.screen.addEventListener('scroll', this.checkScrollPosition);
    this.initializeVideos();
    window.addEventListener("scroll", this.handleScroll);
    let utmMedium = new URLSearchParams(window.location.search).get('utm_medium');
    let specialReferrer = new URLSearchParams(window.location.search).get('utm_content');

    if (specialReferrer) {
      try {
        specialReferrer = decodeURIComponent(specialReferrer);
      } catch (e) {
        console.error('Failed to decode specialReferrer:', e);
      }
    }

    let webReferrer = utmMedium;
    if (specialReferrer && specialReferrer.startsWith('jaewon')) {
      webReferrer = specialReferrer;
    }
    if (webReferrer) {
      this.$store.dispatch('setUserData', {web_referrer: webReferrer});
      this.utm = webReferrer;
      localStorage.setItem('webReferrer', webReferrer);
      console.log('web_referrer:', webReferrer)
      console.log('utm:', this.user.webReferrer)
    }
    logEvent('event_page_into', {
      'web_referrer': this.utm,
    })
    setTimeout(() => {
      const savedPosition = this.$route.query.scroll_position;
      if (savedPosition) {
        this.scrollToRelativePosition(parseFloat(savedPosition));
        const query = { ...this.$route.query };
        delete query.scroll_position;
        delete query.tarot_box_id;
        delete query.from_event;
        this.$router.push({ query });
      }
    },400);
    this.refreshItems();
  },
  beforeUnmount() {
    if (this.autoPlayVideo) {
      this.autoPlayVideo.dispose();
    }
    if (this.scrollPlayVideo) {
      this.scrollPlayVideo.dispose();
    }
    window.removeEventListener("scroll", this.handleScroll);
    this.$refs.screen.removeEventListener('scroll', this.checkScrollPosition);
  },
  methods: {
    ...mapActions(['setUserData', 'setTarotBoxesData', 'setRankingSubjectTitlesData','setPromotionBannersData', 'setIconCategoriesData', 'setHomeSectionsData', 'updateRecommendList','addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    async refreshItems() {
      try {
        let isWeb = this.$isWeb();
        let phoneNumber = new URLSearchParams(window.location.search).get('phone_number');
        let tarotUserId = new URLSearchParams(window.location.search).get('tarot_user_id');
        console.log('refreshItems', phoneNumber, tarotUserId, isWeb);
        let res = await getTarotBoxItemsByFetch(phoneNumber, tarotUserId, isWeb);
        if (res.result === 'success'){
          this.setTarotBoxesData(res.data.tarot_boxes);
          this.setPromotionBannersData(res.data.promotion_banners);
          this.setIconCategoriesData(res.data.icon_categories);
          this.setHomeSectionsData(res.data.home_sections);
          this.setRankingSubjectTitlesData(res.data.ranking_subject_titles);
        }
      } catch (error){
        console.error("Error in refreshItems:", error);
      }
    },
    removeLoader() {
      setTimeout(() => {
        this.isLoading = false;
      }, 400);
    },
    initializeVideos() {
      // 첫 번째 영상 설정
      this.autoPlayVideo = videojs(this.$refs.autoPlayVideo, {
        muted: true,
        controls: false,
        loop: true,
        playsinline: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: "https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2FLP1_%EC%9D%B8%ED%8A%B8%EB%A1%9C%20%EC%88%98%EC%A0%95_%EC%9D%B4%EB%B2%A4%ED%8A%B8%EC%82%AD%EC%A0%9C%20(3)%20(1).mp4?alt=media&token=4bf7bb7c-1130-4e11-9ab1-c7b99cde675a",
            type: "video/mp4",
          },
        ],
      });

      this.autoPlayVideo.ready(() => {
        const playPromise = this.autoPlayVideo.play();

        if (playPromise !== undefined) {
          playPromise.then(() => {
            console.log("Automatic playback started!");
          }).catch(error => {
            console.log("Auto-play was prevented", error);
            // 자동 재생이 차단되면 controls를 표시합니다.
            this.autoPlayVideo.controls(true);
          });
        }
      });

      this.autoPlayVideo.on("canplay", () => {
        this.removeLoader();
        this.addMuteIcon(this.autoPlayVideo.el(), 'autoPlayVideo');
      });

      // // 두 번째 영상 설정
      // this.scrollPlayVideo = videojs(this.$refs.scrollPlayVideo, {
      //   muted: true,
      //   controls: false,
      //   loop: true,
      //   playsinline: true,
      //   responsive: true,
      //   fluid: true,
      //   sources: [
      //     {
      //       src: "https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/%EB%A7%88%EC%9D%B4%ED%83%80%EB%A1%9C%20temp%2FLP2%20(1).mp4?alt=media&token=5cd9fa64-c3c8-40ba-b45a-f21812994193",
      //       type: "video/mp4",
      //     },
      //   ],
      // });
      //
      // this.scrollPlayVideo.on("canplay", () => {
      //   this.addMuteIcon(this.scrollPlayVideo.el(), 'scrollPlayVideo');
      // });

      this.addTouchEventListeners(this.autoPlayVideo.el(), 'autoPlayVideo');
      // this.addTouchEventListeners(this.scrollPlayVideo.el(), 'scrollPlayVideo');
    },
    checkScrollPosition() {
      const screen = this.$refs.screen;
      const scrollTop = screen.scrollTop;
      const scrollHeight = screen.scrollHeight;
      const clientHeight = screen.clientHeight;
      const scrollableHeight = scrollHeight - clientHeight;

      // 상대적 위치 계산
      const relativeScrollPosition = scrollTop / scrollableHeight;

      // 상대적 위치 기준점 계산
      const firstVideoStart = 0 / 5398;
      const firstVideoEnd = 779 / 5398;
      // const secondVideoStart = 1349 / 5398;
      // const secondVideoEnd = 2422 / 5398;

      // 첫 번째 영상 제어
      if (relativeScrollPosition >= firstVideoStart && relativeScrollPosition < firstVideoEnd) {
        if (this.autoPlayVideo.paused()) {
          console.log('첫 번째 영상 재생 시도');
          const playPromise = this.autoPlayVideo.play();
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              console.log("첫 번째 영상 재생 실패:", error);
              // 재생 실패 시 controls를 표시합니다.
              this.autoPlayVideo.controls(true);
            });
          }
        }
      } else {
        if (!this.autoPlayVideo.paused()) {
          console.log('첫 번째 영상 일시정지');
          this.autoPlayVideo.pause();
        }
      }

      // // 두 번째 영상 제어
      // if (relativeScrollPosition >= secondVideoStart && relativeScrollPosition < secondVideoEnd) {
      //   if (this.scrollPlayVideo.paused()) {
      //     console.log('두 번째 영상 재생 시도');
      //     const playPromise = this.scrollPlayVideo.play();
      //     if (playPromise !== undefined) {
      //       playPromise.catch(error => {
      //         console.log("두 번째 영상 재생 실패:", error);
      //         // 재생 실패 시 controls를 표시합니다.
      //         this.scrollPlayVideo.controls(true);
      //       });
      //     }
      //   }
      // } else {
      //   if (!this.scrollPlayVideo.paused()) {
      //     console.log('두 번째 영상 일시정지');
      //     this.scrollPlayVideo.pause();
      //   }
      // }

      // 각 영상의 음소거 상태 유지
      this.autoPlayVideo.muted(this.isAutoPlayVideoMuted);
      // this.scrollPlayVideo.muted(this.isScrollPlayVideoMuted);
      console.log("상대적 스크롤 위치:", relativeScrollPosition);
    },
    addTouchEventListeners(videoElement, videoRef) {
      videoElement.addEventListener('touchstart', () => {
        this.isDragging = false;
      });

      videoElement.addEventListener('touchmove', () => {
        this.isDragging = true;
      });

      videoElement.addEventListener('touchend', () => {
        if (!this.isDragging) {
          this.toggleMute(videoRef);
          if(videoRef === 'autoPlayVideo') {
            if(this.isAutoPlayVideoMuted) {
              logEvent('event_video_mute_1', {});
            } else {
              logEvent('event_video_unmute_1', {});
            }
          } else if(videoRef === 'scrollPlayVideo') {
            if(this.isScrollPlayVideoMuted) {
              logEvent('event_video_mute_2', {});
            } else {
              logEvent('event_video_unmute_2', {});
            }
          }
        }
      });
    },
    addMuteIcon(videoElement, videoRef) {
      const container = videoElement.parentElement;
      let icon = container.querySelector('.mute-icon');

      if (!icon) {
        icon = document.createElement("div");
        icon.className = "mute-icon";
        container.appendChild(icon);
      }

      const img = document.createElement('img');
      img.src = 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/%EB%A7%88%EC%9D%B4%ED%83%80%EB%A1%9C%20temp%2Fsound_mute.png?alt=media&token=266bad1a-622d-4061-a644-4d53f898c56d';
      img.alt = 'Mute';
      img.style.width = "35px";
      img.style.height = "35px";

      // 기존 내용 제거 후 새 이미지 추가
      icon.innerHTML = '';
      icon.appendChild(img);
      icon.style.position = "absolute";
      icon.style.top = "20px";
      icon.style.right = "20px";

      icon.style.zIndex = "10";

      icon.addEventListener('click', (e) => {
        e.stopPropagation();
        this.toggleMute(videoRef);
      });
    },
    toggleMute(videoRef) {
      const player = this[videoRef];
      if (player) {
        const isMuted = player.muted();
        player.muted(!isMuted);

        // 각 플레이어의 음소거 상태를 개별적으로 업데이트
        if (videoRef === 'autoPlayVideo') {
          this.isAutoPlayVideoMuted = !isMuted;
        } else if (videoRef === 'scrollPlayVideo') {
          this.isScrollPlayVideoMuted = !isMuted;
        }
        this.updateMuteIcon(player.el(), !isMuted);
      }
    },
    updateMuteIcon(videoElement, isMuted) {
      const icon = videoElement.parentElement.querySelector('.mute-icon');
      const img = icon.querySelector('img');
      img.src = isMuted
          ? 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/%EB%A7%88%EC%9D%B4%ED%83%80%EB%A1%9C%20temp%2Fsound_mute.png?alt=media&token=266bad1a-622d-4061-a644-4d53f898c56d'
          : 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/%EB%A7%88%EC%9D%B4%ED%83%80%EB%A1%9C%20temp%2Fsound_max.png?alt=media&token=cea6a325-ae01-4fad-9d4d-e82ea3790722'
      img.alt = isMuted ? 'Mute' : 'Unmute';
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      console.log("현재 스크롤 위치 (window):", scrollTop);

      const scrollPlayVideoElement = this.$refs.scrollPlayVideo;
      if (scrollPlayVideoElement) {
        const rect = scrollPlayVideoElement.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        if (isVisible && this.scrollPlayVideo.paused()) {
          this.scrollPlayVideo.play();
        } else if (!isVisible && !this.scrollPlayVideo.paused()) {
          this.scrollPlayVideo.pause();
        }
      }
    },
    scrollToRelativePosition(relativePosition) {
      const screen = this.$refs.screen;
      const scrollableHeight = screen.scrollHeight - screen.clientHeight;
      const targetScrollPosition = scrollableHeight * relativePosition;
      screen.scrollTo({
        top: targetScrollPosition,
        behavior: 'smooth',
      });
    },

    scrollToFirstEvent() {
      const urlParams = new URLSearchParams(window.location.search);
      const utmContent = urlParams.get('utm_content');
      logEvent('event_floating_cta_click_1', {})
      this.$router.push(`/tarot_box/home?is_web=true&utm_content=${utmContent}&from_event=true`);
    },

    scrollToAstrologer() {
      logEvent('event_floating_cta_click_2', {})
      this.scrollToRelativePosition(0.352);
    },
    handleButtonClick() {
      this.goBack();
      return "back";
    },
    goBack() {
      let queryParams = { ...this.$route.query };
      delete queryParams.from_event;
      this.$router.push({ path: `/tarot_box/home`, query: queryParams });
    },
    goInstagram() {
      logEvent('event_instagram_go_click', {
      })
      window.open("https://www.instagram.com/p/DAapSnHJAEg/?igsh=aXZ3N2I3eGJtbDd5", "_blank");
    },
    handleBannerClick(index) {
      if (index === 0) {
        this.navigateToTarotBox(40, 1);
      } else if (index === 1) {
        this.navigateToTarotBox(130, 2);
      } else if (index === 2) {
        this.navigateToTarotBox(139, 3);
      } else if (index === 3) {
        this.navigateToTarotBox(60, 4);
      } else if (index === 4) {
        this.navigateToTarotBox(66, 5);
      } else if (index === 5) {
        this.navigateToTarotBox(217, 6);
      } else if (index === 6) {
        this.navigateToTarotBox(48, 7);
      }
    },
    async navigateToTarotBox(tarotBoxId, eventIndex) {
      const screen = this.$refs.screen;
      const scrollTop = screen.scrollTop;
      const scrollHeight = screen.scrollHeight;
      const clientHeight = screen.clientHeight;
      const scrollableHeight = scrollHeight - clientHeight;
      const relativeScrollPosition = scrollTop / scrollableHeight;

      hideBottomBar();
      changeBottomSafeAreaColor('#FF1E2352');
      changeTopSafeAreaColor('#FFFFFFFF');
      logEvent(`event_content_click_${eventIndex}`, {
        'tarot_box_id': tarotBoxId,
      });

      let queryParams = {
        ...this.$route.query,
        tarot_box_id: tarotBoxId,
        from_event: true,
        scroll_position: relativeScrollPosition.toFixed(4)
      };

      try {
        await this.$router.push({path: `/tarot_box/event`, query: queryParams});
        await this.$router.push({path: `/tarot_box/home`, query: queryParams});
        console.log(`Successfully navigated to tarot box ${tarotBoxId}`);
      } catch (err) {
        console.error('Navigation failed:', err);
      }
    },
  }
};
</script>

<style scoped>
.screen{
  width: 100vw;
  max-width: 480px;
  height: 100svh;
  overflow: auto;
  background: #fcfbf7;
  margin: 0 auto;
  font-size: 0;
}

.image-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.back-button{
  position: absolute;
  top: 0;
  left: 0;
  width: 50px; /* 크기 조정 */
  height: 50px; /* 크기 조정 */
  outline: none;
  background-color: transparent;
}

.back-button:focus{
  outline: none;
  background-color: transparent;
}

.box-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  max-width: 480px;
  padding-top: 20px;
  box-sizing: border-box;
  height: 770vw;
  max-height: calc(480px * 7.70);
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/test%2Fbg.jpg?alt=media&token=4171885d-9884-45cb-89fd-d839ae824a82');
  background-size: contain;
  font-size: 0;
}

#border-frame{
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 480px;
  z-index: 0;
}

.tarot-box-button img{
  width: 25%;
}
.tarot-box-single-button img {
  width: 95%;
}

.video-container {
  width: 100vw;
  max-width: 480px;
  height: calc((100vw / 75) * 100);
  max-height: calc((480px / 75) * 100);
  margin: 0;
  position: relative;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button-container{
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
  box-sizing: border-box;
  background: #FFFFFF;
  gap: 4px;
  z-index: 99;
}

.bttn{
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #473E3E;
  color: white;
  border-radius: 4px;
  padding: 14px 20px;
  font-size: 16px;
  font-weight: bold;
}

.pink{
  flex: 4;
  background: #FF909D;
  color: #5F4747;
}
</style>

