<template>
  <VueBottomSheet
      v-if="currentIndex !== 0"
      :can-swipe="false"
      @opened="handleOpened"
      @closed="$emit('closed')"
      ref="bottomSheet">

    <div v-if="type === 'country'">
      <div class="sheet-container" v-safe-area-bottom>
        <div class="sheet-title">
          <span class="title3">국가를 선택해주세요</span>
          <img src="@/tarot_box/pages/box_input_v2/assets/x-button-s.svg" alt="close" @click="closeBottomSheet"/>
        </div>
        <div class="sheet-content">
          <div class="country-list">
            <div class="country-content"
                 v-wave
                 v-for="item in country"
                 :key="item.id"
                 @click="selectCountry(item)">
              <span style="font-size: 24px;">{{ item.flag }}</span>
              <span>{{ item.name }}</span>
              <span>{{ item.code }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="type === 'phone'">
      <div class="sheet-container" v-safe-area-bottom>
        <div class="sheet-title">
          <span class="title3">문자로 전송된<br>인증번호를 입력해주세요</span>
          <img src="@/tarot_box/pages/box_input_v2/assets/x-button-s.svg" alt="close" @click="closeBottomSheet"/>
        </div>
        <div class="sheet-content">
          <div class="timer">
            <TimerIcon :color="'var(--Grayscale-Gray8)'"/>
            <span class="gray8 font14">{{ formattedTime }}</span>
          </div>
          <div class="code-input">
            <NumberInput
                v-model="certNumber"
                :input-item="{inputFormType: 'code', inputFormSubtype: 'code'}"
                :max-length="5"
                @submit-answer="handleCertNumber"
                ref="numberInput"
                placeholder="5자리 숫자"/>
          </div>
          <div class="retry"
               @click="isTimerExpired && retryAuth()"
               :class="{ 'active': isTimerExpired, 'inactive': !isTimerExpired }">
            <span class="font14">인증번호 재전송</span>
          </div>
          <div
              class="submit-button"
              :class="{ 'expired': isTimerExpired, 'disabled': isSubmitDisabled }"
              @click="!isSubmitDisabled && submitAuth()"
          >
            {{ isTimerExpired ? '인증 시간 만료' : '확인' }}
          </div>
        </div>
      </div>
    </div>

  </VueBottomSheet>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import NumberInput from "@/tarot_box/pages/box_input_v2/components/Inputs/NumberInput.vue";
import TimerIcon from "@/sign_in/assets/TimerIcon.vue";

export default {
  name: "BottomSheet",
  components: {
    NumberInput,
    VueBottomSheet,
    TimerIcon
  },
  data() {
    return {
      sheetHeight: 400,
      payload: null,
      selectedCountry: '',
      timerSeconds: 300,
      timerInterval: null,
      isTimerExpired: false,
      certNumber: '',
      country: [
        {
          id: 1,
          flag: '🇰🇷',
          name: '대한민국',
          code: '+82'
        },
        // {
        //   id: 2,
        //   flag: '🇺🇸',
        //   name: 'UNITED STATES',
        //   code: '+1'
        // },
        // {
        //   id: 3,
        //   flag: '🇨🇦',
        //   name: 'CANADA',
        //   code: '+1'
        // },
        // {
        //   id: 4,
        //   flag: '🇦🇺',
        //   name: 'AUSTRALIA',
        //   code: '+61'
        // },
        // {
        //   id: 5,
        //   flag: '🇳🇿',
        //   name: 'NEW ZEALAND',
        //   code: '+64'
        // }
      ]
    };
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    this.clearTimer();
  },
  props: {
    currentIndex: {
      type: Number,
    },
    tarotBoxHistory: {
      type: Object,
    },
    type: {
      type: String,
    },
    countryCode: {
      type: String,
    },
    phoneNumber: {
      type: String,
    }
  },
  emits: ['go-page', 'close', 'opened', 'closed', 'country-selected', 're-auth', 'submit-auth'],
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timerSeconds / 60);
      const seconds = this.timerSeconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    isSubmitDisabled() {
      return this.certNumber.length < 5 || this.isTimerExpired;
    }
  },
  methods: {
    open(payload) {
      this.payload = payload;
      this.$nextTick(() => {
        this.$refs.bottomSheet.open();
        if(this.type === 'phone'){
          this.resetTimer();
        }
      });
    },
    handleOpened() {
      this.$emit('opened');
      if (this.type === 'phone') {
        setTimeout(() => {
          this.autoFocusAndOpenKeyboard();
        }, 1000);
      }
    },
    autoFocusAndOpenKeyboard() {
      const input = this.$refs.numberInput.$refs.input;
      if (!input) return;

      // 임시 input 요소 생성
      const tempInput = document.createElement('input');
      tempInput.setAttribute('type', 'text');
      tempInput.style.position = 'absolute';
      tempInput.style.opacity = '0';
      tempInput.style.height = '0';
      tempInput.style.fontSize = '16px'; // iOS에서 줌 방지

      // 임시 요소를 body에 추가
      document.body.appendChild(tempInput);

      // 임시 요소에 포커스
      tempInput.focus();

      // 실제 input에 포커스 및 선택
      setTimeout(() => {
        input.focus();
        input.click(); // 일부 모바일 브라우저에서 필요할 수 있음

        // 입력값이 있다면 선택
        if (input.value) {
          input.select();
        }

        // 임시 요소 제거
        document.body.removeChild(tempInput);

        // iOS에서 추가적인 트릭
        setTimeout(() => {
          input.scrollIntoViewIfNeeded();
          // 빈 텍스트 선택으로 키보드를 강제로 열기
          input.setSelectionRange(0, 0);
        }, 100);
      }, 100);
    },
    closeBottomSheet() {
      this.$refs.bottomSheet.close();
    },
    close() {
      this.closeBottomSheet();
    },
    selectCountry(item) {
      this.$emit('country-selected', { flag: item.flag, code: item.code });
      this.closeBottomSheet();
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timerSeconds > 0) {
          this.timerSeconds--;
        } else {
          this.clearTimer();
          this.isTimerExpired = true;
        }
      }, 1000);
    },
    clearTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },
    resetTimer() {
      this.clearTimer();
      this.timerSeconds = 300;
      this.isTimerExpired = false;
      this.startTimer();
    },
    retryAuth() {
      this.resetTimer();
      this.$emit('re-auth');
    },
    handleCertNumber(data) {
      this.certNumber = data.value;
    },
    submitAuth() {
      if (!this.isSubmitDisabled) {
        this.$emit('submit-auth', this.certNumber);
      }
    },
  }
}
</script>


<style scoped>
.sheet-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 30px 24px;
  box-sizing: border-box;
  position: relative;
}

.sheet-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  font-family: "SUIT-Variable", sans-serif;
}

.sheet-content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-family: "SUIT-Variable", sans-serif;
}

.country-list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.country-content{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--Grayscale-Gray10);
  gap: 10px;
  font-family: "SUIT-Variable", sans-serif;
}

.timer{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-family: "SUIT-Variable", sans-serif;
}

.code-input{
  width: 100%;
  margin: 10px 0;
}

.code-input input{
  width: 100% !important;
}

.retry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  padding: 10px 20px;
  font-family: "SUIT-Variable", sans-serif;
  cursor: default;
  transition: background-color 0.3s, color 0.3s;
}

.retry.inactive {
  background-color: var(--Grayscale-Gray8);
  color: var(--Grayscale-Gray12);
}

.retry.active {
  background-color: var(--Grayscale-Gray1);
  color: var(--Grayscale-Gray12);
  cursor: pointer;
}

.retry span {
  font-weight: bold;
}

.submit-button{
  background: var(--Color-palette-Primary-Default);
  width: 100%;
  padding: 16px 0;
  font-size: 18px;
  text-align: center;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin-top: 80px;
  font-family: "SUIT-Variable", sans-serif;
}

.submit-button.expired {
  background: var(--Grayscale-Gray8);
  color: var(--Grayscale-Gray12);
  cursor: not-allowed;
}

.submit-button.disabled {
  background: var(--Grayscale-Gray8);
  color: var(--Grayscale-Gray12);
  cursor: not-allowed;
}


</style>