<template>
  <input
      autofocus
      :value="modelValue"
      placeholder="숫자를 입력해주세요"
      :maxlength="maxLength"
      type="tel"
      class="text-input"
      @input="updateValue"
      @keyup.enter="submitAnswer"
      @blur="submitAnswer"
      ref="input">
</template>

<script>
export default {
  name: "NumberInput",
  props: {
    inputItem: {
      type: Object,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 20
    }
  },
  methods: {
    updateValue(event) {
      const numericValue = event.target.value.replace(/[^0-9]/g, '').slice(0, this.maxLength);
      event.target.value = numericValue;  // 입력 필드 값을 직접 업데이트
      this.$emit('update:modelValue', numericValue);
    },
    submitAnswer() {
      if (this.modelValue.trim()) {
        this.$emit('submitAnswer', {
          ...this.inputItem,
          value: this.modelValue,
          inputFormType: this.inputItem?.inputFormType,
          inputFormSubtype: this.inputItem?.inputFormSubtype
        })
        this.$refs.input.blur();
      }
    },
    focus() {
      this.$refs.input.focus();
      this.forceKeyboardOpen();
    },
    forceKeyboardOpen() {
      setTimeout(() => {
        this.$refs.input.blur();
        this.$refs.input.focus();
      }, 50);

      setTimeout(() => {
        this.$refs.input.select();
      }, 100);
    },
  },
  mounted() {
    this.$refs.input.focus();
  },
  emits: ['update:modelValue', 'submitAnswer', 'focus']
}
</script>

<style scoped>
.text-input{
  width: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  outline: 2px solid var(--Grayscale-Gray10);
  border-bottom: none;
}

.text-input:focus{
  outline: 2px solid var(--Color-palette-Primary-Default);
}

.text-input::placeholder{
  font-size: 18px;
  color: var(--Grayscale-Gray8, #8C8C8C);
}
</style>