<template>
  <div class="coupon-screen">
    <header v-safe-area-top>
      <div style="width: 54px; height: 24px; display: flex; align-items: center; justify-content: center;" @click="$emit('close')">
        <img :src="require('@/my_page/assets/v2/exit-icon.svg')"  alt=""/>
      </div>
      <span>쿠폰등록</span>
    </header>
    <div class="container" :style="{marginTop: `${getMarginTop}px`, height: `calc(100% - ${getMarginTop}px `}">
      <div class="input-number-container">
        <span>쿠폰코드</span>
        <input type="text" v-model="code" :placeholder="placeholder" @input="input"/>
      </div>
      <div class="event-container" v-if="eventData.length > 0">
        <span>진행 중인 이벤트</span>
        <div class="event-list">
          <div v-wave v-for="(item, idx) in eventData" :key="idx" class="event-box" @click="goEvent(item.title, item.landing_url)">
            <h1>{{item.title}}</h1>
            <span>{{item.description}}</span>
          </div>
        </div>
      </div>
      <button :class="disabled ? 'btn active ' : 'btn inactive'" @click="handleBtn">
        <div v-if="isClicked" class="loader"></div>
        <span v-else>등록</span>
      </button>
    </div>
  </div>
</template>

<script>
import {sumbitCouponCode} from "@/tarot_box/helper/mytarot_api";
import {hideBottomBar, logEvent, showBottomBar} from "@/tarot_box/helper/native_api";

export default{
  name: 'CouponPopup',
  props: {
    userPhoneNumber: {
      type: String,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    eventData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data(){
    return {
      eventList:[
        {
          title: '인스타채널 팔로우 이벤트',
          description: '마이타로 인스타채널 팔로우하면 선착순 100명에게 5500원 상당의 13클로버 지급!'
        },
      ],
      code: '',
      placeholder: '쿠폰코드를 입력해주세요',
      disabled: false,
      isClicked: false,
    }
  },
  mounted(){
    logEvent('coupon_into',{});
    hideBottomBar();
  },
  beforeUnmount() {
    showBottomBar();
  },
  computed: {
    getMarginTop() {
      const UrlParams = new URLSearchParams(window.location.search);
      return UrlParams.get('inset_top') ? Number(UrlParams.get('inset_top')) : 0;
    }
  },
  methods:{
    goBack(){
      this.$router.go(-1);
    },
    input(event){
      const input = event.target.value;
      const filteredInput = input.replace(/[^ㄱ-ㅎ가-힣a-zA-Z0-9]/g, '');
      this.code = filteredInput;

      if(this.code !== ''){
        this.disabled = true;
      }else{
        this.disabled = false;
      }
    },
    async handleBtn(){
      logEvent('coupon_input_click',{})
      this.isClicked = true;
      this.disabled = true;
      let res = await sumbitCouponCode(this.userPhoneNumber, this.userId, this.code);
      if (res.result === 'success') {
        logEvent('coupon_payment_success',{
          'coupon_code': this.code,
          'reward-clover': res.data.clover_rewarded
        })
        this.$emit('alert', '쿠폰이 등록되었습니다.');
        this.$emit('user-update')
        this.code= '';
      } else {
        const message = this.handleAlertMessageByStatus(res.data.status)
        logEvent('coupon_payment_fail',{
          'coupon_code': this.code,
          'status': res.data.status
        });
        this.$emit('alert', message);
      }
      this.isClicked = false;
      this.disabled = false;
    },
    handleAlertMessageByStatus(status){
      switch(status){
        case 'not_found':
          return '존재하지 않는 쿠폰번호입니다.';
        case 'expired':
          return '유효기간이 만료된 쿠폰번호입니다.';
        case 'already_rewarded':
          return '이미 등록된 쿠폰번호입니다.';
        case 'processing':
          return '처리중입니다';
      }
    },
    goEvent(eventName, landingUrl) {
      logEvent('coupon_event_click', {
        'event_name': eventName,
        'target_url': landingUrl
      });
      if (landingUrl.includes('1z_external_browser')) {
        window.open(landingUrl, '_blank');
      }
    }
  },
  emits: ['close', 'alert', 'update-user']
}
</script>

<style scoped>

div{
  box-sizing: border-box;
}
.coupon-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  max-width: 100%;
  margin: 0;
  background-color: white;
  z-index: 1000;
  overflow-y: auto;
}
.coupon-screen::-webkit-scrollbar {
  display: none;
}
header{
  position: fixed;
  top:0;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 2;
}
header img{
  position: absolute;
  left: 20px;
  z-index: 1;
}
header span{
  position: absolute;
  left: 0;
  right: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
}
.container{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 44px 24px 0 24px;
}
.input-number-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  margin-top: 30px;
  margin-bottom: 44px;
}
.input-number-container span{
  color: #242424;
  font-size: 14px;
  font-weight: 800;
  line-height: 120%;
}
input{
  box-sizing: border-box;
  width:100%;
  height: 48px;
  border: none;
  border-radius: 8px;
  background-color: #F5F5F5;
  color: #333333;
  padding-left: 15px;
}
input::placeholder{
  border-radius: 8px;
  color: #CCCCCC;
}
input:focus{
  border: 2px solid #242424;
  border-radius: 8px;
  outline: none;
}
.event-container{
  margin-bottom: 10px;
  text-align: left;
}
.event-container span{
  color: #242424;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
}
.event-list{
  display: flex;
  flex-direction: column;
  border-top: 1px solid #D9D9D9;
  margin-top: 16px;
}
.event-box{
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border-bottom: 1px solid #D9D9D9;
}
.event-box h1{
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  color: #333333;
}
.event-box span{
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: #777777;
}
.btn{
  box-sizing: border-box;
  width: calc(100% - 48px);
  max-width: 432px;
  height: 52px;
  border: none;
  border-radius: 8px;
  background-color: #1E2352;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  position: fixed;
  bottom: 20px;
}
.active{
  background-color: #1E2352;
}
.inactive{
  background-color: #68769E;
  pointer-events: none;
  cursor: none;
}
.loader {
  margin: 90% auto;
  height: 24px;
  width: 24px;
  border: 2px solid transparent;
  border-right-color: white;
  border-top-color: white;
  border-radius: 100%;
  animation: spin 800ms infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>