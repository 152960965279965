<script>
export default {
  name: "ZodiacGrid",
  props: {
    get: String,
    initialInputData: String
  },
  data() {
    return {
      zodiacSigns: [
        {type: '물병자리', description: '1/20 ~ 2/18'},
        {type: '물고기자리', description: '2/19 ~ 3/20'},
        {type: '양자리', description: '3/21 ~ 4/19'},
        {type: '황소자리', description: '4/20 ~ 5/20'},
        {type: '쌍둥이자리', description: '5/21 ~ 6/21'},
        {type: '게자리', description: '6/22 ~ 7/22'},
        {type: '사자자리', description: '7/23 ~ 8/22'},
        {type: '처녀자리', description: '8/23 ~ 9/23'},
        {type: '천칭자리', description: '9/24 ~ 10/22'},
        {type: '전갈자리', description: '10/23 ~ 11/22'},
        {type: '사수자리', description: '11/23 ~ 12/24'},
        {type: '염소자리', description: '12/25 ~ 1/19'},
      ],
      selected: this.initialInputData || ''
    }
  },
  methods: {
    selectZodiacSign(selectedZodiacSign) {
      this.selected = selectedZodiacSign;
      this.$emit('onInputDataChange', selectedZodiacSign);
    }
  },
};
</script>

<template>
  <div class="zodiac_grid">
    <div class="zodiac_button" v-wave v-for="zodiacSign in zodiacSigns" :key="zodiacSign.type" :class="{selected: selected===zodiacSign.type}" @click="selectZodiacSign(zodiacSign.type)">
      <div class="zodiac_type">{{zodiacSign.type}}</div>
      <div class="zodiac_description">{{zodiacSign.description}}</div>
    </div>
  </div>
</template>

<style scoped>
.zodiac_grid{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

.zodiac_button{
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 16px;

  width: 156px;
  height: 52px;
  flex-shrink: 0;

  border-radius: 90px;
  border: 1px solid #D9D9D9;
  background: #F0F0F0;
}

.zodiac_type{
  padding-bottom: 2px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.zodiac_description{
  color: #000;
  text-align: center;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}

.selected {
  border-radius: 90px;
  border: 1px solid #1E2352;
  background: #DDDFF8;
}
</style>