<template>
  <div class="review-cta-wrapper">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <path d="M43.9535 47.9985V12.4353C43.9535 10.1518 42.1359 8 39.894 8H12C14.1971 8 15.9754 10.0818 16.0461 12.302V47.8653C16.0461 50.1487 17.8637 52 20.1056 52H48C45.8029 52 44.0246 50.2192 43.9539 47.9989L43.9535 47.9985Z" fill="#C6DDF8"/>
      <path d="M12.0002 8C9.79103 8 8 10.0148 8 12.5V17H16V12.5C16 10.0148 14.209 8 11.9998 8H12.0002Z" fill="#5273A5"/>
      <path d="M38.9258 20.8026H22.3839C22.0536 20.8026 21.7861 20.5333 21.7861 20.2014C21.7861 19.8692 22.054 19.6003 22.3839 19.6003H38.9258C39.2562 19.6003 39.5236 19.8696 39.5236 20.2014C39.5236 20.5337 39.2558 20.8026 38.9258 20.8026Z" fill="#5682C2"/>
      <path d="M38.9258 26.5484H22.3839C22.0536 26.5484 21.7861 26.279 21.7861 25.9472C21.7861 25.615 22.054 25.3461 22.3839 25.3461H38.9258C39.2562 25.3461 39.5236 25.6154 39.5236 25.9472C39.5236 26.2794 39.2558 26.5484 38.9258 26.5484Z" fill="#5682C2"/>
      <path d="M38.9258 32.3428H22.3839C22.0536 32.3428 21.7861 32.0735 21.7861 31.7417C21.7861 31.4095 22.054 31.1405 22.3839 31.1405H38.9258C39.2562 31.1405 39.5236 31.4099 39.5236 31.7417C39.5236 32.0739 39.2558 32.3428 38.9258 32.3428Z" fill="#5682C2"/>
      <path d="M31.1583 38.0967H22.3839C22.0536 38.0967 21.7861 37.8273 21.7861 37.4955C21.7861 37.1633 22.054 36.8943 22.3839 36.8943H31.1583C31.4886 36.8943 31.7561 37.1637 31.7561 37.4955C31.7561 37.8277 31.4882 38.0967 31.1583 38.0967Z" fill="#5682C2"/>
      <path d="M48.8511 43.4322L24 43.5025V47.5025C24 49.7986 21.7829 52.0025 19.5 52.0025L48.8663 52.0492C51.1492 52.0492 53 49.8859 53 47.5898V43.4322H48.8511Z" fill="#6893D3"/>
      <path d="M19.8563 52C17.5716 52 15.7127 50.0238 15.7127 47.5948V12.2721C15.6495 10.2151 14.0818 8.59701 12.1437 8.59701C10.2055 8.59701 8.56193 10.3054 8.56193 12.4052L8.56193 17H8L8 12.4052C8 9.97624 9.85891 8 12.1437 8C14.4284 8 16.2013 9.8725 16.2746 12.2629V47.5948C16.2746 49.6946 17.8812 51.4026 19.8563 51.4026C21.8315 51.4026 23.4381 49.6946 23.4381 47.5948L23.4381 43.5H24V47.5948C24 50.0238 22.1411 52 19.8563 52Z" fill="#F7F9FF"/>
      <path d="M52.1863 24C52.6393 23.5382 55.277 20.8366 55.9005 20.201C56.1117 19.9857 56.077 19.7037 55.9979 19.6255C55.4818 19.1165 55.2084 18.8469 54.692 18.3378C54.1572 17.8108 53.9262 17.5831 53.3918 17.0556C53.3131 16.9779 53.0253 16.9556 52.8208 17.1639C52.362 17.6315 49.7244 20.3331 49.1066 20.9628C48.9021 21.1715 48.9645 21.4238 49.0435 21.5012C49.5783 22.0282 49.8093 22.256 50.3437 22.7834C50.8597 23.2925 51.1332 23.562 51.6496 24.0711C51.7287 24.1492 52.0025 24.1871 52.1863 23.9996V24Z" fill="#CFD8EA"/>
      <path d="M31.4795 42.3978C33.3265 41.3969 34.9807 40.4983 36.6247 39.6087C36.8662 39.4778 37.1073 39.3473 37.3492 39.2169C36.8203 38.6953 36.2913 38.1734 35.7624 37.6518C35.2171 37.1142 34.6722 36.5766 34.1269 36.0391C33.9988 36.2879 33.8683 36.5403 33.7367 36.7954C32.8408 38.5316 31.8838 40.3869 31.0503 41.9958C30.974 42.1727 30.9787 42.2786 31.1049 42.4033C31.1699 42.4673 31.3012 42.4704 31.4792 42.3978H31.4795Z" fill="#1E2352"/>
      <path d="M37.8817 39.0833C43.7135 33.1336 48.5958 28.1524 54.4277 22.2024C54.7397 21.8839 54.6672 21.4288 54.4815 21.2456C54.2384 21.0057 53.9958 20.7674 53.1455 19.9282C52.6111 19.4008 52.2964 19.0914 51.762 18.5639C51.4983 18.3037 51.0539 18.3698 50.8019 18.6268C44.97 24.5765 40.0515 29.5948 34.2197 35.5445C34.0152 35.7531 34.0113 36.1618 34.2664 36.4134C34.8008 36.9408 35.1155 37.2502 35.6499 37.7776C36.5317 38.6481 36.7817 38.8934 36.9858 39.095C37.2336 39.3396 37.6978 39.2708 37.8817 39.0833Z" fill="#1E2352"/>
    </svg>

    <div class="review-cta-text-area">
      <div class="review-cta-title title5">
        리뷰 쓰고 +{{feedbackReward}}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.9655 8.5L8.00027 8.46138C8.01179 8.47432 8.02338 8.48719 8.03504 8.5C8.02338 8.51281 8.0118 8.52569 8.00027 8.53863C7.98875 8.52569 7.97716 8.51281 7.9655 8.5ZM8.00027 14.9616C7.15828 15.9073 5.94944 16.5 4.60633 16.5C2.06233 16.5 0 14.3734 0 11.7501C0 10.4928 0.47373 9.34966 1.24717 8.5C0.47373 7.65035 0 6.50716 0 5.24988C0 2.62659 2.06233 0.5 4.60633 0.5C5.94944 0.5 7.15828 1.09274 8.00027 2.03838C8.84226 1.09274 10.0511 0.5 11.3942 0.5C13.9382 0.5 16.0005 2.62659 16.0005 5.24988C16.0005 6.50716 15.5268 7.65035 14.7534 8.5C15.5268 9.34966 16.0005 10.4928 16.0005 11.7501C16.0005 14.3734 13.9382 16.5 11.3942 16.5C10.0511 16.5 8.84226 15.9073 8.00027 14.9616Z" fill="#4BD1A9"/>
          <path d="M8.3717 12.2224C7.87248 12.2224 7.40525 12.136 6.97002 11.9632C6.5348 11.784 6.15397 11.5376 5.82755 11.224C5.50753 10.9103 5.25472 10.5455 5.06911 10.1295C4.8899 9.70708 4.80029 9.25265 4.80029 8.76622C4.80029 8.27339 4.8899 7.81896 5.06911 7.40294C5.25472 6.98051 5.51074 6.61249 5.83715 6.29887C6.16357 5.98526 6.5412 5.74204 6.97002 5.56923C7.40525 5.39002 7.87248 5.30042 8.3717 5.30042C8.74293 5.30042 9.10135 5.35162 9.44697 5.45402C9.79259 5.55643 10.1126 5.70044 10.407 5.88605C10.7078 6.07166 10.9639 6.29567 11.1751 6.55809L10.2342 7.48935C9.991 7.18853 9.70938 6.96131 9.38936 6.8077C9.07575 6.6541 8.73653 6.57729 8.3717 6.57729C8.07089 6.57729 7.78927 6.63489 7.52685 6.7501C7.26444 6.85891 7.03723 7.01252 6.84521 7.21093C6.6532 7.40294 6.50279 7.63335 6.39399 7.90217C6.28518 8.16459 6.23078 8.4526 6.23078 8.76622C6.23078 9.07344 6.28518 9.36146 6.39399 9.63027C6.50279 9.89269 6.6564 10.1231 6.85481 10.3215C7.05323 10.5135 7.28364 10.6639 7.54606 10.7727C7.81487 10.8815 8.10609 10.9359 8.41971 10.9359C8.77173 10.9359 9.10135 10.8623 9.40857 10.7151C9.71578 10.5679 9.9878 10.3567 10.2246 10.0815L11.1367 10.984C10.9255 11.24 10.6726 11.4608 10.3782 11.6464C10.0838 11.832 9.76379 11.976 9.41817 12.0784C9.07895 12.1744 8.73013 12.2224 8.3717 12.2224Z" fill="#121212"/>
        </svg>
        &nbsp;받기!
      </div>
      <div class="review-cta-subtitle text3">
        소중한 의견을 남겨주시면,<br>
        클로버를 돌려드려요
      </div>
    </div>

    <div class="review-button buttonM" @click="goReviewPage">
      리뷰 쓰기
    </div>
  </div>
</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: 'ReviewCTA',
  data() {
    return {
    };
  },
  props: {
    feedbackReward: Number,
    historyId: Number,
    hashId: String,
    title: String,
  },
  mounted() {
    const url = window.location.href;
    const resultPageUrl = url.split('/')[0];
    const boxId = resultPageUrl.split('?')[2];
    console.log(boxId)
  },
  methods: {
    goReviewPage() {
      logEvent("result_review_click", {
        "tarot_box_id": this.$route.params.boxId,
        "tarot_box_name": this.title,
      });
      const insetTop = new URL(window.location.href).searchParams.get("inset_top");
      const insetBottom = new URL(window.location.href).searchParams.get("inset_bottom")
      const buildNumber = new URL(window.location.href).searchParams.get("build_number");
      this.$router.push('/tarot_box/review/' + this.$route.params.boxId + '?history_id=' + this.historyId + '&hash_id=' + this.hashId + '&build_number=' + buildNumber + '&inset_top=' + insetTop + '&inset_bottom' + insetBottom);
    }
  }
};
</script>


<style scoped>
.review-cta-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px 12px 8px 8px;
  gap: 8px;

  width: calc(100% - 40px);
  height: 76px;
  flex-shrink: 0;
  box-sizing: border-box;

  border-radius: 8px;
  border: 0.5px solid #ECECEC;
  background: #FFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
}

.review-cta-text-area {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  text-align: left;
  flex: 1;
}

.review-cta-title {
  display: flex;
  color: var(--Color-palette-Primary-Default);
}

.review-cta-subtitle {
  color: var(--Grayscale-Gray5);
}

.review-button {
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  gap: 10px;

  border-radius: 4px;
  background: var(--Color-palette-Primary-Default);
  color: var(--Grayscale-Gray12);
}
</style>