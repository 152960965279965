<template>
  <div class="page">
    <div v-show="!imgLoad">
      <img class="loadingGif" src="../../assets/images/Loading.gif" alt=""/>
    </div>

    <div class="header" :style="headerStyle"/>

    <img v-show="imgLoad" :src="this.pageData.backgroundImage" class="backgroundImage" alt=""
         @load="backgroundImageLoad = true"/>

    <div v-show="imgLoad" class="contents" :style="contentsStyle">
      <div :style="spaceStyle"/>
      <img :style="headerImgStyle" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/tarot_box_page_images%2Fdaily_header.png?alt=media&token=1e4cde8e-8d24-4ad9-8aee-049bef4ffa85" alt=""/>
      <div v-if="parseInt(tarotBoxId) === 54">
        <div class="user" :style="userStyle">{{userNameText}}</div>
        <img class="symbol" :style="symbolStyle" :src="this.pageData.imageObject" alt="">
      </div>

    </div>
    <div class="footer">
      <ResultFooter :index="index" :max_index="max_index" @next="nextStage" @prev="preStage"></ResultFooter>
    </div>
  </div>
</template>

<script>
import ResultFooter from "./ResultFooter.vue";

export default {
  data() {
    const appHeaderHeight = 54;
    const userTextLineHeight = 25;
    const footerHeight = 46;
    const fixedHeightValue = `calc(var(--vh, 1vh) * 100 - ${appHeaderHeight}px - ${userTextLineHeight}px - ${footerHeight}px)`;
    return {
      appHeaderHeight,
      footerHeight,
      symbolWidth: 360,
      spacePerSymbolWidth: 46 / 360,
      userTextLineHeight,
      userTextLeftPaddingPerSymbolWidth: 20 / 360,
      fixedHeightValue,
      backgroundImageLoad: false,
      symbolImageLoad: false,
    };
  },
  components: {
    ResultFooter,
  },
  props: {
    language: {
      type: String,
      default: "ko"
    },
    pageData: {
      hasHeaderImg: false,
      textColor: "#000000",
      imageObject: "",
      backgroundImage: "",

      userName: "",
      title: "",
      subTitle: "",
    },
    index: Number,
    max_index: Number,
    tarotBoxId: String || Number,
  },
  methods: {
    nextStage(){
      this.$emit('next');
    },
    preStage(){
      this.$emit('prev');
    },
    closePage(){
      this.$emit('close');
    },
  },
  computed: {
    imgLoad() {
      return this.backgroundImageLoad;
    },
    userNameText() {
      if (this.language === 'ko') {
        return `${this.pageData.userName}님의`;
      } else if (this.language === 'en') {
        return `For ${this.pageData.userName},`;
      }
      return `${this.pageData.userName}님의`;
    },
    symbolHeightPerSymbolWidth() {
      return this.pageData.hasHeaderImg ? 429 / 360 : 558 / 360;
    },
    headerImgHeightPerSymbolWidth() {
      return this.pageData.hasHeaderImg ? 122 / 360 : 0;
    },
    headerStyle(){
      return{
        width: `100vw`,
        height: `${this.appHeaderHeight}px`
      }
    },
    contentsStyle(){
      return{
        marginTop: `${this.appHeaderHeight}px`,
        marginBottom: `${this.footerHeight}px`,

        maxWidth: `100vw`,
        maxHeight: `calc(var(--vh, 1vh) * 100 - ${this.appHeaderHeight}px - ${this.footerHeight}px)`,
      }
    },
    spaceStyle() {
      const spaceHeight = `calc(${this.fixedHeightValue} * (${this.spacePerSymbolWidth} / (${this.spacePerSymbolWidth} + ${this.symbolHeightPerSymbolWidth} + ${this.headerImgHeightPerSymbolWidth})))`;
      return {
        height: spaceHeight,
      };
    },
    headerImgStyle() {
      const maxHeightValue = `calc(${this.fixedHeightValue} * (${this.headerImgHeightPerSymbolWidth} / (${this.spacePerSymbolWidth} + ${this.symbolHeightPerSymbolWidth} + ${this.headerImgHeightPerSymbolWidth})))`;
      return {
        maxWidth: '100vw',
        maxHeight: maxHeightValue,
      };
    },
    symbolStyle() {
      const symbolMaxHeight = `calc(${this.fixedHeightValue} * (${this.symbolHeightPerSymbolWidth} / (${this.spacePerSymbolWidth} + ${this.symbolHeightPerSymbolWidth} + ${this.headerImgHeightPerSymbolWidth})))`;
      return {
        maxWidth: '100vw',
        maxHeight: symbolMaxHeight
      }
    },
    userStyle() {
      return {
        paddingLeft: `calc(${this.symbolWidth}px * ${this.userTextLeftPaddingPerSymbolWidth})`,
        height: `${this.userTextLineHeight}px`,
        color: this.pageData.textColor
      }
    },
  },
}
</script>

<style scoped>
.page {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

.loadingGif{
  width: 60px;
  height: 60px;
}

.header{
  position: fixed;
  top: 0;
}

.backgroundImage{
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.user{
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-align: left;
}

.contents{
  align-self: center;
  display: grid;
  z-index: 1;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 10;
}
</style>
