<template>
  <div className="screen">
    <div className="wrapper">
      <div className="image-container">
        <div className="back-button" @click="goBack"/>
        <!--        <div class="tarot-box-button">
                  <img @click="goFirstTarotBox" src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2FGroup%201396.png?alt=media&token=881b7295-098c-4b2a-853c-02b1f6c6f1f3">
                  <img @click="goSecondTarotBox" src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2FGroup%201396.png?alt=media&token=881b7295-098c-4b2a-853c-02b1f6c6f1f3">
                  <img @click="goThirdTarotBox" src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2FGroup%201396.png?alt=media&token=881b7295-098c-4b2a-853c-02b1f6c6f1f3">
                </div>-->
        <div className="tarot-box-single-button" id="first">
          <img @click="goFirstTarotBox"
               src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2Fjiho-rev-btn2.png?alt=media&token=77c22757-a3b3-42eb-b8e7-3cb84add07ec">
        </div>
        <div className="tarot-box-single-button" id="second">
          <img @click="goSecondTarotBox"
               src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2Fjiho-rev-btn1.png?alt=media&token=e3b15c0c-e4dc-413d-991a-118795496b65">
        </div>
        <div className="tarot-box-single-button" id="third">
          <img @click="goThirdTarotBox"
               src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2Fjiho-rev-btn3.png?alt=media&token=cba17acb-ed75-4a62-95df-33227bae4976">
        </div>
        <img
            src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2F%E1%84%8C%E1%85%B5%E1%84%92%E1%85%A9lp-rev-min.jpg?alt=media&token=85253bc2-61f1-417c-9265-87ea0d831550"
            alt="event_page" style="width: 100%; height: auto;">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {changeTopSafeAreaColor, logEvent} from "@/tarot_box/helper/native_api";
import {changeBottomSafeAreaColor, hideBottomBar} from "@/tarot_box/helper/native_api";

export default {
  name: "EventPage2",
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'promotionBanners', 'iconCategories', 'homeSections']),
  },
  mounted() {
    logEvent('event_into', {event_name: '재회사주전문 지호'});
    changeTopSafeAreaColor('#FF010217');
    window.onBackButtonClick = null;
    window.onBackButtonClick = this.handleButtonClick;
  },
  methods: {
    handleButtonClick() {
      this.goBack();
      return 'back';
    },
    goKakaoTalk() {
      window.location.href = 'https://pf.kakao.com/_jnixhG?1z_external_browser=true'
    },
    goBack() {
      let queryParams = { ...this.$route.query };
      delete queryParams.from_event;
      this.$router.push({path:`/tarot_box/home`, query: queryParams});
    },
    goFirstTarotBox() {
      hideBottomBar();
      changeBottomSafeAreaColor('#FF1E2352');
      const firstTarotBox = 130;
      logEvent('event_content_click', {
        'tarot_box_id': firstTarotBox,
      });
      changeTopSafeAreaColor('#FFFFFFFF');
      let queryParams = { ...this.$route.query };
      queryParams.tarot_box_id = firstTarotBox;
      queryParams.from_event = true;
      this.$router.push({path:`/tarot_box/home`, query: queryParams});
    },
    goSecondTarotBox() {
      hideBottomBar();
      changeBottomSafeAreaColor('#FF1E2352');
      const secondTarotBox = 139;
      logEvent('event_content_click', {
        'tarot_box_id': secondTarotBox,
      });
      changeTopSafeAreaColor('#FFFFFFFF');
      let queryParams = { ...this.$route.query };
      queryParams.tarot_box_id = secondTarotBox;
      queryParams.from_event = true;
      this.$router.push({path:`/tarot_box/home`, query: queryParams});
    },
    goThirdTarotBox() {
      hideBottomBar();
      changeBottomSafeAreaColor('#FF1E2352');
      const thirdTarotBox = 157;
      logEvent('event_content_click', {
        'tarot_box_id': thirdTarotBox,
      });
      changeTopSafeAreaColor('#FFFFFFFF');
      let queryParams = { ...this.$route.query };
      queryParams.tarot_box_id = thirdTarotBox;
      queryParams.from_event = true;
      this.$router.push({path:`/tarot_box/home`, query: queryParams});
    }
  }
}
</script>

<style scoped>
.screen {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: scroll;
  background: #0E1426;
}

.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.back-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  outline: none;
  background-color: transparent;
}

.back-button:focus {
  outline: none;
  background-color: transparent;
}

.submit-button {
  position: absolute;
  bottom: calc(119vw);
  width: 100%;
  height: 100px;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mytarot%2FGroup%201397.png?alt=media&token=a0f2f9c7-e09f-4231-8dbd-29083f7b0ead');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.tarot-box-button {
  position: absolute;
  top: calc(380vw);
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}

.tarot-box-button img {
  width: 25%;
}

.tarot-box-single-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tarot-box-single-button img {
  width: 95%;
}

#first {
  bottom: 194vw;
}

#second {
  bottom: 104vw;
}

#third {
  bottom: 14vw;
}
</style>