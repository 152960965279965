<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div class="confirm-popup-contents-title"/>
        <span class="title2">잠깐! 리뷰를 작성해주시면<br>
        클로버를 더 받을 수 있어요!</span>
        </div>
        <div class="confirm-popup-contents-delete">
          <div>자세한 리뷰를 20자 이상만 써주시면
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
              <span style="font-size: 16px; font-style: normal;font-weight: 700;">+{{boxData.reviewReward}}</span>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.9655 8.5L8.00027 8.46138C8.01179 8.47432 8.02338 8.48719 8.03504 8.5C8.02338 8.51281 8.0118 8.52569 8.00027 8.53863C7.98875 8.52569 7.97716 8.51281 7.9655 8.5ZM8.00027 14.9616C7.15828 15.9073 5.94944 16.5 4.60633 16.5C2.06233 16.5 0 14.3734 0 11.7501C0 10.4928 0.47373 9.34966 1.24717 8.5C0.47373 7.65035 0 6.50716 0 5.24988C0 2.62659 2.06233 0.5 4.60633 0.5C5.94944 0.5 7.15828 1.09274 8.00027 2.03838C8.84226 1.09274 10.0511 0.5 11.3942 0.5C13.9382 0.5 16.0005 2.62659 16.0005 5.24988C16.0005 6.50716 15.5268 7.65035 14.7534 8.5C15.5268 9.34966 16.0005 10.4928 16.0005 11.7501C16.0005 14.3734 13.9382 16.5 11.3942 16.5C10.0511 16.5 8.84226 15.9073 8.00027 14.9616Z" fill="#4BD1A9"/>
            <path d="M8.3717 12.2224C7.87248 12.2224 7.40525 12.136 6.97002 11.9632C6.5348 11.784 6.15397 11.5376 5.82755 11.224C5.50753 10.9103 5.25472 10.5455 5.06911 10.1295C4.8899 9.70708 4.80029 9.25265 4.80029 8.76622C4.80029 8.27339 4.8899 7.81896 5.06911 7.40294C5.25472 6.98051 5.51074 6.61249 5.83715 6.29887C6.16357 5.98526 6.5412 5.74204 6.97002 5.56923C7.40525 5.39002 7.87248 5.30042 8.3717 5.30042C8.74293 5.30042 9.10135 5.35162 9.44697 5.45402C9.79259 5.55643 10.1126 5.70044 10.407 5.88605C10.7078 6.07166 10.9639 6.29567 11.1751 6.55809L10.2342 7.48935C9.991 7.18853 9.70938 6.96131 9.38936 6.8077C9.07575 6.6541 8.73653 6.57729 8.3717 6.57729C8.07089 6.57729 7.78927 6.63489 7.52685 6.7501C7.26444 6.85891 7.03723 7.01252 6.84521 7.21093C6.6532 7.40294 6.50279 7.63335 6.39399 7.90217C6.28518 8.16459 6.23078 8.4526 6.23078 8.76622C6.23078 9.07344 6.28518 9.36146 6.39399 9.63027C6.50279 9.89269 6.6564 10.1231 6.85481 10.3215C7.05323 10.5135 7.28364 10.6639 7.54606 10.7727C7.81487 10.8815 8.10609 10.9359 8.41971 10.9359C8.77173 10.9359 9.10135 10.8623 9.40857 10.7151C9.71578 10.5679 9.9878 10.3567 10.2246 10.0815L11.1367 10.984C10.9255 11.24 10.6726 11.4608 10.3782 11.6464C10.0838 11.832 9.76379 11.976 9.41817 12.0784C9.07895 12.1744 8.73013 12.2224 8.3717 12.2224Z" fill="#121212"/>
          </svg>
              &nbsp;를 더 드립니다.
            </div>
          </div>
      </div>

      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="proceedCheckout">
          작성 완료
        </div>
        <div class="confirm-popup-button" v-wave @click="rewriteReview">
          +{{boxData.reviewReward}}
          <svg class="clover-icon"
              xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9562 9.9995C9.97081 9.98345 9.98533 9.96732 9.99976 9.95111C10.0142 9.96732 10.0287 9.98345 10.0433 9.9995C10.0287 10.0155 10.0142 10.0317 9.99976 10.0479C9.98533 10.0317 9.9708 10.0155 9.9562 9.9995ZM9.99976 18.0761C8.94734 19.2581 7.43637 19.999 5.75758 19.999C2.57775 19.999 0 17.3409 0 14.062C0 12.4904 0.592161 11.0615 1.55895 9.9995C0.592161 8.93748 0 7.50855 0 5.937C0 2.65808 2.57775 0 5.75758 0C7.43637 0 8.94734 0.740894 9.99976 1.92289C11.0522 0.740894 12.5632 0 14.242 0C17.4218 0 19.9995 2.65808 19.9995 5.937C19.9995 7.50855 19.4074 8.93748 18.4406 9.9995C19.4074 11.0615 19.9995 12.4904 19.9995 14.062C19.9995 17.3409 17.4218 19.999 14.242 19.999C12.5632 19.999 11.0522 19.2581 9.99976 18.0761Z" fill="#4BD1A9"/>
            <path d="M10.464 14.652C9.84 14.652 9.256 14.544 8.712 14.328C8.168 14.104 7.692 13.796 7.284 13.404C6.884 13.012 6.568 12.556 6.336 12.036C6.112 11.508 6 10.94 6 10.332C6 9.716 6.112 9.148 6.336 8.628C6.568 8.1 6.888 7.64 7.296 7.248C7.704 6.856 8.176 6.552 8.712 6.336C9.256 6.112 9.84 6 10.464 6C10.928 6 11.376 6.064 11.808 6.192C12.24 6.32 12.64 6.5 13.008 6.732C13.384 6.964 13.704 7.244 13.968 7.572L12.792 8.736C12.488 8.36 12.136 8.076 11.736 7.884C11.344 7.692 10.92 7.596 10.464 7.596C10.088 7.596 9.736 7.668 9.408 7.812C9.08 7.948 8.796 8.14 8.556 8.388C8.316 8.628 8.128 8.916 7.992 9.252C7.856 9.58 7.788 9.94 7.788 10.332C7.788 10.716 7.856 11.076 7.992 11.412C8.128 11.74 8.32 12.028 8.568 12.276C8.816 12.516 9.104 12.704 9.432 12.84C9.768 12.976 10.132 13.044 10.524 13.044C10.964 13.044 11.376 12.952 11.76 12.768C12.144 12.584 12.484 12.32 12.78 11.976L13.92 13.104C13.656 13.424 13.34 13.7 12.972 13.932C12.604 14.164 12.204 14.344 11.772 14.472C11.348 14.592 10.912 14.652 10.464 14.652Z" fill="black"/>
          </svg>
          작성하기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  components: {},
  mounted() {
    logEvent('review_onemore_popup', {
      'tarot_box_id': this.boxData.id,
      'tarot_box_name': this.boxData.title,
    });
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes']),
  },
  props: {
    boxData: {
      type: Object,
    },
    closePopup: {
      type: Function,
    },
    processCheckout: {
      type: Function,
    },
  },
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    rewriteReview() {
      logEvent('review_onemore_popup_go', {
        'tarot_box_id': this.boxData.id,
        'tarot_box_name': this.boxData.title,
      });
      this.$emit('closePopup');
    },
    proceedCheckout() {
      this.$emit('processCheckout');
    },
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 12px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 12px;
}

.confirm-popup-contents-title{
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  color: #1E2352;
}

.confirm-popup-contents-delete {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
  margin-top: 20px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: #1E2352;
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.confirm-popup-button:first-child {
  background: #E9E9E9;
  color: #6E6E6E;
}

.confirm-popup-button:last-child {
  background: #1E2352;
  color: #FFF;
}

.clover-icon {
  margin: 0 6px 0 4px;
}
</style>