<template>
<div class="page">
  <div class="popup">
    <div class="close_button" @click="closePopup">닫기</div>
    <div class="contents">
      <div class="guide_area">
        <div class="guide_title">👍 이런 사진을 올려주세요</div>
        <div class="guide_detail">얼굴이 가려지거나 기울어져있지 않은<br>무표정으로 정면을 바라본 사진 (여권사진 추천)</div>
        <div class="sample_img_container">
          <img class="sample_img" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EA%B4%80%EC%83%81%20%EC%9E%85%EB%A0%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%20%EC%9D%B4%EB%AF%B8%EC%A7%80%2Fguide_1.png?alt=media&token=709d4a8d-6908-433b-bbd5-b629380a002f" alt=""/>
          <div class="space_between_sample_img"/>
          <img class="sample_img" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EA%B4%80%EC%83%81%20%EC%9E%85%EB%A0%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%20%EC%9D%B4%EB%AF%B8%EC%A7%80%2Fguide_2.png?alt=media&token=32fe7d29-12cc-4d0d-94c0-cb19c57c6123" alt=""/>
        </div>
        <div class="guide_detail">양쪽 볼과 턱 끝이 보이는 사진</div>
        <div class="sample_img_container">
          <img class="sample_img" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EA%B4%80%EC%83%81%20%EC%9E%85%EB%A0%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%20%EC%9D%B4%EB%AF%B8%EC%A7%80%2Fguide_3.png?alt=media&token=29b7118d-3d3b-48bb-befe-6213fd661a22" alt=""/>
          <div class="space_between_sample_img"/>
          <img class="sample_img" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EA%B4%80%EC%83%81%20%EC%9E%85%EB%A0%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%20%EC%9D%B4%EB%AF%B8%EC%A7%80%2Fguide_4.png?alt=media&token=5bcaf7dc-6741-43ac-90f7-92c99656622c" alt=""/>
        </div>
      </div>
      <div class="guide_area" style="margin-bottom: 78px">
        <div class="guide_title">❌ 인식 불가능한 사진</div>
        <div class="guide_detail">얼굴이 가려지거나 측면, 얼굴이 작게 나온 사진</div>
        <div class="sample_img_container">
          <img class="sample_img" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EA%B4%80%EC%83%81%20%EC%9E%85%EB%A0%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%20%EC%9D%B4%EB%AF%B8%EC%A7%80%2Fguide_5.png?alt=media&token=e2fc33c3-660c-45d0-9ac9-95fc7f7eddba" alt=""/>
          <div class="space_between_sample_img"/>
          <img class="sample_img" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EA%B4%80%EC%83%81%20%EC%9E%85%EB%A0%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%20%EC%9D%B4%EB%AF%B8%EC%A7%80%2Fguide_6.png?alt=media&token=b46f1d07-2674-4258-bdaf-06d5f1280a7e" alt=""/>
        </div>
        <div class="guide_detail">흐릿하거나 필터가 과한 사진</div>
        <div class="sample_img_container">
          <img class="sample_img" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EA%B4%80%EC%83%81%20%EC%9E%85%EB%A0%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%20%EC%9D%B4%EB%AF%B8%EC%A7%80%2Fguide_7.png?alt=media&token=89b9382d-648d-41cb-bde4-76dfe5bb5f31" alt=""/>
          <div class="space_between_sample_img"/>
          <img class="sample_img" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EA%B4%80%EC%83%81%20%EC%9E%85%EB%A0%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%20%EC%9D%B4%EB%AF%B8%EC%A7%80%2Fguide_8.png?alt=media&token=b308cac5-50a4-4af5-a3fe-9309ccc37247" alt=""/>
        </div>
      </div>
    </div>
    <StartButton :buttonText=startButtonText :able="true" v-wave @next="uploadImg"
                 style="position: fixed;  bottom: 0;"/>
  </div>
</div>
</template>

<script>
import StartButton from "./StartButton.vue";

export default {
  components: {StartButton},
  data() {
    return {
      startButtonText: '사진 등록하기',
    }
  },
  methods: {
    closePopup(){
      this.$emit('close');
    },
    uploadImg(){
      this.$emit('uploadImg');
    }
  }
}
</script>

<style scoped>
.page{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
}

.popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 90px;
  border-radius: 18px 18px 0 0;
  background: #FFF;
}

.contents {
  display: flex;
  flex-flow: column nowrap;
  max-width: calc(360px - 40px);
  max-height: calc(var(--vh, 1vh) * 100 - 90px - 36px); /* footer height */
  margin: 36px 20px 0 20px;
  overflow-y: auto;
}

@media (max-width: 360px) {
  .contents {
    max-width: calc(100vw - 40px);
  }
}

.close_button {
  position: absolute;
  right: 0;
  padding: 20px 20px 20px 20px;

  color: #666;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.guide_area {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 32px;
}

.guide_title {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-align: start;
}

.guide_detail {
  margin: 15px 0 10px 0;
  color: #7F7F7F;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: start;
}

.sample_img_container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.sample_img {
  width: 150px;
  height: 150px;
}

.space_between_sample_img {
  width: 20px;
}
</style>
