<template>
  <div class="clover-history">
    <div class="clover-history-header" v-safe-area-top>
      <div style="width: 30px; height: 30px; display: flex; align-items: center" @click="$emit('close')">
        <img style="transform: rotate(180deg)" src="@/my_page/assets/next.svg">
      </div>
      <div class="title4">친구 초대</div>
      <div style="width: 30px; height: 20px;"/>
    </div>

    <div class="clover-history-wrapper" v-safe-area-top>
      <ShimmerImage
          src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%EC%B9%9C%EA%B5%AC%EC%B4%88%EB%8C%80%20%EC%83%81%EB%8B%A8%EB%B0%B0%EB%84%88%2Finvite_banner%202.jpg?alt=media&token=2835bf7c-3dfd-40c0-9097-c87ec017d152"
          :imageStyle="{ width: '100vw' }"
          :heightRatio="143/360"/>
      <div class="referral-code" v-if="!isInputFocused">
        <div class="title3">
          내 초대 코드 {{ referralHistory.referral_code }}
        </div >
        <div class="copies">
          <div @click="$emit('share-link')" class="copy-block">
            <img src="@/my_page/assets/linkshare.png">
            <div class="text2">코드 복사</div>
          </div>
          <div @click="$emit('share-kakao')" class="copy-block">
            <img src="@/my_page/assets/kakaoshare.png">
            <div class="text2">카카오톡 공유</div>
          </div>
        </div>
      </div>
      <div class="referral-status"  v-if="!isInputFocused">
        <div class="block-header">
          <div class="title3">내 친구 초대 현황</div>
          <div class="text3 gray6">친구가 타로를 보면 클로버가 적립돼요</div>
        </div>
        <div class="blocks">
          <div class="block">
            <div class="title1">{{referralHistory.referral_count}}</div>
            <div class="text2">초대한 친구</div>
          </div>
          <div class="block">
            <div class="title1">{{formatNumber(referralHistory.reward_value)}}원</div>
            <div class="text2">상당의 클로버</div>
          </div>
        </div>
      </div>
      <div class="insert-code" :class="{ 'input-focused': isInputFocused }">
        <div class="block-header">
          <div class="title3">초대 코드 입력하기</div>
          <div class="text3 gray6">영문과 숫자만 입력할 수 있어요</div>
        </div>
        <div class="inputs">
          <input
              type="text"
              v-model="inputCode"
              :placeholder="referralRewarded ? '이미 초대 코드를 입력하셨어요' : '친구의 초대 코드를 입력해주세요'"
              maxlength="6"
              @focus="onInputFocus"
              @blur="onInputBlur"
              @input="onInputChange"
              :readonly="referralRewarded"
              :class="{ 'rewarded': referralRewarded }"
          >
          <button @click="submitReferral" :disabled="!isValidCode || referralRewarded" :class="{ 'disabled': !isValidCode || referralRewarded }">{{buttonText}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import {hideBottomBar, logEvent, showBottomBar} from "@/tarot_box/helper/native_api";

export default {
  name: "FriendInvitePopup",
  components: {
    ShimmerImage
  },
  data() {
    return {
      inputCode: '',
      isValidCode: false,
      isInputFocused: false,
    };
  },
  props: {
    referralHistory: {
      type: Object,
      default: () => {
        return {
          referral_code: '',
          referral_count: 0,
          reward_value: 0,
        }
      }
    },
    referralRewarded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputPlaceholder() {
      return this.referralRewarded ? '이미 초대 코드를 입력했어요' : '친구의 초대 코드를 입력해주세요';
    },
    buttonText() {
      return this.referralRewarded ? '입력완료' : '입력하기';
    }
  },
  watch: {
    inputCode: {
      immediate: true,
      handler(newValue) {
        this.isValidCode = newValue.length === 6;
      }
    }
  },
  mounted() {
    logEvent( 'invite_friends_into', {})
    hideBottomBar();
  },
  beforeUnmount(){
    showBottomBar();
  },
  methods: {
    submitReferral() {
      if (this.isValidCode) {
        logEvent('invite_friends_input_click', {});
        this.$emit('submit', this.inputCode);
        this.inputCode = '';
      }
    },
    onInputChange(event) {
      if (!this.referralRewarded) {
        const sanitizedInput = event.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase().slice(0, 6);
        event.target.value = sanitizedInput;
        this.inputCode = sanitizedInput;
        this.isValidCode = sanitizedInput.length === 6;
      }
    },

    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onInputFocus() {
      if (!this.referralRewarded) {
        this.isInputFocused = true;
        logEvent('invite_friends_code_input_click', {});
        this.$nextTick(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });
      }
    },
    onInputBlur() {
      this.isInputFocused = false;
      window.scrollTo(0, 0);
    },
  },
  emits: ['close', 'share-kakao', 'share-link', 'submit'],
}
</script>

<style scoped>
.clover-history {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: white;
}

.clover-history-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 20px 24px;
  margin: 20px 0 0 0;
  box-sizing: border-box;
  background-color: white;
  z-index: 1;
}

.clover-history-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 56px;
  height: calc(100dvh - 56px);
  width: 100%;
  overflow-y: auto;
  gap: 30px;
}

.insert-code.input-focused {
  margin-bottom: 200px;
}

.referral-code{
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
}

.copies{
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  padding: 30px 0;
  box-sizing: border-box;
  border: 1px solid var(--Grayscale-Gray10);
  background: var(--Grayscale-Gray11);
}

.copies img{
  width: 46px;
  height: 46px;
}

.referral-status{
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
}

.block-header{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.blocks{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 10px;
}

.block{
  display: flex;
  flex: 1;
  padding: 30px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--Grayscale-Gray10);
  background: var(--Grayscale-Gray12);
}

.insert-code{
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  margin-bottom: 30px;
}

.inputs{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

input{
  display: flex;
  flex: 5;
  padding: 20px 16px;
  box-sizing: border-box;
  border: 1px solid var(--Grayscale-Gray10);
  border-radius: 8px;
}

input::placeholder{
  font-size: 16px;
  color: var(--Grayscale-Gray8);
}

button{
  flex: 2;
  padding: 12px 16px;
  border-radius: 8px;
  background: var(--Color-palette-Primary-Default);
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
}

button.disabled {
  background: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray6);
  cursor: not-allowed;
}

input.rewarded {
  background-color: var(--Grayscale-Gray11);
  color: var(--Grayscale-Gray6);
}

</style>