<template>
  <div class="buttonArea">
    <div class="button" :style="{'background': able? '#1E2352': '#9F9F9F'}" @click="handleClick">
      {{ buttonText || msg[language] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartButton',
  methods:{
    handleClick(){
      if(this.able){
        this.$emit('next');
      }
    }
  },
  props: {
    language: String,
    able: Boolean,
    buttonText: String,
  },
  data() {
    return {
      msg: {
        ko: "시작하기",
        en: "START"
      }
    }
  }
}
</script>

<style scoped>
.button{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 42px;
  background: #9F9F9F;
  border-radius: 22px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}

.buttonArea{
  position: absolute;
  bottom: 0px;
  width: 100vw;
  padding: 12px 20px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
</style>