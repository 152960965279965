<template>
  <div class="clover-history">
    <div class="clover-history-header" v-safe-area-top>
      <img style="transform: rotate(180deg)" src="@/my_page/assets/next.svg" @click="$emit('close')">
      <div class="title4">알림 설정</div>
      <div style="width: 16px; height: 16px;"/>
    </div>

    <div class="clover-history-wrapper" v-safe-area-top>
      <div class="alarm-setting">
        <div class="title3">오늘의 운세 알림</div>
        <div class="alarm-toggle-button">
          <label class="switch">
            <input type="checkbox" :checked="localAlarm" @change="handleAlarmChangeDebounced">
            <span class="slider round" :class="{ 'updating': isUpdating }"></span>
          </label>
        </div>
      </div>

      <div class="user-reminder" v-if="localAlarm">
        <div class="alarm-setting">
          <div class="title5">알림 시간</div>
          <div class="alarm-time" @click="openAlarmTimePopup" :class="{ 'updating': isUpdating }">
            {{ formattedReminderTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <AlarmTimeSettingPopup
      :isActive="isAlarmTimePopupActive"
      :initialTime="localAlarmTime"
      @save="setAlarmTime"
      @close="closeAlarmTimePopup"
  />
</template>

<script>
import AlarmTimeSettingPopup from "@/my_page/components/AlarmTimeSettingPopup.vue";
import { debounce } from 'lodash'
import {hideBottomBar, showBottomBar} from "@/tarot_box/helper/native_api";

export default {
  name: "AlarmSettings",
  inject: ['appListener', 'appCommander'],
  components: {
    AlarmTimeSettingPopup,
  },
  data() {
    return {
      localAlarm: false,
      localAlarmTime: '',
      isUpdating: false,
      isAlarmTimePopupActive: false,
      debouncedAlarmChange: null,
      setAlarmTimeSubscription: null,
    };
  },
  props: {
    reminderTime: {
      type: String,
    },
  },
  watch: {
    reminderTime: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal !== 'off') {
          this.localAlarm = true;
          this.localAlarmTime = this.convertTo12HourFormat(newVal);
        } else {
          this.localAlarm = false;
          this.localAlarmTime = '오전 07:00';
        }
        this.isUpdating = false;
      }
    },
  },
  computed: {
    formattedReminderTime() {
      return this.localAlarmTime || '시간 설정';
    }
  },
  created() {
    this.debouncedAlarmChange = debounce(this.handleAlarmChange, 300);
  },
  mounted() {
    hideBottomBar();
  },
  unmounted() {
    this.unsubscribeUpdateAlarmTime();
    showBottomBar();
  },
  methods: {
    handleAlarmChangeDebounced(event) {
      this.localAlarm = event.target.checked;
      this.debouncedAlarmChange();
    },
    handleAlarmChange() {
      this.isUpdating = true;
      if (this.localAlarm) {
        if (!this.localAlarmTime || this.localAlarmTime === '오전 07:00') {
          this.openAlarmTimePopup();
        } else {
          this.updateAlarmSettingsDebounced(this.convertTo24HourFormat(this.localAlarmTime));
        }
      } else {
        this.handleToggleOff();
      }
    },
    openAlarmTimePopup() {
      this.isAlarmTimePopupActive = true;
    },
    closeAlarmTimePopup() {
      this.isAlarmTimePopupActive = false;
      if (!this.localAlarmTime || this.localAlarmTime === '오전 07:00') {
        this.localAlarm = false;
        this.updateAlarmSettingsDebounced('off');
      }
    },
    handleToggleOff() {
      this.localAlarmTime = '오전 07:00';
      this.updateAlarmSettingsDebounced('off');
    },
    setAlarmTime: debounce(function({time12, time24}) {
      this.unsubscribeUpdateAlarmTime();
      this.setAlarmTimeSubscription = this.appListener.subscribeSingleAction('set_noti_permission', (result) => {
        if (result.result === true) {
          console.log('알림 권한이 허용되었습니다.');
          this.localAlarmTime = time12;
          this.updateAlarmSettingsDebounced(time24);
          this.isAlarmTimePopupActive = false;
        } else {
          console.log('알림 권한이 거부되었습니다.');
          this.localAlarm = false;
          this.updateAlarmSettingsDebounced('off');
        }
      });
      this.appCommander.callAppAction('alarm');
    }, 300),
    unsubscribeUpdateAlarmTime() {
      this.setAlarmTimeSubscription?.unsubscribe();
    },
    updateAlarmSettingsDebounced: debounce(function(time) {
      this.$emit('update-reminder-time', time);
      this.$emit('update-user');
      this.isUpdating = false;
    }, 300),
    convertTo12HourFormat(time24) {
      if (!time24) return '';
      const [hours, minutes] = time24.split(':');
      const hour = parseInt(hours);
      const ampm = hour >= 12 ? '오후' : '오전';
      const hour12 = hour % 12 || 12;
      return `${ampm} ${String(hour12).padStart(2, '0')}:${minutes}`;
    },
    convertTo24HourFormat(time12) {
      const [ampm, time] = time12.split(' ');
      let [hours, minutes] = time.split(':');
      hours = parseInt(hours);
      if (ampm === '오후' && hours !== 12) {
        hours += 12;
      } else if (ampm === '오전' && hours === 12) {
        hours = 0;
      }
      return `${String(hours).padStart(2, '0')}:${minutes}`;
    },
  },
  emits: ['close', 'update-reminder-time', 'update-user'],
}
</script>

<style scoped>
.clover-history {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.clover-history-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  margin: 20px 0;
  box-sizing: border-box;
  background-color: white;
  z-index: 1;
}

.clover-history-wrapper {
  margin-top: 56px;
  height: calc(100vh - 56px);
  overflow-y: auto;
}

.alarm-setting {
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: var(--Color-palette-Primary-Default);
  transition: background-color 0s ease;
}

.switch input:checked + .slider:before {
  transform: translateX(21px);
  transition: transform 0s ease;
}

.alarm-time {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: var(--Grayscale-Gray10);
  font-size: 14px;
}

.updating {
  opacity: 0.5;
  pointer-events: none;
}
</style>
