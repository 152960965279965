<template>
  <div class="history-screen">
    <header v-safe-area-top>
      <div class="padding" style="width: 100%; height: 20px;"/>
      <span>
        <img id="backbutton" :src="require('@/my_page/assets/v2/exit-icon.svg')" @click="$emit('close')" alt=""/>
        심층풀이 내역
      </span>
      <div class="padding" style="width: 100%; height: 20px;"/>
    </header>
    <div class="container" :style="{marginTop: `calc(44px + ${getMarginTop}px)`, height: `calc(100% - ${getMarginTop}px `}">
      <div class="history-list">
        <div v-if="paginatedHistory.length === 0" style="text-align: center; padding-top: 50px;">
          <span>심층풀이 내역이 없습니다.</span>
        </div>
        <div v-else v-for="(item, idx) in paginatedHistory" :key="idx" class="clover-box">
          <div class="clover-box-title">
            <h1>{{item.title}}</h1>
            <div>
              <span> {{ formatDate(item.timestamp) }}</span>
            </div>
          </div>
          <div class="clover-amount">
            <span :style="setFontColor(item.quantity > 0 ? false : true)">
              {{ formatPoints(item.quantity) }}회
            </span>
          </div>
        </div>
      </div>
      <div class="paging-container" v-if="extraHistory.length > itemsPerPage">
        <div class="move-btn" @click="prevPage" :class="{ disabled: currentPage === 1 }">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <rect x="29.5" y="29.5" width="29" height="29" rx="14.5" transform="rotate(-180 29.5 29.5)" stroke="#ECECEC"/>
            <path d="M13.5382 15L17.7061 19.1973C17.902 19.3946 18 19.6457 18 19.9507C18 20.2556 17.902 20.5067 17.7061 20.704C17.5102 20.9013 17.2608 21 16.958 21C16.6552 21 16.4059 20.9013 16.2099 20.704L11.2939 15.7534C11.187 15.6457 11.1113 15.5291 11.0668 15.4036C11.0223 15.278 11 15.1435 11 15C11 14.8565 11.0223 14.722 11.0668 14.5964C11.1113 14.4709 11.187 14.3543 11.2939 14.2466L16.2099 9.29597C16.4059 9.09866 16.6552 9 16.958 9C17.2608 9 17.5102 9.09866 17.7061 9.29597C17.902 9.49327 18 9.7444 18 10.0493C18 10.3543 17.902 10.6054 17.7061 10.8027L13.5382 15Z" fill="#B2B2B2"/>
          </svg>
        </div>
        <div><span>{{currentPage}}</span> / {{totalPages}}</div>
        <div class="move-btn" @click="nextPage" :class="{ disabled: currentPage === totalPages }">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#ECECEC"/>
            <path d="M16.4618 15L12.2939 10.8027C12.098 10.6054 12 10.3543 12 10.0493C12 9.74439 12.098 9.49327 12.2939 9.29596C12.4898 9.09865 12.7392 9 13.042 9C13.3448 9 13.5941 9.09865 13.7901 9.29596L18.7061 14.2466C18.813 14.3543 18.8887 14.4709 18.9332 14.5964C18.9777 14.722 19 14.8565 19 15C19 15.1435 18.9777 15.278 18.9332 15.4036C18.8887 15.5291 18.813 15.6457 18.7061 15.7534L13.7901 20.704C13.5941 20.9013 13.3448 21 13.042 21C12.7392 21 12.4898 20.9013 12.2939 20.704C12.098 20.5067 12 20.2556 12 19.9507C12 19.6457 12.098 19.3946 12.2939 19.1973L16.4618 15Z" fill="#B2B2B2"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hideBottomBar, logEvent, showBottomBar } from "@/tarot_box/helper/native_api";

export default {
  name: 'CloverHistoryPage',
  props: {
    extraHistory: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
    }
  },
  computed: {
    getMarginTop() {
      const UrlParams = new URLSearchParams(window.location.search);
      return UrlParams.get('inset_top') ? Number(UrlParams.get('inset_top')) : 0;
    },
    sortedHistory() {
      return [...this.extraHistory].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    },
    paginatedHistory() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedHistory.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.extraHistory.length / this.itemsPerPage);
    }
  },
  mounted() {
    hideBottomBar();
    logEvent('extra_question_history_into', {});
  },
  beforeUnmount() {
    showBottomBar();
  },
  methods: {
    setFontColor(isSpent) {
      return isSpent ? 'color: #121212' : 'color: #4089F6';
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.scrollToTop();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.scrollToTop();
      }
    },
    scrollToTop() {
      const screenElement = this.$refs.screenRef;
      if (screenElement) {
        screenElement.scrollTo({
          top: 0,
        });
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/\./g, '.').replace(',', '');
    },
    formatPoints(points) {
      const absPoints = Math.abs(points);
      const formattedPoints = absPoints.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return points > 0 ? `+${formattedPoints}` : `-${formattedPoints}`;
    },
  },
  emits: ['close'],
}
</script>

<style scoped>
div {
  box-sizing: border-box;
}

.history-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;
  background-color: white;
  z-index: 1000;
  overflow-y: auto;
}

.container {
  width: 100%;
  height: 100%;
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;
  background-color: white;
  z-index: 1;
}

header #backbutton{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

header span {
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
}
.history-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
}

.clover-box {
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clover-box-title {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-width:80%;
  word-break: keep-all;
}
.clover-box-title h1 {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  color: #242424;
  word-break: keep-all;
}

.clover-box-title div {
  display: flex;
  column-gap: 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  color: #999;
}

.clover-amount {
  display: flex;
  column-gap: 6px;
  align-items: center;
}

.clover-amount span {
  font-size: 18px;
  font-weight: 700;
  text-align: right;
}

.clover-amount svg {
  vertical-align: center;
}

.paging-container {
  width: 100%;
  max-width: 480px;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 12px;
  padding: 16px 24px 76px 0;
}

.paging-container span {
  color: #242424;
}

.paging-container div {
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  color: #B2B2B2;
}

.move-btn {
  display: flex;
  align-items: center;
}

.paging-container svg {
  width: 24px;
}
.move-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>