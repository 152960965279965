// sajuUtils.js

import moment from 'moment';

export function convertSajuData(answerData) {
    const sajuData = {
        date: '',
        time: '',
        gender: '',
        realTime: '',
    };

    answerData.forEach(item => {
        if (item.inputFormType === 'dateInput') {
            sajuData.date = formatDate(item.value);
        } else if (item.inputFormType === 'timeInput') {
            sajuData.time = formatTime(item.value);
            sajuData.realTime = formatTime(item.value);
        } else if (item.inputFormType === 'genderInput') {
            sajuData.gender = item.value;
        }
    });

    // Validation
    if (!isValidDate(sajuData.date)) {
        throw new Error('Invalid date format');
    }
    if (!isValidTime(sajuData.time)) {
        throw new Error('Invalid time format');
    }
    if (!isValidGender(sajuData.gender)) {
        throw new Error('Invalid gender value');
    }

    // 일반 시간을 사주의 '생시'로 변환
    const birthHour = convertToBirthHour(sajuData.time);

    return `${sajuData.date}/${birthHour}/${sajuData.gender}/${sajuData.realTime}`;
}

export function convertToSajuDateAndTime(answerData) {
    const sajuData = {
        date: '',
        time: '',
        gender: '',
        realTime: '',
    };

    answerData.forEach(item => {
        if (item.inputFormType === 'dateInput') {
            sajuData.date = formatDate(item.value);
        } else if (item.inputFormType === 'timeInput') {
            sajuData.time = formatTime(item.value);
            sajuData.realTime = item.value
        } else if (item.inputFormType === 'genderInput') {
            sajuData.gender = item.value;
        }
    });

    // Validation
    if (!isValidDate(sajuData.date)) {
        throw new Error('Invalid date format');
    }
    if (!isValidTime(sajuData.time)) {
        throw new Error('Invalid time format');
    }
    if (!isValidGender(sajuData.gender)) {
        throw new Error('Invalid gender value');
    }
    sajuData.date = moment(sajuData.date).format('YYYYMMDD');
    return {
        date: moment(sajuData.date).format('YYYYMMDD'),
        sajutime: convertToBirthHour(sajuData.time)
    };
}

function formatDate(dateString) {
    if (/^\d{8}$/.test(dateString)) {
        // YYYYMMDD 형식인 경우
        return moment(dateString, 'YYYYMMDD').format('YYYY-MM-DD');

    } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        // YYYY-MM-DD 형식인 경우
        return dateString;
    } else if (/^\d{4}년\s\d{1,2}월\s\d{1,2}일$/.test(dateString)) {
        // YYYY년 MM월 DD일 형식인 경우
        return moment(dateString, 'YYYY년 M월 D일').format('YYYY-MM-DD');
    } else {
        throw new Error('Invalid date format');
    }
}

function formatTime(timeString) {
    if (timeString === '태어난 시각 모름') {
        return '00:00';
    }
    // 오전 형식 처리
    else if (/^오전\s\d{1,2}시\s\d{1,2}분$/.test(timeString)) {
        const formatted = moment(timeString, 'A h시 m분').format('HH:mm');
        return formatted;
    }
    // 오후 형식 처리
    else if (/^오후\s\d{1,2}시\s\d{1,2}분$/.test(timeString)) {
        const [hours, minutes] = timeString.match(/\d+/g);
        const formattedHours = (parseInt(hours, 10) + 12) % 24;
        const formatted = `${formattedHours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')}`;
        return formatted;
    }
    // 24시간 형식 (HH시 mm분) 처리
    else if (/^\d{1,2}시\s\d{1,2}분$/.test(timeString)) {
        const [hours, minutes] = timeString.match(/\d+/g);
        const formattedHours = parseInt(hours, 10).toString().padStart(2, '0');
        const formattedMinutes = parseInt(minutes, 10).toString().padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }
    // 기존의 HH:mm 형식 처리
    else if (/^\d{1,2}:\d{2}$/.test(timeString)) {
        return timeString;
    }
    else {
        throw new Error('Invalid time format');
    }
}


function convertToBirthHour(time) {
    const [hour, minute] = time.split(':').map(value => parseInt(value));
    const totalMinutes = hour * 60 + minute;

    if (totalMinutes >= 1410 || totalMinutes < 90) return 0;  // 자시 (23:30 ~ 01:30)
    if (totalMinutes >= 90 && totalMinutes < 210) return 1;   // 축시 (01:30 ~ 03:30)
    if (totalMinutes >= 210 && totalMinutes < 330) return 2;  // 인시 (03:30 ~ 05:30)
    if (totalMinutes >= 330 && totalMinutes < 450) return 3;  // 묘시 (05:30 ~ 07:30)
    if (totalMinutes >= 450 && totalMinutes < 570) return 4;  // 진시 (07:30 ~ 09:30)
    if (totalMinutes >= 570 && totalMinutes < 690) return 5;  // 사시 (09:30 ~ 11:30)
    if (totalMinutes >= 690 && totalMinutes < 810) return 6;  // 오시 (11:30 ~ 13:30)
    if (totalMinutes >= 810 && totalMinutes < 930) return 7;  // 미시 (13:30 ~ 15:30)
    if (totalMinutes >= 930 && totalMinutes < 1050) return 8; // 신시 (15:30 ~ 17:30)
    if (totalMinutes >= 1050 && totalMinutes < 1170) return 9; // 유시 (17:30 ~ 19:30)
    if (totalMinutes >= 1170 && totalMinutes < 1290) return 10; // 술시 (19:30 ~ 21:30)
    if (totalMinutes >= 1290 && totalMinutes < 1410) return 11; // 해시 (21:30 ~ 23:30)
}

function isValidDate(date) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(date);
}

function isValidTime(time) {
    const timeRegex = /^\d{1,2}:\d{2}$/;
    return timeRegex.test(time);
}

function isValidGender(gender) {
    return gender === '남성' || gender === '여성';
}
