<template>
  <WebViewOverlay
      :isVisible="isWebViewVisible"
      :url="webViewUrl"
      @close="isWebViewVisible = false"/>
  <div class="consent-form">
    <div class="checkbox-group">
      <div class="text-area"  @click="toggleAllAll">
        <span class="title3">모두 동의</span>
        <span class="text2 gray4">아래 약관에 모두 동의합니다</span>
      </div>
      <label class="custom-checkbox">
        <input type="checkbox" v-model="allChecked" @change="toggleAll">
        <span class="checkmark" :class="{ 'checked': allChecked }">
          <CheckIcon :currentColor="'white'"/>
        </span>
      </label>
    </div>
    <div class="whitespace"/>
    <div class="checkbox-group" v-for="(item, index) in checkboxItems" :key="index">
      <div class="left-side">
        <div @click="toggleItem(index)">
          {{ item.required ? '(필수)' : '(선택)' }} {{ item.label }}
        </div>
        <span style="color:var(--gray8)" @click="openWebView(item.url)">＞</span>
      </div>
      <div>
        <label class="custom-detail-checkbox">
          <input type="checkbox" v-model="item.checked" @change="updateAllChecked">
          <CheckIcon :currentColor="item.checked ? 'var(--Color-palette-Primary-Default)' : 'var(--Grayscale-Gray8)'"/>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from "@/sign_in/assets/CheckIcon.vue";
import WebViewOverlay from "@/my_page/components/WebViewOverlay.vue";

export default {
  name: "ConsentForm",
  components: {WebViewOverlay, CheckIcon},
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allChecked: false,
      checkboxItems: [
        {label: '서비스 이용약관', checked: false, required: true, url: "https://terms.1zlabs.com/mytarot_service"},
        {label: '개인정보 수집 이용 동의', checked: false, required: true, url: "https://terms.1zlabs.com/mytarot_collect"},
        {label: '야간 알림 수신 동의', checked: false, required: false, url: "https://terms.1zlabs.com/mytarot_night"},
      ],
      isWebViewVisible: false,
      webViewUrl: '',
    };
  },
  mounted() {
    window.onBackButtonClick = this.onBackButtonClick;
  },
  beforeUnmount() {
    if (window.onBackButtonClick === this.onBackButtonClick) {
      window.onBackButtonClick = null;
    }
  },
  computed: {
    isFormValid() {
      return this.checkboxItems.filter(item => item.required).every(item => item.checked);
    }
  },
  watch: {
    isFormValid(newValue) {
      this.$emit('update:modelValue', newValue);
    }
  },
  methods: {
    onBackButtonClick() {
      if (this.isWebViewVisible) {
        this.isWebViewVisible = false;
      }
    },
    toggleAllAll() {
      this.allChecked = !this.allChecked;
      this.checkboxItems.forEach(item => item.checked = this.allChecked);
    },
    toggleAll() {
      this.checkboxItems.forEach(item => item.checked = this.allChecked);
    },
    updateAllChecked() {
      this.allChecked = this.checkboxItems.every(item => item.checked);
    },
    toggleItem(index) {
      this.checkboxItems[index].checked = !this.checkboxItems[index].checked;
      this.updateAllChecked();
    },
    openWebView(url) {
      this.webViewUrl = url;
      this.isWebViewVisible = true;
    },
  },
  emits: ['input', 'update:modelValue']
}
</script>



<style scoped>
.consent-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.whitespace {
  height: 0.5px;
  width: 100%;
  background-color: var(--Grayscale-Gray8);
  margin: 20px 0;
}

.checkbox-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.text-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.left-side {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
}

.custom-detail-checkbox {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
}

.custom-detail-checkbox input{
  display: none;
}


.custom-checkbox input {
  position: absolute;
  opacity: 0;
}

.checkmark {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: var(--Grayscale-Gray8);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.checkmark.checked {
  background: var(--Color-palette-Primary-Default);
}

.checkmark img {
  width: 16px;
  height: 16px;
}

.checkmark.checked .check-icon {
  color: white; /* 체크되었을 때의 색상 */
}
</style>
