<template>
  <div class="table-wrap">
    <table v-if="transposedData.length">
      <thead>
      <tr>
        <th style="background-color: transparent; border:none;"></th>
        <th v-for="(header, index) in headers" :key="index">{{ labelMap[header] || header }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, rowIndex) in transposedData" :key="rowIndex" :class="getRowClass(row)">
        <td>{{ labelMap[row[0]] || row[0] }}</td>
        <td v-for="(cell, cellIndex) in row.slice(1)" :key="cellIndex" :class="getCellClass(row[0], cell.value)">
          <span v-if="cell.type === 'normal'" class="cell-value">{{ cell.value }}</span>
          <div v-else-if="cell.type === 'sipsung'">
            <span class="cell-value">{{ cell.value }}</span><br>
            <span class="good">{{ cell.good }}</span><br>
            <span class="bad">{{ cell.bad }}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  name: "TenPropertyTable",
  props: {
    manseData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      labelMap: {
        hour_manse: "시",
        day_manse: "일",
        month_manse: "월",
        year_manse: "년",
        date_str: "양력",
        heavenly_stem: "천간",
        heavenly_ohang: "오행",
        heavenly_sipsung: "십성",
        heavenly_sipsung_meaning: "십성",
        earthy_stem: "지지",
        earthy_ohang: "오행",
        earthy_sipsung: "십성",
        earthy_sipsung_meaning: "십성",

      }

    };
  },
  methods: {
    getRowClass(row) {
      if (row[0] === '특정_조건') {
        return 'special-row-class';
      } else {
        return '';
      }
    },
    getCellClass(rowName, cellValue) {
      if (rowName === 'heavenly_stem' || rowName === 'earthy_stem') {
        return this.getStemClass(cellValue);
      } else if (rowName === 'heavenly_ohang' || rowName === 'earthy_ohang') {
        return this.getOhangClass(cellValue);
      } else {
        return '';
      }
    },
    getStemClass(cellValue) {
      const stemClassMap = {
        '甲갑': 'stem-1',
        '乙을': 'stem-2',
        '寅인': 'stem-3',
        '卯묘': 'stem-4',
        '丙병': 'stem-5',
        '丁정': 'stem-6',
        '巳사': 'stem-7',
        '午오': 'stem-8',
        '戊무': 'stem-9',
        '己기': 'stem-10',
        '丑축': 'stem-11',
        '辰진': 'stem-12',
        '未미': 'stem-13',
        '戌술': 'stem-14',
        '庚경': 'stem-15',
        '辛신': 'stem-16',
        '申신': 'stem-17',
        '酉유': 'stem-18',
        '壬임': 'stem-19',
        '癸계': 'stem-20',
        '子자': 'stem-21',
        '亥해': 'stem-22'
      };
      return stemClassMap[cellValue] || '';
    },
    getOhangClass(cellValue) {
      const ohangClassMap = {
        '나무': 'ohang-wood',
        '불': 'ohang-fire',
        '흙': 'ohang-earth',
        '쇠': 'ohang-metal',
        '물': 'ohang-water'
      };
      return ohangClassMap[cellValue] || '';
    }
  },
  computed: {
    headers() {
      return Object.keys(this.manseData);
    },
    transposedData() {
      if (!this.manseData) return [];

      const mainKeys = ['hour_manse', 'day_manse', 'month_manse', 'year_manse'];
      const subKeys = ['date_str', 'heavenly_stem', 'heavenly_ohang', 'heavenly_sipsung', 'earthy_stem', 'earthy_ohang', 'earthy_sipsung'];

      return subKeys.map(subKey => {
        return [subKey, ...mainKeys.map(mainKey => {
          const cellValue = this.manseData[mainKey][subKey];

          if (subKey === 'earthy_sipsung' || subKey === 'heavenly_sipsung') {
            const meaning = this.manseData[mainKey][subKey + '_meaning'];
            return {
              type: 'sipsung',
              value: cellValue,
              good: meaning.good,
              bad: meaning.bad
            };
          }
          return { type: 'normal', value: cellValue };
        })];
      });
    }

  }
};
</script>

<style scoped>

thead th,
tbody td:first-child {
  font-size: 10px;
  font-weight: 600;
  background-color: #7579A5;
  border: 0.5px solid #7579A5;
  color: #FFFFFF;
}

thead th:first-child{
  width: 12%;
}

.table-wrap{
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

table {
  border-spacing: 5px;
  width: 100%;
}

th, td {
  background-color: #FBFBFB;
  border: 0.5px solid #D8D8D8;
  border-radius: 3px;
  padding: 4px;
  text-align: center;
  font-size: 12px;
  white-space: pre-line;
  font-weight: 400;

}
td.stem-1,td.stem-2,td.stem-3,td.stem-4 {
  color: #39AEAE;
  font-size: 18px;
  font-weight: 800;
  padding: 16px 0;
  border: 0.5px solid #1E2352;
}

td.stem-5,td.stem-6,td.stem-7,td.stem-8 {
  color: #F37272;
  font-size: 18px;
  font-weight: 800;
  padding: 16px 0;
  border: 0.5px solid #1E2352;
}

td.stem-9,td.stem-10,td.stem-11,td.stem-12 {
  color: #C29011;
  font-size: 18px;
  font-weight: 800;
  padding: 16px 0;
  border: 0.5px solid #1E2352;
}

td.stem-13,td.stem-14,td.stem-15,td.stem-16,td.stem-17,td.stem-18 {
  color: #858585;
  font-size: 18px;
  font-weight: 800;
  padding: 16px 0;
  border: 0.5px solid #1E2352;
}

td.stem-19,td.stem-20,td.stem-21,td.stem-22 {
  color: #363E59;
  font-size: 18px;
  font-weight: 800;
  padding: 16px 0;
  border: 0.5px solid #1E2352;
}

td.ohang-wood {
  background-image:
      url('../../assets/images/output_images/ten_property_table/tree.png'),
      linear-gradient(#39AEAE, #39AEAE);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  color: white;
}
td.ohang-fire {
  background-image:
      url('../../assets/images/output_images/ten_property_table/fire.png'),
      linear-gradient(#F37272, #F37272);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  color: white;
}
td.ohang-earth {
  background-image:
      url('../../assets/images/output_images/ten_property_table/earth.png'),
      linear-gradient(#A96500, #A96500);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  color: white;
}
td.ohang-metal {
  background-image:
      url('../../assets/images/output_images/ten_property_table/iron.png'),
      linear-gradient(#979797, #979797);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  color: white;
}
td.ohang-water {
  background-image:
      url('../../assets/images/output_images/ten_property_table/water.png'),
      linear-gradient(#363E59, #363E59);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  color: white;
}
.good,.bad{
  font-size: 8px;
  color: #484848;
}

</style>
