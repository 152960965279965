<script>
export default {
  name: "GenderGrid",
  props: {
    initialInputData: String
  },
  data() {
    return {
      genders: [
        {type: '남성'},
        {type: '여성'},
      ],
      selected: this.initialInputData || ''
    }
  },
  methods: {
    selectGender(genderType) {
      this.selected = genderType;
      this.$emit('onInputDataChange', genderType);
    }
  }
};
</script>

<template>
  <div class="gender_grid">
    <div class="gender_button" v-wave v-for="gender in genders" :key="gender.type" :class="{selected: selected===gender.type}" @click="selectGender(gender.type)">
      <div class="gender_type">{{gender.type}}</div>
    </div>
  </div>
</template>

<style scoped>
.gender_grid{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

.gender_button{
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 16px;

  width: 157px;
  height: 39px;
  flex-shrink: 0;

  border-radius: 90px;
  border: 1px solid #C8C8C8;
  background: #F5F5F5;
}

.gender_type{
  color: #000;
  text-align: center;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.selected {
  border-radius: 90px;
  border: 1px solid #1E2352;
  background: #DDDFF8;
}
</style>