<template>
  <div v-safe-area-top class="detail-header-bar">
    <div class="back-button" @click="this.$emit('goBack')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 20L8 12L16 4" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <h2 class="title2">{{title}}</h2>
    <div style="width: 24px; height: 24px; background-color: transparent"/>
  </div>
</template>

<script>
export default {
  name: "PageHeaderBar",
  emits: ['goBack'],
  props: {
    title: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>
.detail-header-bar{
  position: fixed;
  top: 0;
  width: 100%;
  padding: 12px 0;
  max-width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid var(--Grayscale-Gray10);
  flex-shrink: 0;
  z-index: 900;
  overscroll-behavior: none;
}

.back-button{
  z-index: 9999;
  left: 16px;
  margin: 20px 0;
}

h2{
  margin: 20px 0;
}
</style>