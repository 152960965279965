<template>
  <AppExitPopup v-if="isAppExitPopupActive" @close="isAppExitPopupActive = false"/>

  <teleport to="body">
    <ExtraQuestionTicketPaywall
        v-if="isExtraQuestionTicketPaywallActive"
        :is-teleported="true"
        :ticket-data="paywallData?.product_list"
        :tarot-user-data="paywallData?.tarot_user"
        @close="closeExtraQuestionTicketPaywall"
    />
  </teleport>

  <div class="screen" v-safe-area-top>
    <div class="logo">
      <img id="logo" :src="require('@/tarot_box/pages/home/assets/images/home-logo.png')" alt="logo">
    </div>
    <div class="profile">
      <div class="title2">{{ this.myPageUserInfo.displayName }}</div>
      <img @click="openNameChangePopup" src="@/my_page/assets/edit-icon.svg">
    </div>
    <div class="clover">
      <div class="text2">나의 보유 클로버</div>
      <div class="title2 clover-status">
        {{ formatNumber(this.myPageUserInfo.cloverBalance) }} <img src="@/tarot_box/pages/box_input_v2/assets/clover.svg">
      </div>
      <div class="clover-buttons">
        <div class="white-button" @click="openCloverHistoryPopup">클로버 내역</div>
        <div class="primary-button" @click="goPaywall">충전하기</div>
      </div>
    </div>
    <div class="functions">
      <div class="function" @click="openCsMail()">
        <img src="@/my_page/assets/help.svg">
        <div class="text3">고객센터</div>
      </div>
      <div class="function" @click="openWebView('https://terms.1zlabs.com/mytarot_service')">
        <img src="@/my_page/assets/policy.svg">
        <div class="text3">약관 및 정책</div>
      </div>
      <div class="function" @click="openAlarmSettingsPopup">
        <img src="@/my_page/assets/alarm.svg">
        <div class="text3">알림 설정</div>
      </div>
      <div class="function" @click="openWithdrawalPopup">
        <img src="@/my_page/assets/withdrawl.svg">
        <div class="text3">탈퇴하기</div>
      </div>
    </div>
    <div class="invite">
      <div class="promo" @click="openInvitePopup">
        <div class="text1">친구 초대하고
          <strong style="color: var(--Color-palette-Primary-Default); font-weight: bold">
            7,500원
          </strong>
          받기
        </div>
        <img src="@/my_page/assets/next.svg">
      </div>
    </div>
    <div class="share">
      <div class="code">
        <span class="text2 gray4">나의 초대 코드</span>
        <span style="font-weight: 900">{{ this.myPageUserInfo.referralCode }}</span>
      </div>
      <div class="share-buttons">
        <img @click="shareLink" src="@/my_page/assets/linkshare.png">
        <img @click="shareKakao" src="@/my_page/assets/kakaoshare.png">
      </div>
    </div>
    <div class="restore" @click="openRestorePopup">
      <div class="title4">구독 내역 복원</div>
    </div>
    <NameChangePopup
        v-model="isNameChangePopupActive"
        :userName="userName"
        @change-name="changeName"
    />
    <CloverHistory
        v-if="isCloverHistoryPopupActive"
        @close="isCloverHistoryPopupActive = false"
        :user-clover="userClover"
        :user-clover-history="userCloverHistory"/>
    <AlarmSettings
        v-if="isAlarmSettingsPopupActive"
        :reminder-time="this.myPageUserInfo.reminderTime "
        @update-user="getmyInfo"
        @update-reminder-time="updateReminderTime"
        @close="isAlarmSettingsPopupActive = false"/>
    <WebViewOverlay
        :isVisible="isWebViewVisible"
        :url="webViewUrl"
        @close="closeWebView"
    />
    <WithdrawalPopup
        :isActive="isWithdrawalPopupActive"
        @close="isWithdrawalPopupActive = false"
        @withdraw="removeMember"/>
    <RestorePopup
        :isActive="isRestorePopupActive"
        :is-processing="isRestoreProcessing"
        @close="isRestorePopupActive = false"/>
    <FriendInvitePopup
        v-if="isFriendPopupActive"
        :referral-history="referralHistory"
        :referral-rewarded="referralRewarded"
        @share-kakao="shareKakao"
        @share-link="shareLink"
        @submit="requestReferral"
        @close="isFriendPopupActive = false"/>
    <div class="toast" v-show="showToast" :class="{ 'show': showToast }">
      {{ toastMessage }}
    </div>
  </div>
</template>

<script>
import {
  getExtarQuestionPaywall,
  getInviteHistory,
  getTarotBoxItemsByFetch,
  getTarotUserInfo,
  getUserCloverHistory,
  changeNickname,
  makeReferral,
  removeUser,
  updateReminderTime,
  getEventList
} from "@/tarot_box/helper/mytarot_api";
import NameChangePopup from "@/my_page/components/NameChangePopup";
import CloverHistory from "@/my_page/components/CloverHistory.vue";
import AlarmSettings from "@/my_page/components/AlarmSettings.vue";
import WithdrawalPopup from "@/my_page/components/WithdrawalPopup.vue";
import WebViewOverlay from "@/my_page/components/WebViewOverlay.vue";
import RestorePopup from "@/my_page/components/RestorePopup.vue";
import FriendInvitePopup from "@/my_page/components/FriendInvitePopup.vue";
import friendInvitePopup from "@/my_page/components/FriendInvitePopup.vue";
import {remoteConfig} from "@/common/firebase";
import {mapActions, mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";
import AppExitPopup from "@/common/AppExitPopup.vue";
import ExtraQuestionTicketPaywall from "@/paywall/ExtraQuestionTicketPaywall.vue";

export default {
  name: "MyPage",
  components: {
    FriendInvitePopup,
    RestorePopup,
    AlarmSettings,
    CloverHistory,
    NameChangePopup,
    WebViewOverlay,
    WithdrawalPopup,
    AppExitPopup,
    ExtraQuestionTicketPaywall
  },
  data() {
    return {
      userId: '',
      userName: '',
      userClover: 0,
      userPhoneNumber: '',
      osType: '',
      referralCode: '',
      reminderTime: '',
      userCloverHistory: [],
      webViewUrl: '',
      referralHistory: {},

      referralRewarded: false,
      isNameChangePopupActive: false,
      isCloverHistoryPopupActive: false,
      isAlarmSettingsPopupActive: false,
      isWithdrawalPopupActive: false,
      isRestorePopupActive: false,
      isRestoreProcessing: false,
      isWebViewVisible: false,
      isFriendPopupActive: false,
      isAppExitPopupActive: false,

      paywallData: null,

      isExtraQuestionTicketPaywallActive: false,

      showToast: false,
      toastMessage: '',
    };
  },
  computed : {
    ...mapGetters(['myPageUserInfo']),
    friendInvitePopup() {
      return friendInvitePopup
    },
    formattedClover() {
      return this.userClover.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      const urlParams = new URLSearchParams(window.location.search);
      vm.userId = parseInt(urlParams.get('tarot_user_id'));
      vm.userPhoneNumber = urlParams.get('phone_number');

      if (vm.userId && vm.userPhoneNumber) {
        await vm.getmyInfo();
        await vm.getCloverhistory();
        vm.getReferralHistory();
      } else {
        console.error('필수 파라미터가 누락되었습니다.');
      }
    });
  },
  async mounted() {
    logEvent('mypage_into', {});
    const urlParams = new URLSearchParams(window.location.search);

    this.userId = parseInt(urlParams.get('tarot_user_id'));
    this.userPhoneNumber = urlParams.get('phone_number');
    this.osType = urlParams.get('os_type');

    window.onBackButtonClick = this.onBackButtonClick;

    if (this.userId && this.userPhoneNumber) {
      await this.getmyInfo();
      await this.getCloverhistory();
      this.getReferralHistory();
      await this.getPaywallInfo();
      this.getExtraQuestionTicketPaywallList();
      this.getTarotBoxesData();
      getEventList();
    }

    const openParam = urlParams.get('open');
    if (openParam === 'history') {
      urlParams.delete('open');
      const newQuery = Object.fromEntries(urlParams);
      await this.$router.replace({ query: newQuery });
      this.isCloverHistoryPopupActive = true;
    } else if (openParam === 'clover') {
      urlParams.delete('open');
      const newQuery = Object.fromEntries(urlParams);
      await this.$router.replace({ query: newQuery });
      this.goPaywall();
    }
  },
  beforeUnmount() {
    if (window.onBackButtonClick === this.onBackButtonClick) {
      window.onBackButtonClick = null;
    }
  },
  methods: {
    ...mapActions(['updatePaywall', 'setTarotBoxesData', 'updateMyPageUserInfo']),
    async getmyInfo() {
      let res = await getTarotUserInfo(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        const newUserInfo = {
          displayName: res.data.display_name,
          cloverBalance: res.data.clover_balance,
          referralCode: res.data.referral_code,
          reminderTime: res.data.reminder_time
        };
        await this.updateMyPageUserInfo(newUserInfo);
        this.userName = this.myPageUserInfo.displayName;
        this.userClover = this.myPageUserInfo.cloverBalance;
        this.referralCode = this.myPageUserInfo.referralCode;
        this.reminderTime = this.myPageUserInfo.reminderTime;
        this.referralRewarded = res.data.referral_rewarded;
      }
    },
    async getTarotBoxesData() {
      let res = await getTarotBoxItemsByFetch(this.userPhoneNumber, this.userId, false);
      if (res.result === 'success') {
        this.setTarotBoxesData(res.data.tarot_boxes);
      }
    },
    async getReferralHistory() {
      let res = await getInviteHistory(this.userPhoneNumber, this.userId);
      if (res.result === 'success') {
        this.referralHistory = res.data;
      }
    },
    async getExtraQuestionTicketPaywallList(){
      let res = await getExtarQuestionPaywall(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        this.paywallData = res.data;
      }
    },
    async changeName(newName) {
      let res = await changeNickname(this.userPhoneNumber,this.userId, newName);
      if (res.result === 'success') {
        await this.getmyInfo();
        this.userName = newName;
        this.isNameChangePopupActive = false;
      }
    },
    async getCloverhistory() {
      let res = await getUserCloverHistory(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        this.userCloverHistory = res.data;
      }
    },
    async updateReminderTime(time) {
      this.reminderTime = time;
      let res = await updateReminderTime(this.userPhoneNumber, this.userId, this.reminderTime);
      if (res.result === 'success') {
        await this.getmyInfo();
      }
    },
    async removeMember() {
      let res = await removeUser(this.userPhoneNumber, this.userId);
      if (res.result === 'success') {
        window.callNativeMethod(`{"action": "withdrawal"}`);
      }
    },
    async requestReferral(code) {
      let res = await makeReferral(this.userPhoneNumber, this.userId, code);
      if (res.result === 'success') {
        logEvent('invite_friends_success_popup', {})
        this.showToastMessage('친구 초대가 완료되었습니다');
        await this.getReferralHistory();
        await this.getmyInfo();
      } else if (res.message === 'already_exist') {
        logEvent('invite_friends_success_popup', {})
        this.showToastMessage('이미 초대한 친구입니다');
      } else {
        this.showToastMessage('유효하지 않은 초대 코드입니다');
        logEvent('invite_friends_fail_popup', {})
      }
    },
    async getPaywallInfo() {
      const configValue = remoteConfig.getValue('paywall_banner_list_v2');
      const parsedValue = JSON.parse(configValue.asString());
      this.updatePaywall(parsedValue);
    },
    shareKakao() {
      logEvent('mypage_kakao_share_click', {})
      window.callNativeMethod(`{"action" : "kakao_share"}`);
    },
    shareLink() {
      logEvent('mypage_link_copy_click', {})
      const shareCode = this.referralCode;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(shareCode)
            .then(() => {
              this.showToastMessage('코드가 복사되었습니다');
            })
            .catch(err => {
              console.error('Error in copying text: ', err);
            });
      } else {
        // Fallback: 기존 방식 사용
        const textarea = document.createElement('textarea');
        textarea.value = shareCode;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 9999);
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    },
    openWebView(url) {
      logEvent ('mypage_terms_click', {});
      this.webViewUrl = url;
      this.isWebViewVisible = true;
    },
    closeWebView() {
      this.isWebViewVisible = false;
    },
    openInvitePopup() {
      logEvent('mypage_invite_click', {});
      this.isFriendPopupActive = true;
    },
    openAlarmSettingsPopup() {
      logEvent ('mypage_invite_friends_click', {});
      this.isAlarmSettingsPopupActive = true;
    },
    openNameChangePopup() {
      logEvent ('mypage_rename_click', {});
      this.isNameChangePopupActive = true;
      logEvent ('mypage_rename_popup', {});
    },
    openWithdrawalPopup() {
      logEvent ('mypage_delete_click', {});
      this.isWithdrawalPopupActive = true;
    },
    openRestorePopup() {
      this.isRestorePopupActive = true;
      window.callNativeMethod(`{"action" : "restore_subscription"}`);
      this.isRestoreProcessing = true;
      setTimeout(() => {
        this.isRestoreProcessing = false;
      }, 2000);
    },
    openCsMail(){
      logEvent ('mypage_voc_click', {});
      window.callNativeMethod(`{"action" : "voc"}`);
    },
    openCloverHistoryPopup() {
      logEvent ('mypage_clover_history_click', {});
      this.isCloverHistoryPopupActive = true;
    },
    formatNumber(num) {
      if (num === 0) {
        return "-";
      }
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    showToastMessage(message){
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
    goPaywall() {
      logEvent('mypage_buy_click', {})
      const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
      const params = urlParams.toString();
      this.$router.push(`/paywall?${params}`);
    },
    onBackButtonClick() {
      if (this.isAppExitPopupActive) {
        return;
      } else if (this.isNameChangePopupActive) {
        return;
      } else if (this.isCloverHistoryPopupActive) {
        this.isCloverHistoryPopupActive = false;
      } else if (this.isAlarmSettingsPopupActive) {
        this.isAlarmSettingsPopupActive = false;
      } else if (this.isWithdrawalPopupActive) {
        return;
      } else if (this.isRestorePopupActive) {
        return;
      } else if (this.isFriendPopupActive) {
        this.isFriendPopupActive = false;
      } else if (this.isWebViewVisible) {
        this.isWebViewVisible = false;
      } else if (this.showToast) {
        return;
      } else {
        this.isAppExitPopupActive = true;
      }
    },
    closeExtraQuestionTicketPaywall() {
      this.isExtraQuestionTicketPaywallActive = false;
    }
  },
}
</script>



<style scoped>
.screen{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  max-width: 480px;
  max-height: 1024px;
  margin: 0 auto;
  padding: 0 19px 0 19px;
  box-sizing: border-box;
  background-color: var(--Grayscale-Gray12);
}

.logo{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  padding-top: 7px;
  box-sizing: border-box;
}

#logo{
  height: 44px;
  width: auto;
}

.profile{
  width: 100%;
  padding: 24px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.clover{
  width: 100%;
  padding: 20px 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 10px;

  background: #EBECFA;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  box-shadow: rgba(0,0,0, 0.05) 0px 4px 8px 0px;
}

.clover-status{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.clover-buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.white-button{
  width: 50%;
  padding: 10px 0;
  text-align: center;
  border: 2px solid var(--Color-palette-Primary-Default);
  font-weight: bold;
  color: var(--Color-palette-Primary-Default);
  border-radius: 8px;
  background-color: white;
}

.primary-button{
  width: 50%;
  padding: 10px 0;
  text-align: center;
  border: 2px solid var(--Color-palette-Primary-Default);
  border-radius: 8px;
  font-weight: bold;
  background-color: var(--Color-palette-Primary-Default);
  color: white;
}

.functions{
  width: 100%;
  padding: 15px 20px;
  margin-top: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  gap: 12px;

  background: var(--Grayscale-Gray12);
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  box-shadow: rgba(0,0,0, 0.05) 0px 4px 8px 0px;
}

.function{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.invite{
  width: 100%;
  padding: 15px 20px 0px 20px;
  margin-top: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 12px;

  background: var(--Grayscale-Gray12);
  border: 1px solid #d9d9d9;
  border-radius: 12px 12px 0 0;
  box-shadow: rgba(0,0,0, 0.05) 0px 4px 8px 0px;
}

.promo{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 24px 0;
}

.share{
  width: 100%;
  padding: 20px 20px 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  gap: 12px;

  background: #EBECFA;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0 0 12px 12px ;
  box-shadow: rgba(0,0,0, 0.05) 0px 4px 8px 0px;
}

.share-buttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.share-buttons img{
  width: 50px;
  height: 50px;
}

.code{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
}

.restore{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
  margin-top: 40px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  width: 90vw;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 10000;
  transition: opacity 0.5s;
}
.toast.show {
  opacity: 1;
  transition: opacity 0.5s;
}


</style>
