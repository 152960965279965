<script>
export default {
  methods: {
    retry() {
      this.$emit('retry');
    }
  }
}
</script>

<template>
  <div class="errorMsg">
    <div class="errorMsgTitle">오류가 발생했습니다.</div>
    <div class="errorMsgContent">다시 시도하려면 아래 버튼을 눌러주세요.</div>
    <div class="retryButton" @click="retry">다시 시도</div>
  </div>
</template>

<style scoped>
.errorMsg {
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorMsgTitle {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 700;

  margin-bottom: 15px;
}

.errorMsgContent {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;

  margin-bottom: 40px;
}

.retryButton {
  width: 120px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  background: #1E2352;

  color: #FFF;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
}
</style>