  <template>
    <div class="box-info-item">
      <ShimmerImage :src="thumbnailUrl"
                    :imageStyle="{ width: '45px'}"
                    :borderRadius="'4px'"
                    :heightRatio="1.3788"/>

      <div class="text-area">
        <h3 class="title5" v-html="parseText(item.title)"/>
        <span class="text3">{{ item.subTitle }}</span>
      </div>
    </div>
  </template>

  <script>
  import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
  export default {
    name: "BoxInfoItem",
    components: {
      ShimmerImage
    },
    data() {
      return {
      };
    },
    props: {
      item: Object,
    },
    computed: {
      thumbnailUrl() {
        return this.item && this.item.thumbnailImgUrl
            ? this.item.thumbnailImgUrl
            : 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/thumbnail_default.jpg?alt=media&token=cbd4c705-25c2-467c-a105-c48e0687e3a2';
      },
    },
    methods: {
      parseText(text) {
        if (text) {
          return text.replace(/\\n/g, '<br>');
        } else {
          return '';
        }
      },
    }
  }

  </script>

  <style scoped>
  .box-info-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px; /* 영역 사이의 간격 */
    padding: 12px;
    width: calc(100% - 16px * 2);
    margin: 8px 16px 16px 16px;
    box-sizing: border-box;
    height: 86px;

    border-radius: 8px;
    border: 0.5px solid var(--Grayscale-Gray10, #ECECEC);
    background: var(--Grayscale-Gray12, #FFF);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  }

  .text-area {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
    overflow: hidden; /* 말줄임표 표시를 위해 필요 */
  }

  .title5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .text3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>