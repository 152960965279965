<script>
export default {
  methods: {
    closePopup(){
      this.$emit('close');
    }
  },
  props:{
    card_name: String,
    card_explain: String,
    card_img: String,
  }
}
</script>

<template>
  <div class="page">
    <div class="popup">
      <div class="header">
        <img class="star" src="../../assets/images/output_images/star.png" alt=""/>
        <div class="title">{{ card_name }}</div>
        <img class="star" src="../../assets/images/output_images/star.png" alt=""/>
      </div>
      <div class="cardImage">
        <img class="card_img" :src="this.card_img" alt=""/>
        <div class="for_style"></div>
        <div class="card_meaning">
          <img class="text_card_meaning" src="../../assets/images/output_images/text_card_meaning.png" alt=""/>
        </div>
        <div class="card_explain_area">
          <div class="card_explain">{{ card_explain }}</div>
        </div>
      </div>
      <div class="close-button" @click="closePopup">닫기</div>
    </div>
  </div>
</template>

<style scoped>
.text_card_meaning{
  height: 17px;
  width: 74px
}

.close-button{
  width: 203px;
  height: 42px;
  display: flex;
  flex-flow: column;
  justify-content: center;

  border-radius: 12px;
  border: 1px solid #000;
  background: #000;
  margin: 21px 0 16px 0;

  color: #FFF;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.cardImage{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 0 0 0;
}

.card_explain_area{
  display: flex;
  flex-flow: column;
  width: 258px;
  height: 145px;

  border-radius: 12px;
  border: 1.2px solid #000;
  background: #DDDFF8;
}

.card_explain{
  margin: 40px 18px 18px 18px;
  text-align: left;
  overflow-y: auto;
}
.for_style{
  position: absolute;
  top: calc(81px + 189px + 31px - 14px + 5px);
  width: 128px;
  height: 37px;
  border-radius: 8px;
  border: 1.2px solid #000;
  background: #FFF;
}
.card_meaning{
  position: absolute;
  top: calc(81px + 189px + 31px - 14px);
  width: 128px;
  height: 37px;
  border-radius: 8px;
  border: 1.2px solid #000;
  background: #1E2352;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.card_img{
  width: 115px;
  height: 189px;
  margin-bottom: 31px;
  z-index:0;

  border-radius: 4px;
  border: 0.5px solid #E7E7E7;
}

.header{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0 27px 0;
}
.star{
  width: 31px;
  height: 31px;
  padding: 0 4px 0 4px;
}
.title{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1.2px solid #000;
  background: #FFF;
  height: 32px;
  width: calc(100% - 102px);

  color: #000;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}
.page{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
}
.popup{
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  width: 302px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 20px;
  border: 0.5px solid #D9D9D9;
  background: linear-gradient(180deg, rgb(30, 35, 82) 0%, #F6F6F6 10.68%, #F6F6F6 44.01%), #D9D9D9;
}
</style>
