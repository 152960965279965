<template>
  <div class="screen">
    <div class="top-bar">
      <span class="title1">MASON</span>
    </div>
    <div class="side-bar">
      <span class="title3">JSON Editor</span>
    </div>
    <JsonEditor />
  </div>
</template>

<script>
import JsonEditor from "@/mason/components/JsonEditor.vue";

export default {
  name: "MasonMainPage",
  components: {JsonEditor}
}
</script>

<style scoped>
.screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;
  width: 100vw;
  min-width: 1400px;
}

.top-bar {
  background-color: #f0f0f0;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
}

.side-bar {
  background-color: #f0f0f0;
  width: 200px;
  height: 100%;
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 99;
  border-right: 1px solid #000;
  padding: 30px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}


</style>