<template>
  <div class="detail-header-bar">
    <h2 class="title2">{{title}}</h2>
    <div class="back-button" @click="this.$emit('openExitPopup')">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z" fill="black"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeaderBar",
  emits: ['openExitPopup'],
  props: {
    title: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>
.detail-header-bar{
  width: 100%;
  height: 68px;
  max-width: 480px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  border-bottom: 1px solid var(--Grayscale-Gray10);
  flex-shrink: 0;
  z-index: 900;
}

.back-button{
  position: absolute;
  z-index: 9999;
  right: 16px;
}
</style>