<script>
export default {
  name: "MbtiGrid",
  props: {
    get: String,
    initialInputData: String
  },
  data() {
    return {
      personalities: [
        {type: 'INTJ', description: '용의주도한 전략가'},
        {type: 'INTP', description: '논리적인 사색가'},
        {type: 'ENTJ', description: '대담한 통솔자'},
        {type: 'ENTP', description: '뜨거운 논쟁을 즐기는 변론가'},
        {type: 'INFJ', description: '선의의 옹호자'},
        {type: 'INFP', description: '열정적인 중재자'},
        {type: 'ENFJ', description: '정의로운 사회운동가'},
        {type: 'ENFP', description: '재기발랄한 활동가'},
        {type: 'ISTJ', description: '청렴결백한 논리주의자'},
        {type: 'ISFJ', description: '용감한 수호자'},
        {type: 'ESTJ', description: '엄격한 관리자'},
        {type: 'ESFJ', description: '사교적인 외교관'},
        {type: 'ISTP', description: '만능 재주꾼'},
        {type: 'ISFP', description: '호기심 많은 예술가'},
        {type: 'ESTP', description: '모험을 즐기는 사업가'},
        {type: 'ESFP', description: '자유로운 영혼의 연예인'}
      ],
      selected: this.initialInputData || ''
    }
  },
  methods: {
    selectPersonality(personalityType) {
      this.selected = personalityType;
      this.$emit('onInputDataChange', personalityType);
    }
  },
};
</script>

<template>
  <div class="mbti_grid">
    <div class="mbti_button" v-wave v-for="personality in personalities" :key="personality.type" :class="{selected: selected===personality.type}" @click="selectPersonality(personality.type)">
      <div class="mbti_type">{{personality.type}}</div>
      <div class="mbti_description">{{personality.description}}</div>
    </div>
  </div>
</template>

<style scoped>
.mbti_grid{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

.mbti_button{
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 16px;

  width: 156px;
  height: 52px;
  flex-shrink: 0;

  border-radius: 90px;
  border: 1px solid #D9D9D9;
  background: #F0F0F0;
}

.mbti_type{
  padding-bottom: 2px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.mbti_description{
  color: #000;
  text-align: center;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}

.selected {
  border-radius: 90px;
  border: 1px solid #1E2352;
  background: #DDDFF8;
}
</style>