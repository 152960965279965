<script>
export default {
  name: "TermsConditions.vue",
  emits: ['close'],
}
</script>

<template>
  <div class="overlay">
    <div class="modal">
      <div class="modal-header">
        <h3>Terms and Conditions</h3>
        <button @click="$emit('close')" class="close">X</button>
      </div>
      <div class="modal-content">
        <div class="terms-content">
          <h1>Terms of Service</h1>
          <p><em>Last updated July 31, 2024</em></p>

          <p>These Terms of Service ("Terms") are an agreement formed between you ("You") and SUPERKEN LLC ("Company," "MASTRO," "we" or "us") and cover the app "MASTRO" (the "App"), and the related websites (the "Website") operated on behalf of MASTRO, and together with any content, tools, features and functionality offered on or through our App and Website (the "Services").</p>

          <p>These Terms govern your access to and use of the Services. Please read them carefully, as they include important information about your legal rights. By accessing and/or using the Services, you're agreeing to these Terms. If you don't understand or agree to these Terms, please don't use the Services.</p>

          <p>For purposes of these Terms, "you" and "your" means you as the user of the Services. If you use the Services on behalf of a company or other entity then "you" includes you and that entity, and you represent and warrant that (a) you are an authorized representative of the entity with the authority to bind the entity to these Terms, and (b) you agree to these Terms on the entity's behalf.</p>

          <p><strong>Note:</strong> these Terms contain an arbitration clause and class action waiver. By agreeing to these Terms, you agree (a) to resolve all disputes with us through binding individual arbitration, which means that you waive any right to have those disputes decided by a judge or jury, and (b) that you waive your right to participate in class actions, class arbitrations, or representative actions. You have the right to opt-out of arbitration as explained below.</p>

          <h2>Use of the Services</h2>
          <h3>Your Registration Obligations</h3>
          <p>You may be required to submit a registration form in order to access and use certain features of the Services. If you choose to register, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Services' registration form. Registration data and certain other information about you are governed by our Privacy Policy. If you are under 13 years old OR if you are an EU citizen or resident under 16 years old, you are not authorized to register to use the Services.</p>

          <h3>Member Account, Password and Security</h3>
          <p>You are responsible for maintaining the confidentiality of your password and account, if any, and are fully responsible for any and all activities that occur under your password or account. You agree to (a) immediately notify MASTRO of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session when accessing the Services. MASTRO will not be liable for any loss or damage arising from your failure to comply with this paragraph.</p>

          <h3>Modifications to Site</h3>
          <p>MASTRO reserves the right to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice. You agree that MASTRO will not be liable to you or to any third party for any modification, suspension or discontinuance of the Services.</p>

          <h3>General Practices Regarding Use and Storage</h3>
          <p>You acknowledge that MASTRO may establish general practices and limits concerning use of the Services, including without limitation the maximum period of time that data or other content will be retained by the Services and the maximum storage space that will be allotted on MASTRO's servers on your behalf. You agree that MASTRO has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded to the Services. You acknowledge that MASTRO reserves the right to terminate accounts that are inactive for an extended period of time. You further acknowledge that MASTRO reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.</p>

          <h2>Conditions Of Use</h2>
          <h3>User Conduct</h3>
          <p>In addition to agreeing to comply with our Acceptable Use Policy ("AUP"), which is incorporated herein, you agree to comply with the following conditions in using the Services. You are solely responsible for all code, video, images, information, data, text, software, music, sound, photographs, graphics, messages or other materials ("Content") that you upload, post, publish or display (hereinafter, "Upload") or email or otherwise transmit via the Services. Your use of the Services may also be subject to license and use restrictions set forth in the CreativeML Open RAIL-M License. The following are examples of the kind of Content and/or use that is illegal or prohibited by MASTRO. MASTRO reserves the right to investigate and take appropriate legal action against anyone who, in MASTRO's sole discretion, violates this provision, including without limitation, removing the offending Content from the Website or Services, suspending or terminating the account of such violators and reporting them to applicable law enforcement authorities.</p>

          <p>You agree to not use the Website or Services to:</p>
          <ul>
            <li>upload or transmit any Content that: (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or other relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, "junk mail," "spam," "chain letters," "pyramid schemes," "contests," "sweepstakes," or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another's privacy, hateful racially, ethnically or otherwise objectionable; or (vii) in the sole judgment of MASTRO, is objectionable or which restricts or inhibits any other person from using or enjoying the Website or Services, or which may expose MASTRO or its users to any harm or liability of any type;</li>
            <li>interfere with or disrupt the Website or Services or servers or networks connected to the Website or Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Website or Services;</li>
            <li>violate any applicable local, state, national or international law, or any regulations having the force of law;</li>
            <li>impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
            <li>solicit personal information from anyone under the age of 18;</li>
            <li>harvest or collect email addresses or other contact information of other users from the Website or Services by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;</li>
            <li>advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorized;</li>
            <li>further or promote any criminal activity or enterprise or provide instructional information about illegal activities;</li>
            <li>obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Website or Services;</li>
            <li>lease, lend, sell or sublicense any part of the Services;</li>
            <li>try to get around any technological measure designed to protect the Services or any technology associated with the Services;</li>
            <li>reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any Services source code, in whole or in part (unless a portion of code contained within the Services is released as open source and the open source license governing such code expressly permits reverse engineering, copying or other modification)</li>
            <li>use the Services to create malicious or abusive content (as determined by MASTRO in its sole discretion) or any content that violates a MASTRO policy; or use the Services (or any part thereof or any technology contained therein) in any manner that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable laws.</li>
          </ul>

          <h2>Intellectual Property Rights</h2>
          <h3>User Content Uploaded to the Site</h3>
          <p>With respect to the content or other materials you upload to or create using the Services (collectively, "User Content"), you represent and warrant that you own all right, title and interest in and to such User Content, including, without limitation, all copyrights and rights of publicity contained therein, or are otherwise permitted to use them for the applicable purposes, for example by license or legal exception or limitation. By creating or uploading any User Content you own or create using the Service, you retain all rights in it (to the extent you would otherwise hold such rights) and hereby grant and will grant MASTRO to the fullest extent permitted under the law a nonexclusive, worldwide, royalty-free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, store, modify and otherwise use your User Content for any MASTRO-related purpose in any form, medium or technology now known or later developed.</p>

          <h3>Website or Services Content, Software and Trademarks</h3>
          <p>You acknowledge and agree that the Website or Services may contain content or features ("Site Content") that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. In connection with your use of the Website or Services you will not engage in or use any data mining, robots, scraping or similar automated data gathering or extraction methods. If you are blocked by MASTRO from accessing the Website or Services (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address). Any use of the Website or Services, or the Site Content, other than as specifically authorized herein is strictly prohibited. Any rights not expressly granted herein are reserved by MASTRO.</p>

          <p>The MASTRO name and logos are trademarks of MASTRO (collectively the "MASTRO Trademarks"). Other trademarks used and displayed via the Website or Services may be trademarks of their respective owners who may or may not endorse or be affiliated with or connected to MASTRO. Nothing in these Terms or the Website or Service should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of MASTRO Trademarks without our prior written permission in each instance. All goodwill generated from the use of MASTRO Trademarks will insure to our exclusive benefit.</p>

          <h3>Third Party Material</h3>
          <p>Under no circumstances will MASTRO be liable in any way for any content or materials of any third parties, including, but not limited to infringement of intellectual property rights, for any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the use of any such content. You acknowledge that MASTRO does not pre-screen content, but that MASTRO and its designees will have the right (but not the obligation) in their sole discretion to refuse or remove any content that is available via the Website or Services. Without limiting the foregoing, MASTRO and its designees will have the right to remove any content that violates these Terms, our AUP, or is deemed by MASTRO, in its sole discretion, to be otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any content, including any reliance on the accuracy, completeness, or usefulness of such content.</p>

          <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the Website or Services ("Submissions") provided by you to MASTRO are non-confidential and MASTRO will be entitled to the unrestricted use and dissemination of these Submissions for any purpose, without acknowledgment of or compensation to you.</p>

          <p>Without limiting the foregoing, you acknowledge and agree that MASTRO may preserve content and may also disclose content and/or metadata if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process, applicable laws or government requests; (b) enforce these Terms; (c) respond to claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal safety of MASTRO, its users and the public. You understand that the technical processing and transmission of the Website and Services, including your content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.</p>

          <h3>Copyright Complaints</h3>
          <p>MASTRO respects the intellectual property of others, and we ask our users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have been otherwise violated, you should notify MASTRO of your infringement claim in accordance with the procedure set forth below.</p>

          <p>MASTRO will process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act ("DMCA") and other applicable intellectual property laws with respect to any alleged or actual infringement. A written notification of claimed copyright infringement should be mailed to:</p>

          <p>
            SUPERKEN LLC<br>
            Attn: Legal<br>
            info@1zlabs.com
          </p>

          <h2>Third Party Websites and Services</h2>
          <p>The Services may provide, or third parties may provide, links or other access to other sites and resources on the Internet or to third-party applications. MASTRO has no control over such sites, resources or applications and MASTRO is not responsible for and does not endorse such sites, resources or applications. You further acknowledge and agree that MASTRO will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods or services available on or through any such sites, resources or applications. Any dealings you have with third parties found while using the Services are between you and the third party, and you agree that MASTRO is not liable for any loss or claim that you may have against any such third party.</p>

          <h2>Indemnity and Release</h2>
          <p>You agree to release, indemnify and hold MASTRO and its affiliates and their officers, employees, directors and agents harmless from any from any and all losses, damages, expenses, including reasonable attorneys' fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Services or any related information, any User Content, your application(s) to MASTRO or the results thereof, your violation of these Terms of Use or your violation of any rights of another.</p>

          <p>If you are a California resident, you waive California Civil Code Section 1542, which says:</p>

          <blockquote>A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.</blockquote>

          <p>If you are a resident of another jurisdiction, you waive any comparable statute or doctrine.</p>

          <h2>Disclaimer of Warranty</h2>
          <p>Your use of the Service is at your sole risk. The site is provided on an "AS IS" and "AS AVAILABLE" basis. MASTRO expressly disclaims all warranties of any kind, whether express, implied or statutory, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. MASTRO makes no warranty that (i) the Services will meet your requirements, (ii) the Services will be uninterrupted, timely, secure, or error-free, or (iii) the results that may be obtained from the use of the Services will be accurate or reliable.</p>

          <h2>Limitation of Liability</h2>
          <p>You expressly understand and agree that MASTRO will not be liable for any indirect, incidental, special, consequential, exemplary damages, or damages for loss of profits including but not limited to, damages for loss of goodwill, use, data or other intangible losses (even if MASTRO has been advised of the possibility of such damages), whether based on contract, tort, negligence, strict liability or otherwise, resulting from: (I) the use or the inability to use the Services or any related information; (ii) unauthorized access to or alteration of your transmissions or data; (iii) statements or conduct of any third party (including users) on the Services; or (iv) any other matter relating to the Services. In no event will MASTRO's total liability to you for all damages, losses or causes of action exceed ten dollars ($10).</p>
          <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations set forth above may not apply to you. If you are dissatisfied with any portion of the site or with these terms of use, your sole and exclusive remedy is to discontinue use of the Services.</p>

          <h2>Dispute Resolution By Binding Arbitration</h2>
          <p>This section affects your rights so please read it carefully.</p>

          <h3>Agreement to Arbitrate</h3>
          <p>This Dispute Resolution by Binding Arbitration section of the Terms is referred to in these Terms as the "Arbitration Agreement." You agree that any and all disputes or claims that have arisen or may arise between you and MASTRO, whether arising out of or relating to these Terms (including any alleged breach thereof), the Website or Services, any aspect of the relationship or transactions between us, shall be resolved exclusively through final and binding arbitration, rather than a court, in accordance with the terms of this Arbitration Agreement, except that you may assert individual claims in small claims court, if your claims qualify. Further, this Arbitration Agreement does not preclude you from bringing issues to the attention of the Korean government, or local agencies, and such agencies can, if the law allows, seek relief against us on your behalf. You agree that, by entering into these Terms, you and MASTRO are each waiving the right to a trial by jury or to participate in a class action. Your rights will be determined by a neutral arbitrator, not a judge or jury. Relevant Korean Law governs the interpretation and enforcement of this Arbitration Agreement.</p>

          <h3>Prohibition of Class and Representative Actions and Non-Individualized Relief</h3>
          <p>You and MASTRO agree that each of us may bring claims against the other only on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding. Unless both You and MASTRO agree otherwise, the arbitrator may not consolidate or join more than one person's or party's claims and may not otherwise preside over any form of a consolidated, representative, or class proceeding. Also, the arbitrator may award relief (including monetary, injunctive, and declaratory relief) only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that party's individual claim(s).</p>

          <h3>Pre-Arbitration Dispute Resolution</h3>
          <p>MASTRO is always interested in resolving disputes amicably and fairly, and so if you have concerns, we strongly encourage you to first email us about them at info@1zlabs.com. If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute ("Notice"). The Notice to MASTRO should be sent to SUPERKEN LLC 800 N KING ST STE 304 1074 WILMINGTON, DE 19801("Notice Address"). The Notice must (i) describe the nature and basis of the claim or dispute and (ii) set forth the specific relief sought. If MASTRO and you do not resolve the claim within sixty (60) calendar days after the Notice is received, you or MASTRO may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by MASTRO or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or MASTRO is entitled.</p>

          <h3>Arbitration Procedures</h3>
          <p>Arbitration will be conducted by a neutral arbitrator. The arbitrator must follow the provisions of these Terms as a court would. All issues are for the arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and arbitrability of this Arbitration Agreement. Although arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings, the arbitrator can award the same damages and relief on an individual basis that a court can award to an individual under the Terms and applicable law. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons.</p>

          <p>Unless MASTRO and you agree otherwise, any arbitration hearings will take place in Seoul, Republic of Korea. If your claim is for $10,000 or less, MASTRO can agree that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic or video conference hearing, or by an in-person hearing. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the award is based.</p>

          <h3>Confidentiality</h3>
          <p>All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be strictly confidential for the benefit of all parties.</p>

          <h3>Severability</h3>
          <p>If a court or the arbitrator decides that any term or provision of this Arbitration Agreement (other than the subsection (b) titled "Prohibition of Class and Representative Actions and Non-Individualized Relief" above) is invalid or unenforceable, the parties agree to replace such term or provision with a term or provision that is valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable term or provision, and this Arbitration Agreement shall be enforceable as so modified. If a court or the arbitrator decides that any of the provisions of subsection (b) above titled "Prohibition of Class and Representative Actions and Non-Individualized Relief" are invalid or unenforceable, then the entirety of this Arbitration Agreement shall be null and void. The remainder of the Terms will continue to apply.</p>

          <h3>Future Changes to Arbitration Agreement</h3>
          <p>Notwithstanding any provision in this Terms of Use to the contrary, MASTRO agrees that if it makes any future change to this Arbitration Agreement (other than a change to the Notice Address) while you are a user of the Services, you may reject any such change by sending MASTRO written notice within thirty (30) calendar days of the change to the Notice Address provided above. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this Arbitration Agreement as of the date you first accepted these Terms (or accepted any subsequent changes to these Terms).</p>

          <h2>9. Termination</h2>
          <p>You agree that MASTRO, in its sole discretion, may suspend or terminate your account (or any part thereof) or use of the Services and remove and discard any content within the Website or Services, for any reason, including, without limitation, for lack of use or if MASTRO believes that you have violated or acted inconsistently with the letter or spirit of these Terms or MASTRO's Acceptable Use Policy. MASTRO may also in its sole discretion and at any time discontinue providing the Services, or any part thereof, with or without notice. You agree that any termination of your access to the Services under any provision of these Terms or our AUP may be effected without prior notice, and acknowledge and agree that MASTRO may (but has no obligation to) immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Services. Termination of your account or access to any component of the Services will not terminate MASTRO's rights to your User Content. Further, you agree that MASTRO will not be liable to you or any third party for any termination of your access to the Services.</p>

          <h2>10. General</h2>
          <h3>Entire Agreement</h3>
          <p>These Terms constitute the entire agreement between you and MASTRO and govern your use of our Services, superseding any prior agreements between you and MASTRO with respect to the Services.</p>

          <h3>Choice of Law, Jurisdiction, Venue</h3>
          <p>These Terms are governed by the laws of the Republic of Korea without regard to its conflict of law provisions. With respect to any disputes or claims not subject to arbitration, as set forth above, you and MASTRO agree to submit to the personal and exclusive jurisdiction of the Seoul Central District Court Seoul, Republic of Korea.</p>

          <h3>Severance</h3>
          <p>If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.</p>

          <h3>No Waiver</h3>
          <p>Any failure of MASTRO to exercise or enforce any right or provision of these Terms does not constitute a waiver of such right or provision.</p>

          <h3>Expiration of Claims</h3>
          <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Site or these Terms of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>

          <h3>Admissibility</h3>
          <p>A printed version of this agreement and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>

          <h3>Assignment</h3>
          <p>You may not assign these Terms without the prior written consent of MASTRO, but MASTRO may assign or transfer these Terms of Use, in whole or in part, without restriction.</p>

          <h3>Section Headings</h3>
          <p>The section titles in these Terms of Use are for convenience only and have no legal or contractual effect.</p>

          <h3>Notice</h3>
          <p>Notices to you may be made via either email or regular mail. The Site may also provide notices to you of changes to these Terms or other matters by displaying notices or links to notices generally on the Website.</p>

          <h2>11. Changes to these Terms</h2>
          <p>We reserve the right, at our sole discretion, to change or modify portions of these Terms at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date these terms were last revised. Any such changes will become effective no earlier than fourteen (14) days after they are posted, except that changes addressing new functions of the Site or changes made for legal reasons will be effective immediately. Your continued use of the Site after the date any such changes become effective constitutes your acceptance of the new Terms.</p>

          <h2>12. Contact Us</h2>
          <p>If you have any questions about our Services, or to report any violations of these Terms or our Acceptable Use Policies, please contact us at info@1zlabs.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  position: relative;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #f0f0f0;
}

.modal-header h3 {
  margin: 0;
}

.terms-content {
  text-align: left;
}

.terms-content h1 {
  margin: 20px 0 10px 0;
}

.terms-content h2 {
  margin: 20px 0 10px 0;
}

.terms-content h3 {
  margin: 20px 0 10px 0;
}

.terms-content p {
  margin: 20px 0 10px 0;
}


.close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .modal {
    width: 95%;
    max-height: 90vh;
  }
}
</style>