<template>
  <div class="consent-form">
    <TextInput
        ref="nameInput"
        :input-item="inputItem"
        v-model="name"
        :max-length="10"
        @update:modelValue="updateName"
    />
    <div class="text3 gray8" style="margin-bottom: 20px; transform: translateY(10px)">
      {{ name.length }}/10
    </div>
  </div>
</template>

<script>
import TextInput from "@/tarot_box/pages/box_input_v2/components/Inputs/TextInput.vue";

export default {
  name: "NameRegistration",
  components: {TextInput},
  data() {
    return {
      name: '',
      inputItem: {
        inputFormType: 'name',
        inputFormSubtype: 'name',
        options: {
          placeholder: '10글자 한글,영문,숫자만 입력 가능'
        }
      }
    }
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    updateName(newName) {
      this.name = newName;
      this.validateAndEmit();
    },
    validateAndEmit() {
      const isValid = this.name.trim().length > 0;
      this.$emit('name-validated', isValid);
      this.$emit('update:name', this.name);
    },
    submitName() {
      this.validateAndEmit();
      this.$emit('submit-name', this.name);
    },
    focusInput() {
      this.$refs.nameInput.$refs.input.focus();
    }
  },
  emits: ['update:name', 'name-validated', 'submit-name']
}
</script>

<style scoped>
.consent-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.consent-form input {
  width: 100% !important;
}
</style>