<template>
  <div class="check-circle" :class="{ checked: modelValue }" @click="handleClick">
    <svg v-if="modelValue" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M6.66647 10.1139L12.7947 3.98566L13.7375 4.92846L6.66647 11.9995L2.42383 7.75691L3.36664 6.81411L6.66647 10.1139Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CheckCircle",
  props: {
    modelValue: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('update:modelValue', !this.modelValue);
    }
  }
}
</script>

<style scoped>
.check-circle {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  border-radius: 10px;
  border: 1px solid #999999;
}

.checked {
  background-color: #1E2352;
}
</style>