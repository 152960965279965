<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8333 5.125L7.125 13.8333L3.16667 9.875" :stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
  props: {
    currentColor: {
      type: String,
      default: "var(--Grayscale-Gray4)"
    }
  }
}
</script>

<style scoped>
svg path{
  transition: all 0.3s ease;
}
</style>