<template>
  <div class="gender_birth_grid">
    <div class="grid_cell">
      <div class="title">
        <h3>성별</h3>
      </div>
      <div class="gender_grid">
        <div class="gender_button" v-for="gender in genders" :key="gender.type" :class="{selected: selectedGender === gender.type}" @click="selectGender(gender.type)">
          <div class="gender_type">{{gender.type}}</div>
        </div>
      </div>
    </div>
    <div class="grid_cell">
      <div class="title">
        <h3>태어난 날</h3>
        <span class="caution"> * 양력으로 알려주세요</span>
      </div>
      <div class="birth_day_input_container">
        <div class="day_picker_placeholder" v-show="!selectedDateText">생년월일을 선택해 주세요</div>
        <flat-pickr
            v-model="selectedDateText"
            @on-change="onDateChange"
            :config="flatPickrDateConfig"
            class="date_picker"
        />
      </div>
    </div>
    <div class="grid_cell2">
      <div class="title">
        <h3>태어난 시간</h3>
        <div class="check_circle_item">
          <CheckCircle v-model="noTime"/>
          <span class="check_circle_text">태어난 시간 모름</span>
        </div>
      </div>
      <div>
        <div class="birth_day_input_container">
          <div class="day_picker_placeholder" v-show="!timeText">{{ this.noTime ? '시간 모름 선택 : 00:00시로 해석' : '태어난 시간을 선택해 주세요' }}</div>
          <input
              type="text"
              class="date_picker"
              :value="timeText"
              :disabled="noTime"
              @click="showTimeSelectorPopup = !showTimeSelectorPopup"
              readonly
          />
          <TimeSelectorPopup :show="showTimeSelectorPopup" @change="onTimeChange"/>
        </div>
      </div>
    </div>
    <!--    <div class="grid_cell2">-->
    <!--      <div class="title">-->
    <!--        <h3>태어난 도시</h3>-->
    <!--        <div class="check_circle_item">-->
    <!--          <CheckCircle v-model="noCity"/>-->
    <!--          <span class="check_circle_text">태어난 도시 모름</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <CitySelector v-model="selectorCity" :disabled="noCity" disabled-text="도시 모름 선택 : 서울시로 해석"/>-->
    <!--      <div v-show="adjustMinutesByLongitude" class="info_text_container">-->
    <!--        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">-->
    <!--          <path d="M9 16C5.134 16 2 12.866 2 9C2 5.134 5.134 2 9 2C12.866 2 16 5.134 16 9C16 12.866 12.866 16 9 16ZM9 14.6C12.0928 14.6 14.6 12.0928 14.6 9C14.6 5.9072 12.0928 3.4 9 3.4C5.9072 3.4 3.4 5.9072 3.4 9C3.4 12.0928 5.9072 14.6 9 14.6ZM9.7 9H12.5V10.4H8.3V5.5H9.7V9Z" fill="#999999"/>-->
    <!--        </svg>-->
    <!--        <span class="info_text">{{ `태어난 시간에서 ${adjustMinutesByLongitude}분 보정됩니다.` }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import '@/tarot_box/pages/box_input/css/custom_flatpickr.css';
import "flatpickr/dist/l10n/ko.js"
import dayjs from "dayjs";
import CheckCircle from "@/tarot_box/pages/box_input/components/input_modules/CheckCircle.vue";
import TimeSelectorPopup from "@/tarot_box/pages/box_input/components/input_modules/TimeSelectorPopup.vue";

export default {
  name: "GenderBirthGridWithCity",
  created() {
    if(this.initialInputData) {
      const dateString = this.initialInputData.split('/')[0];
      this.selectedDate = dayjs(dateString).tz('Asia/Seoul')
      this.flatPickrDateConfig.defaultDate = new Date(dateString);
      this.selectedTimeIndex = this.initialInputData.split('/')[1];
      this.selectedGender = this.initialInputData.split('/')[2];
    }
  },
  components: {
    TimeSelectorPopup,
    CheckCircle,
    flatPickr,
  },
  props: {
    initialInputData: String,
  },
  emits: ['onInputDataChange'],
  computed: {
    timeText: {
      get() {
        return this.selectedTimeText;
      },
      set(value) {
        this.selectedTimeText = value;
        this.combineData();
      },
    },
    // selectorCity: {
    //   get() {
    //     return this.selectedCity;
    //   },
    //   set(value) {
    //     this.selectedCity = value;
    //     (async () => {
    //       const response = await getCityCoord(this.selectedCity.placeId)
    //       this.selectedCityLongitude = response.data.location.longitude;
    //       this.selectedCityCountryCode = response.data.country;
    //       this.adjustMinutesByLongitude = calculateTimeDifferenceFromLongitudeInMinute(this.selectedCityLongitude);
    //       this.combineData();
    //     })();
    //   },
    // },
    noTime: {
      get() {
        return this.timeUndefined
      },
      set(value) {
        this.timeUndefined = value;
        this.selectedTimeText = '';
        this.combineData();
      },
    },
    noCity: {
      get() {
        return this.cityUndefined
      },
      set(value) {
        this.cityUndefined = value;
        this.selectedCity = null;
        this.selectedCityLongitude = null;
        this.adjustMinutesByLongitude = null;
        this.combineData();
      },
    },
  },
  data() {
    return {
      genders: [
        {type: '남성'},
        {type: '여성'},
      ],
      flatPickrDateConfig: {
        locale: 'ko',
        enableTime: false,
        dateFormat: "Y년 n월 j일",
        minDate: "1910-01-01",
        maxDate: "2040-12-31",
        disableMobile: "true"
      },
      flatPickrTimeConfig: {
        locale: 'ko',
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        disableMobile: "true",
      },
      selectedDate: null,
      selectedDateText: '',
      selectedTimeText: '',
      selectedTimeIndex: null,
      selectedGender: '',
      selectedCity: null,
      selectedCityLongitude: null,
      selectedCityCountryCode: null,
      adjustMinutesByLongitude: 0,
      timeUndefined: false,
      cityUndefined: false,
      showTimeSelectorPopup: false,
    }
  },
  methods: {
    selectGender(genderType) {
      this.selectedGender = genderType;
      this.combineData();
    },
    onDateChange(date) {
      this.selectedDate = dayjs(date).tz('Asia/Seoul');
      this.combineData();
    },
    onTimeChange(time) {
      this.selectedTimeText = time;
      this.showTimeSelectorPopup = false;
      this.combineData();
    },
    combineData() {
      if (!this.selectedGender ||
          !this.selectedDate ||
          !this.selectedTimeText && !this.noTime
          // !this.adjustMinutesByLongitude && !this.noCity
      ) {
        this.$emit('onInputDataChange', '');
        return;
      }

      // if (this.noCity) {
      //   this.adjustMinutesByLongitude = calculateTimeDifferenceFromLongitudeInMinute(127.06);
      // }
      const [calculatedDate, calculatedTimeIndex] = this.calculateAdjustedDateAndTimeIndex();
      const calculatedDateString = calculatedDate.format('YYYY-MM-DD');
      const data = `${calculatedDateString}/${calculatedTimeIndex}/${this.selectedGender}`;

      console.info('text:', data);
      if (calculatedDateString) {
        this.$emit('onInputDataChange', data);
      }
    },
    calculateAdjustedDateAndTimeIndex() {
      // const calculatedTimeInMinutes = this.getTimeInMinutes() + this.adjustMinutesByLongitude;
      const calculatedTimeInMinutes = this.getTimeInMinutes();
      const calculatedDate = this.selectedDate.add(calculatedTimeInMinutes, 'minute');
      const minutesOfDate = Math.floor(calculatedDate.unix() / 60) - Math.floor(this.selectedDate.unix() / 60);
      const calculatedTimeIndex = Math.floor(((minutesOfDate + 1440 + 60) % 1440) / 120);
      return [calculatedDate, calculatedTimeIndex];
    },
    getTimeInMinutes() {
      if (this.noTime) return 0;
      const times = this.selectedTimeText.split(':');
      return parseInt(times[0]) * 60 + parseInt(times[1]);
    },
  }
}
</script>

<style>
input[type="text"]:disabled.date_picker {
  background-color: #d9d9d9;
  color: #999999;
}
</style>

<style scoped>

.invisible {
  visibility: hidden;
}

.day_picker_placeholder {
  position: absolute;
  width: max-content;
  max-width: calc(100vw - 40px - 20px - 60px);
  margin-left: 20px;
  display: flex;
  align-items: center;
  pointer-events: none;

  color: #999999;
  font-family: 'SUIT Variable',sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.grid_cell {
  margin-bottom: 32px;
}

.grid_cell2 {
  margin-bottom: 8px;
}

.gender_grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 8px;
  align-items: center;
}

.gender_button {
  display: flex;
  flex-flow: column;
  justify-content: center;

  width: calc(50vw - 20px - 6px);
  height: 39px;
  flex-shrink: 0;

  border-radius: 90px;
  border: 1px solid #b2b2b2;
  background: #F5F5F5;
}

.gender_type {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-family: 'SUIT Variable', sans-serif;
  font-weight: 700;
}

.selected {
  border-radius: 90px;
  border: 1px solid #1E2352;
  background: #DDDFF8;
}

.gender_birth_grid {
  max-width: 100vw;
  max-height: calc(100vh - 90px - 78px); /* header footer height*/

  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

.title {
  font-family: 'SUIT Variable', sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

.check_circle_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.check_circle_text {
  margin-left: 6px;
  font-family: 'SUIT Variable', sans-serif;
  font-size: 14px;
  color: #555555;
}

h3 {
  font-family: 'SUIT Variable', sans-serif;
  font-size: 14px;
  text-align: left;
  font-weight: 700;
}

.title h3 {
  font-family: 'SUIT Variable', sans-serif;
  font-size: 14px;
}

.title .caution {
  font-family: 'SUIT Variable', sans-serif;
  font-size: 14px;
  margin-left: 10px;
  color: #FF8181;
}

img {
  width: 24px;
  height: 24px;
  margin-left: 60px;
  margin-top: 10px;
}

.birth_day_input_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: calc(100vw - 40px);
  height: 40px;
}

.date_picker_button button {
  border: none;
  background: transparent;
}

.date_picker {
  max-width: calc(100vw - 40px);
  height: 38px;
  padding: 0 60px 0 20px;

  font-family: 'SUIT Variable',sans-serif;
  font-size: 14px;

  border-radius: 90px;
  outline: none;
  box-shadow: none;
  border: 1px solid #b2b2b2;
  color: #1E2352;
  font-weight: 700;
  text-align: left;

  background-size: 20px;
  background: #F5F5F5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z' fill='%23999999'/%3E%3C/svg%3E") no-repeat right 20px center;
}

.info_text_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
}

.info_text {
  font-family: 'SUIT Variable', sans-serif;
  color: #777777;
  margin-left: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.disabled {
  background-color: #d9d9d9;
  color: #999999;
}
</style>
