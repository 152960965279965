<template>
  <div class="birth_grid">
    <div class="birth_day">
      <div class="title">
        <h3>태어난 날</h3>
        <span>* 양력으로 알려주세요</span>
      </div>
      <div class="birth_day_input_container">
        <div class="day_picker_placeholder" v-show="!selectedDate">생년월일을 선택해 주세요</div>
        <flat-pickr v-model="this.selectedDateText" :config="flatPickrConfig" class="date_picker"/>
      </div>
    </div>
    <div class="birth_time">
      <h3>태어난 시간</h3>
      <select class="birth_time_input_container"
              :style="selectedTimeIndex !== null ? '' : {color: '#7F7F7F', fontWeight: 400}"
              @change="combineTimeData(selectedTime)" v-model="selectedTime" ref="selectTime">
        <option :value="null" disabled selected >
          태어난 시간을 선택해 주세요
        </option>
        <option v-for="(item, index) in selectList" :key="index" :value="item.value">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import '@/tarot_box/pages/box_input/css/custom_flatpickr.css';
import "flatpickr/dist/l10n/ko.js"
export default {
  name: "BirthGrid",
  created() {
    if(this.initialInputData) {
      this.selectedDate = this.initialInputData.split('/')[0];
      this.flatPickrConfig.defaultDate = new Date(this.selectedDate);

      this.selectedTime = this.initialInputData.split('/')[1];
      this.selectedTimeIndex = this.initialInputData.split('/')[1];
    }
  },
  components: {
    flatPickr
  },
  props: {
    initialInputData: String,
  },
  emits: ['onInputDataChange'],
  data() {
    return {
      flatPickrConfig: {
        locale: 'ko',
        enableTime: false,
        dateFormat: "Y년 n월 j일",
        minDate: "1910-01-01",
        maxDate: "2040-12-31",
        defaultDate: new Date(2000, 0, 1),
        disableMobile: "true"
      },
      selectedTime: null,
      selectedTimeIndex: null,
      selectedDateText: '',
      selectedDate: '',
      selectList: [
        {name: "몰라요", value: 13},
        {name: "조자시(子時) 00:00~01:30", value: 0},
        {name: "축시(丑時) 01:31~03:30", value: 1},
        {name: "인시(寅時) 03:31~05:30", value: 2},
        {name: "묘시(卯時) 05:31~07:30", value: 3},
        {name: "진시(辰時) 07:31~09:30", value: 4},
        {name: "사시(巳時) 09:31~11:30", value: 5},
        {name: "오시(午時)  11:31~13:30", value: 6},
        {name: "미시(未時) 13:31~15:30", value: 7},
        {name: "신시(申時) 15:31~17:30", value: 8},
        {name: "유시(酉時) 17:31~19:30", value: 9},
        {name: "술시(戌時) 19:31~21:30", value: 10},
        {name: "해시(亥時) 21:31~23:30", value: 11},
        {name: "야자시(子時) 23:31~23:59", value: 12}
      ],
      selected: this.initialInputData || '',
    }
  },
  watch: {
    selectedDateText(newVal){
      this.selectedDate = this.formatDate(newVal);
      this.combineDayData();
    }
  },
  methods: {
    combineDayData() {
      this.selected = `${this.selectedDate}/${this.selectedTimeIndex}`;

      if(this.initialInputData) {
        this.$emit('onInputDataChange', this.selected);
      } else if(this.selectedDate && this.selectedTimeIndex!==null){
        this.$emit('onInputDataChange', this.selected);
      }
    },
    combineTimeData(time){
      this.selectedTimeIndex=time;
      this.selected = `${this.selectedDate}/${this.selectedTimeIndex}`;
      if(this.initialInputData) {
        this.$emit('onInputDataChange', this.selected);
      } else if(this.selectedDate && this.selectedTimeIndex!==null){
        this.$emit('onInputDataChange', this.selected);
      }
    },
    formatDate(dateStr){
      const year = dateStr.split('년')[0];
      const month = dateStr.split('년 ')[1].split('월')[0].padStart(2, '0');
      const day = dateStr.split('월 ')[1].split('일')[0].padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }
}
</script>

<style scoped>
.day_picker_placeholder{
  position: absolute;
  width: max-content;
  max-width: calc(100vw - 40px - 20px - 60px);
  margin-left: 20px;
  display: flex;
  align-items: center;
  pointer-events: none;

  background-color: #F5F5F5;
  color: #7F7F7F;
  font-family: 'Roboto',sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.birth_grid{
  max-width: 100vw;
  max-height: calc(var(--vh, 1vh) * 100 - 90px - 78px); /* header footer height*/

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.title{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

h3{
  margin-bottom: 10px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.title h3 {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  margin-bottom: 0;
}

.title span {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #FF8181;
}

img {
  width: 24px;
  height: 24px;
  margin-left: 60px;
  margin-top: 10px;
}

.birth_day{
  margin-bottom: 20px;
}

.birth_time_input_container {
  width: 320px;
  max-width: calc(100vw - 40px);
  height: 40px;

  border-radius: 90px;
  border: 1px solid #C8C8C8;
  padding: 5px 60px 5px 20px;

  font-family: 'Roboto',sans-serif;
  font-size: 16px;
  color: #1E2352;
  font-weight: 600;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 20px;
  background: #F5F5F5 url('../../../box_result/assets/images/output_images/select_button.png') no-repeat right 20px center;

}

.birth_day_input_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  max-width: calc(100vw - 40px);
  height: 40px;
}

.date_picker_button button {
  border: none;
  background: transparent;
}

.date_picker {
  width: 320px;
  max-width: calc(100vw - 40px);
  height: 38px;
  padding: 0 60px 0 20px;

  font-family: 'Roboto',sans-serif;
  font-size: 16px;

  border-radius: 90px;
  outline: none;
  box-shadow: none;
  border: 1px solid #C8C8C8;
  color: #1E2352;
  font-weight: 600;
  text-align: left;

  background-size: 20px;
  background: #F5F5F5 url('../../../box_result/assets/images/output_images/select_button.png') no-repeat right 20px center;
}
</style>
