<template>
  <PageHeaderBar title="리뷰 작성" @go-back="handleBackButtonClick()"/>
  <WriteReviewPopup :box-data="boxData" v-if="popupActive" @closePopup="closePopup()" @processCheckout="processCheckout()"/>

  <div class="content" v-safe-area-top>
    <div class="section">
      <div class="header title3" style="justify-content: space-between">
        적립 예상 클로버
        <div class="clover-icon-text">
          {{ boxData.reactionReward + boxData.reviewReward }}
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.96118 9.00001C8.9743 8.98559 8.98735 8.9711 9.00032 8.95653C9.01328 8.9711 9.02633 8.98559 9.03945 9C9.02633 9.01442 9.01328 9.02891 9.00032 9.04348C8.98735 9.02891 8.9743 9.01442 8.96118 9.00001ZM9.00032 16.2693C8.05308 17.3332 6.69312 18 5.18213 18C2.32012 18 0 15.6076 0 12.6564C0 11.242 0.532948 9.95587 1.40307 9.00001C0.532948 8.04415 0 6.75806 0 5.34361C0 2.39242 2.32012 0 5.18213 0C6.69312 0 8.05308 0.666842 9.00032 1.73069C9.94755 0.666842 11.3075 0 12.8185 0C15.6805 0 18.0006 2.39242 18.0006 5.34361C18.0006 6.75806 17.4677 8.04415 16.5976 9.00001C17.4677 9.95587 18.0006 11.242 18.0006 12.6564C18.0006 15.6076 15.6805 18 12.8185 18C11.3075 18 9.94755 17.3332 9.00032 16.2693Z" fill="#4BD1A9"/>
            <path d="M9.41823 13.1877C8.8566 13.1877 8.33096 13.0905 7.84134 12.8961C7.35171 12.6945 6.92328 12.4173 6.55606 12.0645C6.19604 11.7117 5.91162 11.3012 5.70281 10.8332C5.5012 10.358 5.40039 9.84675 5.40039 9.29951C5.40039 8.74508 5.5012 8.23385 5.70281 7.76582C5.91162 7.29059 6.19964 6.87657 6.56686 6.52375C6.93408 6.17093 7.35891 5.89731 7.84134 5.7029C8.33096 5.50129 8.8566 5.40048 9.41823 5.40048C9.83585 5.40048 10.2391 5.45809 10.6279 5.57329C11.0167 5.6885 11.3767 5.85051 11.708 6.05932C12.0464 6.26813 12.3344 6.52015 12.572 6.81537L11.5136 7.86303C11.2399 7.52461 10.9231 7.26899 10.5631 7.09618C10.2103 6.92337 9.82865 6.83697 9.41823 6.83697C9.07981 6.83697 8.76299 6.90177 8.46777 7.03138C8.17255 7.15379 7.91694 7.3266 7.70093 7.54981C7.48491 7.76582 7.3157 8.02504 7.1933 8.32746C7.07089 8.62267 7.00969 8.94669 7.00969 9.29951C7.00969 9.64513 7.07089 9.96915 7.1933 10.2716C7.3157 10.5668 7.48851 10.826 7.71173 11.0492C7.93494 11.2652 8.19416 11.4344 8.48937 11.5568C8.79179 11.6793 9.11941 11.7405 9.47223 11.7405C9.86826 11.7405 10.2391 11.6577 10.5847 11.492C10.9303 11.3264 11.2363 11.0888 11.5028 10.7792L12.5288 11.7945C12.2912 12.0825 12.0068 12.3309 11.6756 12.5397C11.3443 12.7485 10.9843 12.9105 10.5955 13.0257C10.2139 13.1337 9.82145 13.1877 9.41823 13.1877Z" fill="#121212"/>
          </svg>
        </div>
      </div>

      <BoxInfoItem :item="boxData"/>
    </div>

    <div class="section">
      <div class="header title3" ref="headerDiv">
        어떠셨어요?
        <div class="title5 clover-icon-text">
          작성하면 + {{boxData.reactionReward}}
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96981 7L7.00022 6.96622C7.0103 6.97754 7.02044 6.9888 7.03064 7C7.02044 7.0112 7.0103 7.02247 7.00022 7.03378C6.99014 7.02247 6.98001 7.0112 6.96981 7ZM7.00023 12.6539C6.26349 13.4814 5.20575 14 4.03054 14C1.80454 14 0 12.1392 0 9.84386C0 8.74374 0.414514 7.74345 1.09128 7C0.414514 6.25656 0 5.25627 0 4.15614C0 1.86077 1.80454 0 4.03054 0C5.20575 0 6.26348 0.518644 7.00022 1.34607C7.73696 0.518644 8.79469 0 9.9699 0C12.1959 0 14.0004 1.86077 14.0004 4.15614C14.0004 5.25627 13.5859 6.25656 12.9092 7C13.5859 7.74345 14.0004 8.74374 14.0004 9.84386C14.0004 12.1392 12.1959 14 9.9699 14C8.79469 14 7.73696 13.4814 7.00023 12.6539Z" fill="#4BD1A9"/>
            <path d="M7.32518 10.2571C6.88835 10.2571 6.47953 10.1815 6.09871 10.0303C5.71789 9.87351 5.38467 9.6579 5.09905 9.38349C4.81903 9.10907 4.59782 8.78985 4.43541 8.42583C4.2786 8.05621 4.2002 7.65858 4.2002 7.23296C4.2002 6.80173 4.2786 6.40411 4.43541 6.04009C4.59782 5.67047 4.82183 5.34845 5.10745 5.07403C5.39307 4.79961 5.72349 4.5868 6.09871 4.43559C6.47953 4.27878 6.88835 4.20038 7.32518 4.20038C7.65 4.20038 7.96362 4.24518 8.26604 4.33479C8.56845 4.42439 8.84847 4.5504 9.10609 4.71281C9.3693 4.87522 9.59331 5.07123 9.77813 5.30084L8.95488 6.11569C8.74206 5.85248 8.49565 5.65366 8.21563 5.51926C7.94122 5.38485 7.6444 5.31764 7.32518 5.31764C7.06196 5.31764 6.81555 5.36805 6.58594 5.46885C6.35632 5.56406 6.15751 5.69847 5.9895 5.87208C5.82149 6.04009 5.68988 6.2417 5.59468 6.47691C5.49947 6.70653 5.45187 6.95854 5.45187 7.23296C5.45187 7.50177 5.49947 7.75379 5.59468 7.989C5.68988 8.21862 5.82429 8.42023 5.9979 8.59384C6.17151 8.76185 6.37312 8.89346 6.60274 8.98866C6.83795 9.08387 7.09277 9.13147 7.36718 9.13147C7.6752 9.13147 7.96362 9.06707 8.23243 8.93826C8.50125 8.80945 8.73926 8.62464 8.94648 8.38383L9.74452 9.17347C9.55971 9.39749 9.3385 9.5907 9.08088 9.75311C8.82327 9.91552 8.54325 10.0415 8.24083 10.1311C7.94402 10.2151 7.6388 10.2571 7.32518 10.2571Z" fill="#121212"/>
          </svg>
        </div>
      </div>

      <div class="review-select-options-wrapper">
        <div v-for="item in reviewSelectOptions" :key="item.id"
             class="review-select-option text2"
             :class="{ 'selected': item.id === selectedReviewOptionId }"
             @click="selectReviewOption(item)">

          {{ item.text }}
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header title3">
        자세한 리뷰
        <div class="title5 clover-icon-text">
          작성하면 + {{boxData.reviewReward}}
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96981 7L7.00022 6.96622C7.0103 6.97754 7.02044 6.9888 7.03064 7C7.02044 7.0112 7.0103 7.02247 7.00022 7.03378C6.99014 7.02247 6.98001 7.0112 6.96981 7ZM7.00023 12.6539C6.26349 13.4814 5.20575 14 4.03054 14C1.80454 14 0 12.1392 0 9.84386C0 8.74374 0.414514 7.74345 1.09128 7C0.414514 6.25656 0 5.25627 0 4.15614C0 1.86077 1.80454 0 4.03054 0C5.20575 0 6.26348 0.518644 7.00022 1.34607C7.73696 0.518644 8.79469 0 9.9699 0C12.1959 0 14.0004 1.86077 14.0004 4.15614C14.0004 5.25627 13.5859 6.25656 12.9092 7C13.5859 7.74345 14.0004 8.74374 14.0004 9.84386C14.0004 12.1392 12.1959 14 9.9699 14C8.79469 14 7.73696 13.4814 7.00023 12.6539Z" fill="#4BD1A9"/>
            <path d="M7.32518 10.2571C6.88835 10.2571 6.47953 10.1815 6.09871 10.0303C5.71789 9.87351 5.38467 9.6579 5.09905 9.38349C4.81903 9.10907 4.59782 8.78985 4.43541 8.42583C4.2786 8.05621 4.2002 7.65858 4.2002 7.23296C4.2002 6.80173 4.2786 6.40411 4.43541 6.04009C4.59782 5.67047 4.82183 5.34845 5.10745 5.07403C5.39307 4.79961 5.72349 4.5868 6.09871 4.43559C6.47953 4.27878 6.88835 4.20038 7.32518 4.20038C7.65 4.20038 7.96362 4.24518 8.26604 4.33479C8.56845 4.42439 8.84847 4.5504 9.10609 4.71281C9.3693 4.87522 9.59331 5.07123 9.77813 5.30084L8.95488 6.11569C8.74206 5.85248 8.49565 5.65366 8.21563 5.51926C7.94122 5.38485 7.6444 5.31764 7.32518 5.31764C7.06196 5.31764 6.81555 5.36805 6.58594 5.46885C6.35632 5.56406 6.15751 5.69847 5.9895 5.87208C5.82149 6.04009 5.68988 6.2417 5.59468 6.47691C5.49947 6.70653 5.45187 6.95854 5.45187 7.23296C5.45187 7.50177 5.49947 7.75379 5.59468 7.989C5.68988 8.21862 5.82429 8.42023 5.9979 8.59384C6.17151 8.76185 6.37312 8.89346 6.60274 8.98866C6.83795 9.08387 7.09277 9.13147 7.36718 9.13147C7.6752 9.13147 7.96362 9.06707 8.23243 8.93826C8.50125 8.80945 8.73926 8.62464 8.94648 8.38383L9.74452 9.17347C9.55971 9.39749 9.3385 9.5907 9.08088 9.75311C8.82327 9.91552 8.54325 10.0415 8.24083 10.1311C7.94402 10.2151 7.6388 10.2571 7.32518 10.2571Z" fill="#121212"/>
          </svg>
        </div>
      </div>
      <div class="input-field">
        <textarea v-model="reviewContent"
                  placeholder="도움이 되셨다면 후기를 20자 이상 작성해주세요. 다른 분들에게도 도움이 될거에요."
                  rows="6"
                  maxlength="120"
                  :class="{ 'border-red': !isReviewLongEnough }"
                  @focus="handleTextareaFocus"
                  @blur="handleTextareaBlur"
                  @input="updateReviewContent"/>

        <div class="guide-text" v-if="isTextareaFocused">
          <span v-for="(text, index) in guideTexts"
                :key="index"
                class="text3"
                style="color: var(--Grayscale-Gray5)"
                :class="{ 'active': index === activeGuideTextIndex }">
            {{ text }}
          </span>
        </div>


      </div>
      <div class="text-limit" :style="{ color: isReviewLongEnough ? '' : 'var(--Color-palette-System-color-Red, #F66)' }">
        {{ reviewContent.length }} / 최소 20, 최대 120자
      </div>
    </div>

    <div class="section" style="margin-bottom: 150px;">
      <div class="guideline">
        <div class="guideline-title">
          리뷰 작성 유의사항
        </div>
        <div class="guideline-text">
          모든 리뷰는 운영 정책에 의해 검토 후 게시됩니다
        </div>
        <div class="toggle-button" ref="toggleButton" @click="toggleGuidelines">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 9.5L12 14.5L7 9.5" stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-bind:class="{ 'show': showGuidelines }" class="hidden-guidelines">
          <div class="guideline-text">
            <ol style="list-style-type: disc;">
              <li>리뷰 작성 적립금은 20분 이내로 지급됩니다.</li>
            </ol>
            <ol>
              <li>작성된 리뷰가 아래에 해당할 경우 노출 제한 될 수 있으며, 위반 내역의 심각성과 횟수에 따라 리뷰 작성 제한 및 적립금 회수 조치가 진행 될 수 있습니다.</li>
              <li>콘텐츠와 무관한 내용을 포함한 리뷰</li>
              <li>부적절하거나 타인에게 불쾌감을 줄 수 있는 리뷰 (비속어 또는 부적절한 표현, 특정 그룹에 대한 차별/혐오 표현, 성적적이거나 폭력적인 내용 또는 도일 문자/문구 반복이 포함된 리뷰 등)</li>
              <li>리뷰 서비스 목적에 어긋나는 리뷰
                <ul>
                  <li>광고성/홍보성 내용이 포함된 내용</li>
                  <li>허위/잘못된 정보/악의적인 비방이 포함된 내용,</li>
                  <li>어떠한 이유도 언급하지 않고 콘텐츠를 비방하는 내용</li>
                </ul>
              </li>
              <li>타 서비스/상품에 대한 내용 등</li>
              <li>구매가 환불된 콘텐츠에 대한 리뷰</li>
              <li>기타 관계 법령, 서비스 이용 약관에 위배되는 리뷰
                <ul>
                  <li>제한 조치에 대하여 이의가 있는 경우 고객센터를 통해 이의를 제기할 수 있습니다.</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

  </div>

  <button v-safe-area-bottom class="footer-button title2" @click="submitReview">
    작성 완료
  </button>

  <div class="toast" v-show="showToast" :class="{ 'show': showToast }">
    {{ toastMessage }}
  </div>


</template>

<script>
import PageHeaderBar from "@/tarot_box/pages/home/components/common/PageHeaderBar.vue";
import BoxInfoItem from "@/tarot_box/pages/home/components/write_review_page/BoxInfoItem.vue";
import {mapGetters} from "vuex";
import {submitBoxReview} from "@/tarot_box/helper/mytarot_api";
import {changeBottomSafeAreaColor, logEvent, showBottomBar} from "@/tarot_box/helper/native_api";
import WriteReviewPopup from "@/tarot_box/pages/box_result/WriteReviewPopup.vue";

export default {
  name: "WriteReviewPage",
  components: {BoxInfoItem, PageHeaderBar, WriteReviewPopup},
  computed: {
    ...mapGetters(['user','tarotBoxes']),
  },
  data() {
    return {
      reviewSelectOptions: [
        {id: 1, text: '😊 재밌어요'},
        {id: 2, text: '🙏 도움 돼요'},
        {id: 3, text: '👀 그냥 그래요'},
        {id: 4, text: '👎 별로네요'},
      ],
      guideTexts: [
        "고민에 도움이 되었나요?",
        "결과에 만족하셨나요?",
        "전문적인 해석이라고 느끼셨나요?",
        "결과지를 모두 보고난 후 기분은 어떠셨어요?",
        "누구에게 추천하고 싶으신가요?"
      ],
      activeGuideTextIndex: 0,
      selectedReviewOptionId: null,
      selectedReviewOption: null,
      isTextareaFocused: false,
      reviewContent: '',
      isReviewLongEnough: true,
      isReviewWritten: false,
      showGuidelines: true,
      showToast: false,
      toastMessage: '',
      boxData: {
        id: 1,
        title: '',
        subTitle: '',
        thumbnailImgUrl: '',
        reviewReward: 0,
        reactionReward: 0
      },
      popupActive: false,
      isButtonActive: true,
    };
  },
  mounted(){
    setInterval(() => {
      this.activeGuideTextIndex = (this.activeGuideTextIndex + 1) % this.guideTexts.length;
    }, 3000);
    window.onBackButtonClick = this.handleBackButtonClick;
    const boxId = this.$route.params.boxId;
    this.boxData = this.tarotBoxes.find(box => box.id === parseInt(boxId));
    console.log(boxId);
    console.log(this.boxData);
    if(boxId && this.boxData.title) {
      logEvent('review_into', {
        'tarot_box_id': boxId,
        'tarot_box_name': this.boxData.title,
      });
    } else {
      setTimeout(() => {
        const boxId = this.$route.params.boxId;
        this.boxData = this.tarotBoxes.find(box => box.id === parseInt(boxId));
        logEvent('review_into', {
          'tarot_box_id': boxId,
          'tarot_box_name': "store not loaded",
        });
      }, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.activeGuideTextIndex);
    window.onBackButtonClick = null;
  },
  watch: {
    reviewContent(newVal) {
      this.isReviewLongEnough = newVal.length >= 20 || newVal.length === 0;
      this.isReviewWritten = newVal.length > 0;
    },
  },
  methods: {
    selectReviewOption(item) {
      this.selectedReviewOption = item.text;
      this.selectedReviewOptionId = item.id;
      logEvent('review_rate_click', {
        'reaction': this.selectedReviewOption,
        'reaction_id': this.selectedReviewOptionId,
      });
    },
    toggleGuidelines() {
      this.showGuidelines = !this.showGuidelines;
      const toggleButton = this.$refs.toggleButton;
      if (toggleButton) {
        toggleButton.classList.toggle('rotated');
      }
    },
    handleTextareaFocus() {
      this.isTextareaFocused = true;
      this.showGuidelines = true;
      this.$nextTick(() => {
        this.$refs.headerDiv.scrollIntoView({ behavior: 'smooth' });
      });
    },
    handleTextareaBlur() {
      this.reviewContent = this.reviewContent.trim().substring(0, 120);
      this.isTextareaFocused = false;
    },
    updateReviewContent(event) {
      this.reviewContent = event.target.value;
      this.isReviewLongEnough = this.reviewContent.length >= 20 || this.reviewContent.length === 0;
      this.isReviewWritten = this.reviewContent.length > 0;
    },

    async processCheckout() {
      console.log('processCheckout')
      const historyId = this.$route.query.history_id;
      let selectedReaction;
      switch (this.selectedReviewOptionId) {
        case 1:
          selectedReaction = '재밌어요';
          break;
        case 2:
          selectedReaction = '도움 돼요';
          break;
        case 3:
          selectedReaction = '그냥 그래요';
          break;
        case 4:
          selectedReaction = '별로요';
          break;
      }
      let res = await submitBoxReview(this.user.phoneNumber, this.user.userId, this.$route.params.boxId, historyId, selectedReaction, this.reviewContent);
      if (res.result === 'success') {
        logEvent('review_completed', {
          'tarot_box_id': this.boxData.title,
          'tarot_box_name': this.boxData.title,
          'reaction': this.selectedReviewOption,
          'reaction_id': this.selectedReviewOptionId,
          'review': this.reviewContent,
          'allDone': this.isReviewWritten,
        });
        changeBottomSafeAreaColor('#FFFFFF');
        //get build_number params from url
        const urlParams = new URLSearchParams(window.location.search);
        const buildNumber = urlParams.get('build_number');
        const osType = urlParams.get('os_type');
        const insetTop = urlParams.get('inset_top')
        const insetBottom = urlParams.get('inset_bottom')
        this.$router.push(`/tarot_box/review_complete?tarot_box_id=${this.$route.params.boxId}&reward_clover=${res.data.reward_clover}&reaction_id=${this.selectedReviewOptionId}&build_number=${buildNumber}&os_type=${osType}&inset_top=${insetTop}&inset_bottom=${insetBottom}`);
      } else if(res.result === 'fail' && res.message === "이미 작성하셨거나 기간이 지난 리뷰입니다.") {
        this.toastMessage = res.message;
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
          this.toastMessage = '';
        }, 3000);
        setTimeout(() => {
          this.$router.go(-1);
        }, 3000);
      } else {
        this.toastMessage = res.message;
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
          this.toastMessage = '';
        }, 3000);
      }
    },

    async submitReview() {
      if(this.isButtonActive === false){
        console.log('rageclick prevented')
      } else {
        this.isButtonActive = false;
        if (this.selectedReviewOption !== null && this.isReviewLongEnough === true && this.isReviewWritten === true) {
          logEvent('review_done_click', {
            'success': true,
            'reaction_clicked': true,
            'review_text_length': this.reviewContent.length,
          })
          await this.processCheckout();
        } else if (this.selectedReviewOption !== null && this.isReviewWritten === false) {
          this.popupActive = true
          logEvent('review_done_click', {
            'success': true,
            'reaction_clicked': true,
            'review_text_length': this.reviewContent.length,
          })
        } else if (this.isReviewLongEnough === false) {
          logEvent('review_done_click', {
            'success': false,
            'reaction_clicked': true,
            'review_text_length': this.reviewContent.length,
          })
          this.toastMessage = '리뷰를 20자 이상 작성해주세요';
          this.showToast = true;
          setTimeout(() => {
            this.showToast = false;
            this.toastMessage = '';
          }, 3000);
        } else {
          logEvent('review_done_click', {
            'success': false,
            'reaction_clicked': false,
            'review_text_length': this.reviewContent.length,
          })
          this.toastMessage = '어떠셨는지 평가를 선택해주세요';
          this.showToast = true;
          setTimeout(() => {
            this.showToast = false;
            this.toastMessage = '';
          }, 3000); // 3초 후 토스트 숨김
        }
        this.isButtonActive = true;
      }
    },
    handleBackButtonClick() {
      console.log(this.$route.meta.fromPath);
      if (!this.$route.meta.fromPath.includes('result')) {
        showBottomBar();
        this.$router.go(-1);
        return 'back';
      } else {
        this.$router.go(-1);
        return 'back';
      }
    },
    closePopup(){
      this.popupActive = false;
    }
  },
}
</script>

<style scoped>
.content {
  flex: 1;
  width: 100vw;
  max-height: calc(var(--vh, 1vh) * 100); /* 화면 높이에 맞춤 */
  overflow-y: auto; /* 내용이 넘칠 경우 스크롤 허용 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 70px;
  box-sizing: border-box;
}
.section {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.header {
  width: 100vw;
  box-sizing: border-box;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.clover-icon-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  color: var(--Color-palette-Primary-Default, #4BD1A9);
}

.review-select-options-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
}

.review-select-option {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;

  border-radius: 90px;
  border: 0.5px solid var(--Grayscale-Gray9);
  background: var(--Grayscale-Gray12);
}

.selected {
  box-shadow: inset 0 0 0 1.2px #1E2352;
  background: #F7F7FF;
}

.input-field{
  display: flex;
  width: 100vw;
  box-sizing: border-box;
  padding: 0 16px 8px 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  text-align: left;
  position: relative;
}

.input-field textarea {
  width: 100%;
  padding: 16px;
  padding-bottom: 44px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 0.5px solid var(--Grayscale-Gray9, #D9D9D9);
  background: var(--Grayscale-Gray12, #FFF);
  font-size: 16px;
}

.border-red {
  border-color: var(--Color-palette-System-color-Red, #F66) !important;
}

.input-field textarea::placeholder{
  color: var(--Grayscale-Gray6, #999);
  font-size: 14px;
  line-height: 120%;
}

.guide-text{
  position: absolute;
  bottom: 9px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 66px);
  padding: 7px 16px;
  text-align: left;
  overflow: hidden;


  border-radius: 0 0 8px 8px;
  background: var(--Grayscale-Gray11, #F5F5F5);  transition: transform 0.5s;
  height: 20px; /* 텍스트 높이에 맞게 조정 */
}

.guide-text span {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.4s, transform 0.4s;
}

.guide-text span.active {
  opacity: 1;
  transform: translateY(0);
}

.text-limit{
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px 8px 16px;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;

  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: var(--Grayscale-Gray6, #999);
}


.hidden-guidelines {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.show {
  max-height: 100%;
  transition: max-height 0.5s ease-in-out;
}

.guideline{
  position: relative;
  width: calc(100% - 32px);
  box-sizing: border-box;
  padding: 12px 16px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 24px;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Grayscale-Gray11, #F5F5F5);

  transition: max-height 0.5s ease-in-out;

}

.guideline-title{
  color: var(--Grayscale-Gray5, #777);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
}

.guideline-text {
  color: var(--Grayscale-Gray5, #777);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
}


ol{
  padding-left: 16px;
  font-size: 13px;
}

li {
  padding-bottom: 4px;
}
ul{
  padding-top: 4px;
  padding-left: 3px;
  padding-right: 3px;
  list-style-type: disc;
  margin-top: 12px;
}

.toggle-button{
  position: absolute;
  height: 24px;
  width: 24px;
  top: 16px;
  right: 16px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out; /* Animation for rotation */
  transform-origin: center; /* 회전의 중심점을 중앙으로 설정 */
  transform: rotate(180deg); /* 180-degree rotation */
}

.toggle-button.rotated {
  transform: rotate(0deg); /* 180-degree rotation */
}

.footer-button {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 20px 0;
  flex-shrink: 0;

  color: var(--Grayscale-Gray12);
  background: var(--Color-palette-Primary-Default);
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 65vw;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 100;
  transition: opacity 0.5s, transform 0.5s;
}
.toast.show {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s, transform 0.5s;
}

</style>