<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="overlay" @click="closePopup"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 20px;">
          매일 오늘의 운세를 알려드릴까요?
        </span>
      </div>
      <div class="popup-content">
        <div class="time-select">
          <div class="time-focus">
            <div class="title1" style="flex:2; transform:translateX(35%)"></div>
            <div class="title1" style="flex:2; transform:translateX(35%)">시</div>
            <div class="title1" style="flex:2; transform:translateX(35%)">분</div>
          </div>
          <!-- AM/PM Picker -->
          <swiper :slides-per-view="3" :centered-slides="true" :space-between="0"
                  direction="vertical" class="ampm-swiper" @slideChange="onAmPmChange"
                  :initial-slide="initialAmPmIndex" ref="ampmSwiper">
            <swiper-slide v-for="ampm in ampmOptions" :key="ampm">
              <div class="swiper-item">{{ ampm }}</div>
            </swiper-slide>
          </swiper>

          <!-- Hour Picker -->
          <swiper :slides-per-view="5" :centered-slides="true" :space-between="0"
                  direction="vertical" class="hour-swiper" @slideChange="onHourChange"
                  :initial-slide="initialHourIndex" ref="hourSwiper">
            <swiper-slide v-for="hour in hours" :key="hour">
              <div class="swiper-item">{{ hour }}</div>
            </swiper-slide>
          </swiper>

          <!-- Minute Picker -->
          <swiper :slides-per-view="5" :centered-slides="true" :space-between="0"
                  direction="vertical" class="minute-swiper" @slideChange="onMinuteChange"
                  :initial-slide="initialMinuteIndex" ref="minuteSwiper">
            <swiper-slide v-for="minute in minutes" :key="minute">
              <div class="swiper-item">{{ minute }}</div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div class="popup-footer">
        <div class="btn2" @click="cancelTime">
          다음에
        </div>
        <div class="btn2" @click="saveTime" style="flex: 5; background: var(--Color-palette-Primary-Default); color: var(--Grayscale-Gray12)">
          저장
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "AlarmTimeSettingPopup",
  components: { Swiper, SwiperSlide },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    initialTime: {
      type: String,
      default: '오전 07:00',
    },
  },
  data() {
    return {
      selectedTime: {
        ampm: '오전',
        hours: '07',
        minutes: '00',
      },
      ampmOptions: ['오전', '오후'],
      hours: Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0')),
      minutes: Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')),
      initialAmPmIndex: 0,
      initialHourIndex: 6,
      initialMinuteIndex: 0,
    };
  },
  watch: {
    initialTime: {
      immediate: true,
      handler() {
        this.initializeTime();
      }
    }
  },
  emits: ['save', 'close'],
  mounted() {
    this.initializeTime();
  },
  methods: {
    initializeTime() {
      if (this.initialTime) {
        const [ampm, time] = this.initialTime.split(' ');
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours);

        this.selectedTime.ampm = ampm;
        this.selectedTime.hours = hours;
        this.selectedTime.minutes = minutes;

        this.initialAmPmIndex = ampm === '오후' ? 1 : 0;
        this.initialHourIndex = hour - 1; // 1-based to 0-based index
        this.initialMinuteIndex = parseInt(minutes);
      }
    },
    onAmPmChange(swiper) {
      this.selectedTime.ampm = this.ampmOptions[swiper.activeIndex];
    },
    onHourChange(swiper) {
      this.selectedTime.hours = this.hours[swiper.activeIndex];
    },
    onMinuteChange(swiper) {
      this.selectedTime.minutes = this.minutes[swiper.activeIndex];
    },
    closePopup() {
      this.$emit('close');
    },
    cancelTime() {
      this.$emit('save', 'off');
      this.closePopup();
    },
    saveTime() {
      const time12 = `${this.selectedTime.ampm} ${this.selectedTime.hours}:${this.selectedTime.minutes}`;
      const time24 = this.convertTo24HourFormat(time12);
      this.$emit('save', { time12, time24 });
    },
    convertTo24HourFormat(time12) {
      const [ampm, timeStr] = time12.split(' ');
      let [hours, minutes] = timeStr.split(':');
      hours = parseInt(hours);
      if (ampm === '오후' && hours !== 12) {
        hours += 12;
      } else if (ampm === '오전' && hours === 12) {
        hours = 0;
      }
      return `${String(hours).padStart(2, '0')}:${minutes}`;
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 30px 16px 0 16px;
  justify-content: space-between;
}

.popup-content{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
}

input{
  width: 90% !important;
  padding: 12px 16px;
  box-sizing: border-box;
}

.time-select {
  position: relative;
  display: flex;
  width: calc(100% - 60px);
  padding: 0 30px;
  margin: 0 20px;
  box-sizing: border-box;
  height: 200px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  align-items: center;
}

.time-focus {
  position: absolute;
  width: 100%;
  height: 44px;
  background: var(--Grayscale-Gray11);
  border-radius: 8px;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
}

.ampm-swiper {
  flex:2 ;
  width: 60px;
  height: 120px;
  text-align: left;
}

.hour-swiper {
  flex: 2;
  width: 60px;
  height: 180px;
  text-align: center;
}

.minute-swiper {
  flex: 2;
  width: 60px;
  height: 180px;
  text-align: center;
}

.swiper-slide {
  display: flex !important;
  justify-content: center!important;
  align-items: center!important;
  font-size: 22px;
  font-weight: bold;
  color: var(--Grayscale-Gray8);
}

.swiper-slide-active {
  font-weight: bold;
  color: var(--Grayscale-Gray2);
  font-size: 22px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swiper-item{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SUIT Variable", sans-serif;
}

.time-select.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.gray-text {
  color: var(--Grayscale-Gray8) !important;
}


.btn2{
  flex: 2;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray6);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}


</style>
