<template>
  <div class="content">
    <div class="section">
      <div class="header title3" style="margin-bottom: 20px;">
        휴대폰 인증
      </div>
      <label for="allAgree" class="custom-checkbox">
        <input type="checkbox" id="allAgree" :checked="isAllAgree" @change="toggleAllCheckboxes" class="hidden-checkbox">
        <span v-if="isAllAgree" class="checkbox-circle">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13" cy="13" r="12" fill="#1E2352"/>
            <path d="M9 12L12.9048 17L17.6667 9" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        <span v-else class="checkbox-circle">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13" cy="13" r="12" fill="#B2B2B2"/>
            <path d="M9 12L12.9048 17L17.6667 9" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        마이타로 이용약관 동의
      </label><!-- 하단 체크박스 -->
      <div>
        <label class="custom-checkbox">
          <input type="checkbox" :checked="agreements.service" @change="() => updateAgreement('service')" class="hidden-checkbox">
          <span class="checkbox-icon">
          <span v-if="agreements.service">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 12L12.9048 17L17.6667 9" stroke="#1E2352" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
           </span>
          <span v-else>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M9 12L12.9048 17L17.6667 9" stroke="#D9D9D9" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </span>
          (필수) 서비스 이용 약관&nbsp;&nbsp;
          <svg @click="openInNewTab('https://terms.tickl.ai/gpt/')"
               width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.86 6.016L1.666 11.21L0.98 10.524L5.474 6.016L0.98 1.522L1.666 0.821999L6.86 6.016Z" fill="#999999"/>
          </svg>

        </label>
      </div>
      <div>
        <label class="custom-checkbox">
          <input type="checkbox" :checked="agreements.privacy" @change="() => updateAgreement('privacy')" class="hidden-checkbox">
          <span class="checkbox-icon">
          <span v-if="agreements.privacy">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 12L12.9048 17L17.6667 9" stroke="#1E2352" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
           </span>
          <span v-else>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M9 12L12.9048 17L17.6667 9" stroke="#D9D9D9" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </span>
          (필수) 개인정보 수집이용 동의&nbsp;&nbsp;
          <svg @click="openInNewTab('https://terms.tickl.ai/gpt-1/')"
               width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.86 6.016L1.666 11.21L0.98 10.524L5.474 6.016L0.98 1.522L1.666 0.821999L6.86 6.016Z" fill="#999999"/>
          </svg>
        </label>
      </div>

      <div class="disclaimer">
        <img src="@/tarot_box/pages/web_checkout/assets/disclaimer.png" alt="disclaimer" style="width: 90%; height: auto;">
      </div>
    </div>
  </div>
</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";

export default ({
  name: "CheckoutConsentForm",
  data() {
    return {
      agreements: {
        service: false,
        privacy: false,
      },
      isAllAgree: false,
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    if(status && status === 'fail'){
      this.agreements.service = true;
      this.agreements.privacy = true;
      this.isAllAgree = true;
    }
  },
  emits: ['update:agreements', 'update:isAllAgree'],
  methods: {
    toggleAllCheckboxes() {
      if(!this.isAllAgree){
        logEvent('purchase_allagree_click', {})
      }
      this.isAllAgree = !this.isAllAgree;
      this.agreements.service = this.isAllAgree;
      this.agreements.privacy = this.isAllAgree;
      this.$emit('update:agreements', this.agreements);
      this.$emit('update:isAllAgree', this.isAllAgree);
    },
    updateAgreement(type) {
      this.agreements[type] = !this.agreements[type];
      if(this.agreements.service === true && this.agreements.privacy === true){
        this.isAllAgree = true;
      } else {
        this.isAllAgree = false;
      }
      this.$emit('update:agreements', this.agreements);
      this.$emit('update:isAllAgree', this.agreements.service && this.agreements.privacy);
    },
    openInNewTab(url) {
      if (url) {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
          newWindow.focus();
        } else {
          console.error('Failed to open the new tab. The popup might have been blocked.');
        }
      } else {
        console.error('Invalid URL:', url);
      }
    }
  }
})
</script>



<style scoped>
.content {
  width: 100%;
  overflow-y: auto; /* 내용이 넘칠 경우 스크롤 허용 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 24px;
  box-sizing: border-box;
}

.hidden-checkbox {
  display: none;
}

.hidden-checkbox:checked + .checkbox-circle::after {
  opacity: 1;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 14px;
  margin: 4px 0;
}

.checkbox-circle {
  margin-right: 8px;
}

/* 체크 상태일 때의 스타일 */
.checkbox-circle::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--Color-palette-Primary-Default); /* primary color를 변수로 지정하거나 직접 색상 코드 사용 */
  opacity: 0;
  transition: opacity 0.2s;
}

.checkbox-icon{
  margin-right: 6px;
}

.disclaimer{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 12px;
  margin-top: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  background: #F5F5F5;
}
</style>