<template>

  <div class="detail-header-bar" v-safe-area-top>
    <h2 class="title2">리뷰 작성</h2>
  </div>

  <div class="content" v-safe-area-top>
    <div class="section">
      <div class="point">
        <div>
          <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M37.8375 37.835C37.8926 37.7744 37.9474 37.7135 38.0019 37.6523C38.0564 37.7135 38.1112 37.7744 38.1664 37.835C38.1112 37.8956 38.0564 37.9565 38.0019 38.0177C37.9474 37.9565 37.8926 37.8956 37.8375 37.835ZM38.0019 68.3943C34.0198 72.8666 28.3027 75.67 21.9506 75.67C9.91906 75.67 0.165534 65.6125 0.165534 53.206C0.165534 47.2599 2.40596 41.8533 6.06382 37.835C2.40595 33.8167 0.165527 28.4101 0.165527 22.464C0.165527 10.0575 9.91905 0 21.9506 0C28.3027 0 34.0198 2.80334 38.0019 7.27567C41.984 2.80334 47.7011 0 54.0532 0C66.0848 0 75.8383 10.0575 75.8383 22.464C75.8383 28.4101 73.5979 33.8167 69.94 37.835C73.5979 41.8533 75.8383 47.2599 75.8383 53.206C75.8383 65.6125 66.0848 75.67 54.0532 75.67C47.7011 75.67 41.984 72.8666 38.0019 68.3943Z" fill="#4BD1A9"/>
          <path d="M39.7586 55.4393C37.3976 55.4393 35.1879 55.0307 33.1295 54.2134C31.0712 53.3658 29.2701 52.2004 27.7264 50.7172C26.2129 49.234 25.0172 47.5086 24.1394 45.5411C23.2918 43.5433 22.868 41.3941 22.868 39.0936C22.868 36.7628 23.2918 34.6137 24.1394 32.6461C25.0172 30.6483 26.228 28.9078 27.7718 27.4246C29.3155 25.9414 31.1014 24.7911 33.1295 23.9738C35.1879 23.1263 37.3976 22.7025 39.7586 22.7025C41.5143 22.7025 43.2094 22.9446 44.8439 23.429C46.4785 23.9133 47.992 24.5943 49.3844 25.4722C50.8071 26.35 52.0179 27.4094 53.0168 28.6505L48.5671 33.0548C47.4169 31.6321 46.085 30.5575 44.5715 29.831C43.0883 29.1046 41.484 28.7413 39.7586 28.7413C38.3359 28.7413 37.0041 29.0137 35.763 29.5586C34.5219 30.0732 33.4474 30.7997 32.5393 31.738C31.6312 32.6461 30.9198 33.7358 30.4052 35.0072C29.8906 36.2482 29.6334 37.6104 29.6334 39.0936C29.6334 40.5466 29.8906 41.9087 30.4052 43.18C30.9198 44.4211 31.6463 45.5108 32.5847 46.4492C33.523 47.3573 34.6127 48.0686 35.8538 48.5832C37.1251 49.0978 38.5024 49.3551 39.9856 49.3551C41.6505 49.3551 43.2094 49.007 44.6623 48.3108C46.1153 47.6146 47.4017 46.6157 48.5217 45.3141L52.8352 49.5821C51.8363 50.7929 50.6406 51.8372 49.2482 52.715C47.8558 53.5929 46.3423 54.2739 44.7077 54.7582C43.1034 55.2123 41.4537 55.4393 39.7586 55.4393Z" fill="#121212"/>
        </svg>
        </div>
        <div class="title4 point-title">
          {{ point }}
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4655 8.16998L8.50027 8.13136C8.51179 8.1443 8.52338 8.15717 8.53504 8.16998C8.52338 8.18279 8.5118 8.19567 8.50027 8.20861C8.48875 8.19567 8.47716 8.18279 8.4655 8.16998ZM8.50027 14.6316C7.65828 15.5772 6.44944 16.17 5.10633 16.17C2.56233 16.17 0.5 14.0434 0.5 11.4201C0.5 10.1628 0.97373 9.01964 1.74717 8.16998C0.97373 7.32033 0.5 6.17714 0.5 4.91986C0.5 2.29658 2.56233 0.169983 5.10633 0.169983C6.44944 0.169983 7.65828 0.762727 8.50027 1.70837C9.34226 0.762727 10.5511 0.169983 11.8942 0.169983C14.4382 0.169983 16.5005 2.29658 16.5005 4.91986C16.5005 6.17714 16.0268 7.32033 15.2534 8.16998C16.0268 9.01964 16.5005 10.1628 16.5005 11.4201C16.5005 14.0434 14.4382 16.17 11.8942 16.17C10.5511 16.17 9.34226 15.5772 8.50027 14.6316Z" fill="#4BD1A9"/>
            <path d="M8.8717 11.8924C8.37248 11.8924 7.90525 11.806 7.47002 11.6332C7.0348 11.454 6.65397 11.2076 6.32755 10.8939C6.00753 10.5803 5.75472 10.2155 5.56911 9.79948C5.3899 9.37706 5.30029 8.92263 5.30029 8.4362C5.30029 7.94337 5.3899 7.48895 5.56911 7.07292C5.75472 6.6505 6.01074 6.28248 6.33715 5.96886C6.66357 5.65524 7.0412 5.41202 7.47002 5.23921C7.90525 5.06 8.37248 4.9704 8.8717 4.9704C9.24293 4.9704 9.60135 5.0216 9.94697 5.12401C10.2926 5.22641 10.6126 5.37042 10.907 5.55603C11.2078 5.74164 11.4639 5.96566 11.6751 6.22807L10.7342 7.15933C10.491 6.85851 10.2094 6.6313 9.88936 6.47769C9.57575 6.32408 9.23653 6.24727 8.8717 6.24727C8.57089 6.24727 8.28927 6.30488 8.02685 6.42008C7.76444 6.52889 7.53723 6.6825 7.34521 6.88091C7.1532 7.07292 7.00279 7.30334 6.89399 7.57215C6.78518 7.83457 6.73078 8.12258 6.73078 8.4362C6.73078 8.74342 6.78518 9.03144 6.89399 9.30025C7.00279 9.56267 7.1564 9.79308 7.35481 9.9915C7.55323 10.1835 7.78364 10.3339 8.04606 10.4427C8.31487 10.5515 8.60609 10.6059 8.91971 10.6059C9.27173 10.6059 9.60135 10.5323 9.90857 10.3851C10.2158 10.2379 10.4878 10.0267 10.7246 9.75148L11.6367 10.6539C11.4255 10.91 11.1726 11.1308 10.8782 11.3164C10.5838 11.502 10.2638 11.646 9.91817 11.7484C9.57895 11.8444 9.23013 11.8924 8.8717 11.8924Z" fill="#121212"/>
          </svg>
          적립!
        </div>
        </div>
      <div class="disclaimer">
        <div class="title2">리뷰 작성 완료</div>
        <div class="text2" style="color: var(--Grayscale-Gray5, #777); line-height: 120%; /* 16.8px */ margin-top: 16px">
          소중한 리뷰 감사합니다<br>
          작성해주신 리뷰는<br>
          운영정책에 맞는지 검토하고 게시 됩니다!
        </div>
      </div>
    </div>
  </div>

  <div v-safe-area-bottom class="button" @click="goMyTarotHistory">
    <button class="buttonL" >닫기</button>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {changeBottomSafeAreaColor, showBottomBar, requestInAppReview} from "@/tarot_box/helper/native_api";

export default {
  name: "WriteReviewCompletePage",
  computed: {
    ...mapGetters(['user','tarotBoxes']),
  },
  data() {
    return {
      point: null,
    };
  },
  mounted(){
    this.point = this.$route.query.reward_clover;

    const reactionId = parseInt(this.$route.query.reaction_id);
    if(reactionId < 3){
      setTimeout(() => {
        requestInAppReview();
      }, 1500);
    }
  },
  methods: {
    goMyTarotHistory() {
      showBottomBar();
      changeBottomSafeAreaColor('#171B2B');
      const urlParams = new URLSearchParams(window.location.search);
      const buildNumber = urlParams.get('build_number');
      const osType = urlParams.get('os_type');
      const insetTop = urlParams.get('inset_top');
      const insetBottom = urlParams.get('inset_bottom');
      this.$router.push(`/tarot_box/mytarot?tarot_user_id=${this.user.userId}&phone_number=${this.user.phoneNumber}&build_number=${buildNumber}&os_type=${osType}&inset_top=${insetTop}&inset_bottom=${insetBottom}`);
    },
  },
}
</script>

<style scoped>
.content {
  flex: 1;
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100); /* 화면 높이에 맞춤 */
  overflow-y: auto; /* 내용이 넘칠 경우 스크롤 허용 */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.section {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.point{
  margin-bottom: 24px;
  flex-direction: column;
}
.point-title{
  margin-top: 16px;
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--Color-palette-Primary-Default, #1E2352);
  border-radius: 90px;
  background: var(--Grayscale-Gray11, #F5F5F5);
}
.detail-header-bar{
  height: 68px;
  max-width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  border-bottom: 1px solid var(--Grayscale-Gray10);

  flex-shrink: 0;
}

.button{
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 90vw;
  justify-content: center;
  align-items: center;

}

.button button{
  width: 100%;
  padding: 16px 0;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid var(--Color-palette-Primary-Default, #1E2352);
  background: var(--Grayscale-Gray12, #FFF);
}
</style>