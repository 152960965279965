<template>
  <div class="select-item" :class="{ 'active': isActive, 'completed': selectedCountry.flag }">
    <span class="title3" :class="{ 'gray3': selectedCountry.flag }">
      <template v-if="selectedCountry.flag">
        {{ selectedCountry.flag }} {{ selectedCountry.code }}
      </template>
      <template v-else>
        <span class="gray8">국가를 선택해주세요.</span>
      </template>
    </span>
<!--    <img src="@/tarot_box/pages/box_input_v2/assets/Input/select.svg" alt="select"/>-->
  </div>
</template>

<script>
export default {
  name: "CountrySelect",
  props: {
    selectedCountry: {
      type: Object,
      default: () => ({ flag: '🇰🇷', code: '+82' })
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    openSelector() {
      this.$emit('open-bottom-sheet', 'country');
    }
  },
  emits: ['open-bottom-sheet']
}

</script>

<style scoped>
.select-item {
  width: 100%;
  padding: 13px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--Grayscale-Gray10, #ECECEC);
}

.select-item.active {
  border: 2px solid var(--Grayscale-Gray2, #242424);
}

.select-item.completed .title3 {
  color: var(--Grayscale-Gray3);
}

.gray3 {
  color: var(--Grayscale-Gray3);
}
</style>