<template>
  <div class="unclickable-wrapper" v-if="disableScreenClick"/>
  <div id="error" v-if="showError">
    <AlertError @retry="retryInitTarotBoxInputPage"/>
  </div>
  <div v-else id="stage">
    <TarotBoxHeader
        @close-button-click="goBack"/>
    <div v-if="currentStep===0">
      <InputStart :language="language" :hasHeaderImg="hasHeaderImg" :pageInfo="startPageImg" :buttonAble="startButtonAble"
                  @next="nextStep" @close="closePage"></InputStart>
    </div>
    <div v-for="pageIndex in inputPageMetaArr.length" :key="pageIndex">
      <div :class="{ invisible: currentStep !== pageIndex}">
        <InputForm :language="language" :draw-tarot-card="drawTarotCard"
                   :currentIndex="currentStep" :currentStepData="inputPageMetaArr[pageIndex-1]" :totalStepNumber="inputPageMetaArr.length"
                   @onInputDataChange="handleMetaData" @prev="prevStep" @next="nextStep" @close="closePage">
        </InputForm>
      </div>
    </div>
  </div>

  <TarotCardSelectPage v-if="showTarotCardSelectPage"
                       :user-input-data="userInputData" :box-id="this.$route.params.boxId"
                       @make-box-result="makeBoxHistory"
                       @close-tarot-card-select-page="showTarotCardSelectPage=false"/>

  <BoxPaymentPopup v-if="showPaymentPopup"
                   :boxPrice="paymentPopupData.price"
                   :paymentPopupData="paymentPopupData.payment_popup"
                   :tarotBoxHistoryId="paymentPopupData.id"
                   :boxId="boxId"
                   :inputData="userInputData"
                   :pickedCards="userPickedCards"
                   @paymentDone="openPaymentDonePopup()"/>
  <PaymentDonePopup v-show="paymentDone"/>
</template>

<script>
import InputForm from "@/tarot_box/pages/box_input/components/input_modules/InputForm.vue";
import InputStart from "@/tarot_box/pages/box_input/components/input_modules/InputStart.vue";
import {
  getMySajuByFetch,
  getTarotBoxInputDataByFetch,
  getTarotBoxItemsByFetch,
  makeBoxResult
} from "@/tarot_box/helper/mytarot_api";
import AlertError from "./components/AlertError.vue";
import * as Sentry from "@sentry/vue";
import TarotBoxHeader from "./components/TarotBoxHeader.vue";
import {logEvent} from "@/tarot_box/helper/native_api";
import TarotCardSelectPage from "@/tarot_box/pages/box_input/TarotCardSelectPage.vue";
import {mapActions, mapGetters} from "vuex";
import BoxPaymentPopup from "@/tarot_box/pages/box_input/components/BoxPaymentPopup.vue";
import PaymentDonePopup from "@/tarot_box/pages/box_input/components/PaymentDonePopup.vue";

export default{
  async mounted() {
    this.addSafeAreaColorTop('#FF646464');
    this.addSafeAreaColorBottom('#FFFDFDFD');
    if (this.language === "") {
      this.language = "ko";
    }
    this.boxId = parseInt(this.$route.params.boxId);
    // Ensure tarotBoxes is loaded and contains data
    if (Array.isArray(this.tarotBoxes) && this.tarotBoxes.length > 0) {
      const tarotBoxData = this.tarotBoxes.find(box => box.id === this.boxId);
      // Check if tarotBoxData is found
      if (tarotBoxData && tarotBoxData.tarotSpread) {
        this.drawTarotCard = tarotBoxData.tarotSpread.name !== 'no_spread';
      } else {
        console.error('Tarot box data or tarotSpread is undefined');
        // Handle the error appropriately (e.g., show an error message)
      }
    } else {
      let res = await getTarotBoxItemsByFetch('','',true)
      if (res.result === 'success'){
        this.tarotBoxData = res.data.tarot_boxes.find(box => box.id === this.boxId);
        this.setTarotBoxesData(res.data.tarot_boxes);
      }
    }

    this.loadInitialData();
    window.onBackButtonClick = this.onBackButtonClick; // Rob: 앱과 뒤로가기 기능 통신을 시작합니다.
    const tarotBoxData = this.tarotBoxes.find(box => box.id === this.boxId);
    logEvent("snack_detail_input_into", {
      "tarot_box_id": this.$route.params.boxId,
      "tarot_box_name": tarotBoxData?.title,
    });
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes']),
  },
  beforeUnmount() {
    if (window.onBackButtonClick === this.onBackButtonClick) {
      window.onBackButtonClick = null; // Rob: Page 이탈 전 통신을 끊습니다.
    }
    this.previousSafeAreaColorTop();
    this.previousSafeAreaColorBottom();
  },
  data() {
    return {
      language: this.$route.params.language,
      showError: false,
      currentStep:0,
      retryCount: 0,
      startButtonAble: false,
      hasHeaderImg: false,
      startPageImg: {},
      inputPageMetaArr: [],
      userInputData: '',
      userPickedCards: '',
      showTarotCardSelectPage: false,
      boxId: null,
      drawTarotCard: false,
      paymentPopupData: {},
      showPaymentPopup: false,
      paymentDone: false,
      disableScreenClick: false,
    }
  },
  methods: {
    ...mapActions(['addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom', 'setTarotBoxesData']),
    sendInputData: function() {
      const data = [];

      for (let i = 0; i < this.inputPageMetaArr.length; i++) {
        const item = this.inputPageMetaArr[i];
        console.log(item);
        data.push({
          "key": item.key,
          "value": item.value
        });
      }

      this.userInputData = JSON.stringify(data);
      if(this.drawTarotCard) {
        this.showTarotCardSelectPage = true;
      } else {
        this.makeBoxHistory();
      }
    },
    nextStep: function() {
      if(this.currentStep===this.inputPageMetaArr.length) {
        this.sendInputData();
      } else{
        this.currentStep++;
      }
    },
    prevStep: function() {
      this.currentStep--;
    },
    //Rob: 뒤로가기 관련 로직입니다.
    onBackButtonClick() {
      if(this.showPaymentPopup || this.paymentDone) {
        return;
      }
      else if(this.showTarotCardSelectPage) {
        this.showTarotCardSelectPage = false;
        return 'back';
      }
      else if(this.currentStep === 0) {
        this.goBack();
        return 'back';
      } else {
        this.prevStep();
        return 'back';
      }
    },
    goBack(){
      this.$router.go(-1);
      this.previousSafeAreaColorTop();
      this.previousSafeAreaColorBottom();
    },
    handleMetaData: function(inputMetaDataIndex, inputData){
      this.inputPageMetaArr[inputMetaDataIndex].value = inputData;
      let minLength = this.inputPageMetaArr[inputMetaDataIndex].minLength;
      let maxLength = this.inputPageMetaArr[inputMetaDataIndex].maxLength;
      this.inputPageMetaArr[inputMetaDataIndex].buttonAble = inputData.length >= minLength && inputData.length <= maxLength;
    },
    closePage: function() {
      this.sendInfoToApp('{"action": "close"}');
    },
    sendInfoToApp: function(info) {
      if("undefined" !== typeof Native && !this.isWeb()) {
        // eslint-disable-next-line
        Native.postMessage(info);
      }
    },
    async loadInitialData(){
      await this.initTarotBoxInputPage(this.$route.params.boxId);
      await this.setMySajuDefaultDate();
      this.startButtonAble = true;
      this.showError = false;
    },
    async initTarotBoxInputPage(tarotBoxId) {
      let resultData = await getTarotBoxInputDataByFetch(tarotBoxId);

      if (resultData === undefined || resultData === null ||
          resultData.inputPageMetaArr === undefined || resultData.inputPageMetaArr === null ||
          resultData.inputPageMetaArr.length === 0) {
        if(this.retryCount < 2) {
          this.retryCount++;
          console.log("retry " + this.retryCount);
          await this.initTarotBoxInputPage(tarotBoxId);
          return;
        }
        this.showError = true;
        return;
      }

      if (resultData.hasHeaderImg !== undefined) {
        this.hasHeaderImg = resultData.hasHeaderImg;
      } else {
        this.hasHeaderImg = false;
      }

      this.startPageImg = resultData.startPageImgUrl;
      this.inputPageMetaArr = resultData.inputPageMetaArr.map((item) =>{
        const {
          buttonAble=false,
          value='',
          inputFieldPlaceholder='',
          minLength = 1,
          maxLength=20,
          ...rest
        } = item;
        return{
          buttonAble, inputFieldPlaceholder,value,minLength, maxLength, ...rest
        };
      });
    },
    retryInitTarotBoxInputPage() {
      this.showError = false; // 다시 시도 버튼 클릭 시 오류 메시지를 숨김
      this.retryCount = 0; // 재시도 횟수 초기화
      this.initTarotBoxInputPage(this.$route.params.boxId);
    },
    async setMySajuDefaultDate() {
      if(this.$isWeb() === true) {
        return;
      }
      try {
        for (const item of this.inputPageMetaArr) {
          if (item.key === "my_saju") {
            const urlParams = new URLSearchParams(window.location.search);
            let userId = urlParams.get("tarot_user_id");

            if(!userId) {
              return;
            }
            let mySajuData = await getMySajuByFetch(userId);

            if (mySajuData.year !== 0 && mySajuData.month !== 0 && mySajuData.day !== 0 && mySajuData.time_index !== 0) {
              if (mySajuData.month < 10)
                mySajuData.month = "0" + mySajuData.month;
              if (mySajuData.day < 10)
                mySajuData.day = "0" + mySajuData.day;

              item.value = `${mySajuData.year}-${mySajuData.month}-${mySajuData.day}/${mySajuData.time_index}`;
              item.buttonAble = true;
            }
          }
        }
      }catch (e){
        Sentry.captureMessage("Error in set default saju date:" + e)
      }
    },
    openPaymentDonePopup() {
      this.showPaymentPopup = false;
      this.paymentDone = true;
    },
    async makeBoxHistory(picked_cards = '') {
      this.disableScreenClick = true;
      this.userPickedCards = picked_cards;
      if(this.$isWeb() === true){
        this.showPaymentPopup = true;
        this.disableScreenClick = false;
        return;
      }
      //TODO: Alert if no input data
      //search 'tarot_user_id' in url params
      const urlParams = new URLSearchParams(window.location.search);
      const paramsUserId = parseInt(urlParams.get('tarot_user_id'));
      const paramsPhoneNumber = urlParams.get('phone_number');

      if(paramsUserId === null || paramsUserId === undefined || paramsUserId === '') {
        Sentry.captureMessage("No User ID found in URL Params while processing App Checkout: " + this.userInputData + ", " + `${paramsUserId}` + ", " + this.userPhoneNumber + ", " + this.userPickedCards + ", " + this.boxId + `${window.location.href}`);
      }

      if(this.userInputData === '[]' || this.userInputData === null || this.userInputData === undefined || this.userInputData === '') {
        Sentry.captureMessage("No Input Data while processing App Checkout: " + this.userInputData + ", " + `${paramsUserId}` + ", " + `${paramsPhoneNumber}` + ", " + this.userPickedCards + ", " + this.boxId+ `${window.location.href}`);
      }

      const apiResult = await makeBoxResult(paramsUserId, paramsPhoneNumber, this.userInputData, picked_cards, this.boxId);

      logEvent("make_box_history", {
        "tarot_box_id": this.boxId,
        "user_id": paramsUserId,
        "picked_cards": picked_cards,
        "api_result": apiResult.result,
        "user_input_data": this.userInputData,
        }
      );

      this.showPaymentPopup = apiResult.result === 'success';
      this.disableScreenClick = false;
      if(picked_cards === '') {
        this.addSafeAreaColorTop('#FF141414');
        this.addSafeAreaColorBottom('#FF333333');
      } else {
        this.addSafeAreaColorTop('#FF040305');
        this.addSafeAreaColorBottom('#FF060606');
      }
      this.paymentPopupData = apiResult.data;
    }
  },
  components: {
    PaymentDonePopup, BoxPaymentPopup,
    TarotCardSelectPage,
    TarotBoxHeader,
    AlertError,
    InputStart,
    InputForm,
  },
}
</script>

<style scoped>
.invisible {
  display: none;
}

.unclickable-wrapper{
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,0);
}
</style>
