<template>
  <div class="modal" :class="{ 'is-active': modelValue }" v-show="modelValue">
    <div class="overlay" @click="closePopup"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          이름 수정
        </span>
        <span class="text3 gray8" style="margin-bottom: 20px;">
          한글, 영문, 숫자로만 12글자까지 입력 가능합니다.
        </span>
      </div>
      <div class="popup-content">
        <input
            type="text"
            class="input"
            v-model="newName"
            :placeholder="'이름 입력'"
            style="margin-bottom: 20px;"
            @input="handleInput"
            @keydown="preventInvalidInput"
            @blur="handleBlur"
            maxlength="12"
            ref="nameInput"
        />
        <div class="text3 gray8" style="margin-bottom: 20px; transform: translateY(-10px)">
          {{ newName.length }}/12
        </div>
      </div>

      <div class="popup-footer">
        <div class="btn gray" @click="closePopup">
          취소하기
        </div>
        <div class="btn" @click="saveName" style="background: var(--Color-palette-Primary-Default)">
          저장하기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NameChangePopup",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'change-name'],
  data() {
    return {
      newName: '',
    }
  },
  watch: {
    modelValue(value) {
      if (value) {
        this.newName = this.userName;
      }
    }
  },
  methods: {
    closePopup() {
      this.$emit('update:modelValue', false);
      this.newName = this.userName; // 팝업을 닫을 때 초기값으로 리셋
    },
    saveName() {
      if (this.newName.trim() !== '') {
        this.$emit('change-name', this.newName.trim());
        this.closePopup();
      }
    },
    handleInput(event) {
      let newValue = event.target.value.replace(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣()]/g, '');

      // 12글자로 제한
      if (newValue.length > 12) {
        newValue = newValue.slice(0, 12);
      }

      // 값이 변경되었다면 업데이트
      this.newName = newValue;

      // input 요소의 값을 직접 업데이트
      this.$refs.nameInput.value = newValue;
    },
    preventInvalidInput(event) {
      const regex = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣()]$/;
      if (!regex.test(event.key) && !this.isAllowedKey(event)) {
        event.preventDefault();
      }
    },
    isAllowedKey(event) {
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
      return allowedKeys.includes(event.key);
    },
    handleBlur() {
      this.newName = this.newName.trim();
    },
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 50px 16px 0 16px;
  justify-content: space-between;
}

input{
  width: 90% !important;
  padding: 12px 16px;
  box-sizing: border-box;
}

.btn{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray12);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}

.btn.gray{
  background-color: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray6);
}


</style>