<template>
  <div class="page">
    <div class="popup">
      <div class="close_button" @click="closePopup">
        <img :style="{'width':'16px'}" src="../../assets/images/input_images/close_button.png" alt=""/>
      </div>
      <div class="contents">
        <div class="text">
          <div class="title">나의 이목구비가 잘 보이는<br>사진을 등록해주세요</div>
          <div class="detail">고민된다면 사진 아래 가이드를 확인해보세요!</div>
        </div>
        <div class="button_area">
          <div class="aos_img_select_button">
            <img class="aos_button_img" src="../../assets/images/input_images/face_img_upload_images/aos_gallery.svg" alt=""
                 v-wave
                 @click="updateImgUploadType('aos_gallery')"/>
            사진첩
          </div>
          <div class="aos_img_select_button">
            <img class="aos_button_img" src="../../assets/images/input_images/face_img_upload_images/aos_camera.svg" alt=""
                 v-wave
                 @click="updateImgUploadType('aos_camera')"/>
            카메라
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closePopup(){
      this.$emit('close');
    },
    updateImgUploadType(imgUploadType){
      this.$emit('uploadImg', imgUploadType);
    }
  }
}
</script>

<style scoped>
.page{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
}

.popup{
  position: absolute;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  height: 304px;
  margin-top: 90px;
  border-radius: 12px 12px 0 0;
  background: #FFF;
}

.close_button {
  position: absolute;
  right: 0;
  padding: 21px +18.15px 21px +18.15px;
}

.contents {
  display: flex;
  flex-flow: column nowrap;
  width: calc(360px - 40px);
  margin: 26px 20px 58px 20px;
}

@media (max-width: 360px) {
  .contents {
    max-width: calc(100vw - 40px);
  }
}

.text{
  align-self: flex-start;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 30px;
}

.title{
  padding-bottom: 6px;

  text-align: left;
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.detail {
  text-align: left;
  color: #7F7F7F;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.button_area {
  align-self: center;
  width: inherit;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 16px;
}

.aos_img_select_button{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;

  width: 114px;
  height: 114px;

  border-radius: 12px;
  border: +0.5px solid #DDD;
  background: #FFF;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
  color: #1E2352;
}

.aos_button_img {
  height: 48px;
  margin-bottom: 12px;
}
</style>
