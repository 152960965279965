<template>
  <AppExitPopup v-if="isAppExitPopupActive" @close="isAppExitPopupActive = false"/>
  <MainHeaderBar :bgColor="bgColor"/>
  <ItemsEmpty v-if="isItemEmpty"/>
  <div class="screen" ref="screenRef" v-if="!isItemEmpty">
    <!-- header bar imported from home directory-->
    <ClaimableReviewReward
        v-if="sumReviewRewards() > 0"
        :reward="sumReviewRewards()"
        @open-popup="openHistoryPopup"
    />
    <MyTarotItem
        v-for="item in paginatedData"
        :key="item.id"
        :item="item"
        :os-type="osType"
        :build-number="buildNumber"
        @refresh-user-info="updateBoxHistory"
        @refreshBox="updateBoxHistory"
        @deleteBox="deleteBox"
    />
    <div class="pagination">
      <div v-if="totalPages > 5" @click="changePage(currentPage - 1)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 16L10 12L14 8" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <button v-for="page in visiblePages" :key="page" @click="changePage(page)" :class="{'active': page === currentPage}">
        {{ page }}
      </button>
      <div v-if="totalPages > 5" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 8L14 12L10 16" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <ProcessingPopup v-if="processingPopupActive" @process-popup-close-callback="closeProcessPopup"/>
    <HistoryPolicyPopup v-if="historyPopupActive" @close-popup="closeHistoryPopup"/>
  </div>
  <ItemsEmpty v-if="isItemEmpty"/>
  <teleport to="body">
    <ServiceErrorPopup v-if="isServiceErrorPopupActive"
                       @close="isServiceErrorPopupActive = false"/>
  </teleport>
</template>

<script>
import MainHeaderBar from "@/tarot_box/pages/home/components/main_header_bar/MainHeaderBar.vue";
import ClaimableReviewReward from "@/tarot_box/pages/my_tarot/components/ClaimableReviewReward.vue";
import ItemsEmpty from "@/tarot_box/pages/my_tarot/components/ItemsEmpty.vue";
import MyTarotItem from "@/tarot_box/pages/my_tarot/components/my_tarot_items/MyTarotItem.vue";
import {mapActions, mapGetters} from "vuex";
import {getUserBoxHistory, deleteBoxHistory, getTarotBoxItemsByFetch} from "@/tarot_box/helper/mytarot_api.js";
import {logEvent} from "@/tarot_box/helper/native_api";
import ProcessingPopup from "@/tarot_box/pages/my_tarot/components/processing_popup/ProcessingPopup.vue";
import HistoryPolicyPopup from "@/tarot_box/pages/my_tarot/components/HistoryPolicyPopup.vue";
import ServiceErrorPopup from "@/ServiceErrorPopup.vue";
import AppExitPopup from "@/common/AppExitPopup.vue";
import {remoteConfig} from "@/common/firebase";

export default {
  name: "MyTarotPage",
  components: {
    HistoryPolicyPopup,
    ProcessingPopup,
    ItemsEmpty,
    ClaimableReviewReward,
    MainHeaderBar,
    MyTarotItem,
    ServiceErrorPopup,
    AppExitPopup,
  },
  data() {
    return {
      isItemEmpty: false,
      historyData: [],
      currentPage: 1, // 현재 페이지 번호
      itemsPerPage: 10, // 페이지당 표시할 항목 수
      totalItems: 0, // 전체 항목 수
      intervalId: null,
      bgColor: '#FAFAFA',
      processingPopupActive: false,
      buildNumber: 0,
      osType: '',
      historyPopupActive: false,
      isServiceErrorPopupActive: false,
      isAppExitPopupActive: false,
    }
  },
  mounted() {
    this.buildNumber = parseInt(this.$route.query.build_number);
    this.getPaywallInfo();
    this.osType = this.$route.query.os_type;
    this.updateBoxHistory();
    this.getTarotBoxData();
    this.intervalId = setInterval(() => {
      this.updateBoxHistory();
    }, 5000);
    window.onBackButtonClick = null;
    window.onBackButtonClick = this.onBackButtonClick;
    logEvent('mytarot_into', {});
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (window.onBackButtonClick === this.onBackButtonClick) {
      window.onBackButtonClick = null;
    }
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes']),
    filteredHistoryData() {
      return this.historyData.filter(item => item.status !== "error");
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredHistoryData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredHistoryData.length / this.itemsPerPage);
    },
    visiblePages() {
      const pages = [];
      const startPage = Math.max(1, this.currentPage - 2);
      const endPage = Math.min(this.totalPages, startPage + 4);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
  },
  methods: {
    ...mapActions(['setTarotBoxesData', 'setUserData', 'updatePaywall']),
    getTarotBoxData() {
      let res = getTarotBoxItemsByFetch(this.user.phoneNumber, this.user.userId)
      if (res.result === 'success'){
        this.setTarotBoxesData(res.data.tarot_boxes);
      }
    },
    async getPaywallInfo() {
      if(this.buildNumber < 84){
        return;
      }
      const configValue = remoteConfig.getValue('paywall_banner_list_v2');
      const parsedValue = JSON.parse(configValue.asString());
      console.log('parsedValue', parsedValue)
      this.updatePaywall(parsedValue);
    },
    async updateBoxHistory() {
      const id = this.$route.query.tarot_user_id;
      const phoneNumber = this.$route.query.phone_number;
      this.setUserData({ user_id: id, phone_number: phoneNumber });
      try {
        let res = await getUserBoxHistory(this.user.phoneNumber, this.user.userId);
        if (res.result === 'success') {
          this.historyData = res.data;
          this.totalItems = this.filteredHistoryData.length;
          this.isItemEmpty = this.filteredHistoryData.length === 0;
        } else {
          this.isItemEmpty = true;
        }
      } catch (error) {
        console.error("API 호출 중 오류 발생:", error);
        this.isItemEmpty = true;
      }
    },
    sumReviewRewards() {
      return this.filteredHistoryData.reduce((acc, item) => {
        if (item.feedback_reward_status === 'available') {
          return acc + item.feedback_reward;
        }
        return acc;
      }, 0);
    },
    onBackButtonClick() {
      if (this.isAppExitPopupActive) {
        return;
      } else if (this.historyPopupActive) {
        return;
      } else if (this.isServiceErrorPopupActive){
        return;
      } else {
        this.isAppExitPopupActive = true;
      }
    },
    async deleteBox(itemHistoryId) {
      const id = this.$route.query.tarot_user_id;
      const phoneNumber = this.$route.query.phone_number;
      await deleteBoxHistory(phoneNumber, id, itemHistoryId);
      this.updateBoxHistory();
    },
    scrollToTop() {
      const screenElement = this.$refs.screenRef;
      if (screenElement) {
        screenElement.scrollTo({
          top: 0,
        });
      }
    },
    changePage(pageNumber) {
      if (pageNumber < 1) {
        this.currentPage = 1;
      } else if(pageNumber > this.totalPages) {
        this.currentPage = this.totalPages;
      } else {
        this.currentPage = pageNumber;
      }
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    closeProcessPopup() {
      this.processingPopupActive = false;
    },
    openHistoryPopup() {
      this.historyPopupActive = true;
    },
    closeHistoryPopup() {
      this.historyPopupActive = false;
    },
  },
}
</script>

<style scoped>
.screen {
  background: #FAFAFA;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 150px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 100px;
  padding-bottom: 50px;
  box-sizing: border-box;
  gap: 10px;
  min-width: 250px; /* 예시로 250px로 설정, 필요에 따라 조정 */
}

.pagination button {
  display: inline-flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 4px;
  background: var(--Grayscale-Gray10, #ECECEC);
  color: var(--Grayscale-Gray7, #B2B2B2);
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.pagination button.active {
  border-radius: 4px;
  background: var(--Color-palette-Primary-Lighten4, #E3E6ED);
  color: var(--Color-palette-Primary-Lighten1, #2D3E73);
  text-align: center;
  /* Text style/Button S */
}

</style>
