<template>
  <div v-if="show" class="time_selector_popup_overlay">
    <div class="time_selector_popup">
      <span class="time_selector_popup_title">태어난 시간 선택</span>
      <div class="time_selector">
        <VueScrollPicker class="time_picker" :options="hourList" v-model="hour"/>
        <VueScrollPicker class="etc_picker" :options="[':']" />
        <VueScrollPicker class="time_picker" :options="minuteList" v-model="minute" />
      </div>
      <span class="time_selector_popup_button" @click="updateTime">선택완료</span>
    </div>
  </div>
</template>

<style src="../../../../../../node_modules/vue-scroll-picker/lib/style.css"></style>
<script>
import {VueScrollPicker} from "vue-scroll-picker";

export default {
  name: "TimeSelectorPopup",
  components: {VueScrollPicker},
  props: {
    show: Boolean,
  },
  computed: {
    hour: {
      get() {
        return this.selectedHour;
      },
      set(value) {
        this.selectedHour = value;
      },
    },
    minute: {
      get() {
        return this.selectedMinute;
      },
      set(value) {
        this.selectedMinute = value;
      },
    },
  },
  data() {
    return {
      hourList: Array.from({length: 24}, (_, i) => i.toString(10).padStart(2, '0')),
      minuteList: Array.from({length: 60}, (_, i) => i.toString(10).padStart(2, '0')),
      selectedHour: "00",
      selectedMinute: "00",
    }
  },
  methods: {
    updateTime() {
      this.$emit('change', `${this.selectedHour}:${this.selectedMinute}`);
    },
  }
}
</script>

<style scoped>
.invisible {
  display: none;
}
.time_selector_popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(20, 20, 20, 0.5);
}

.time_selector_popup {
  position: fixed;
  top: 50vh;
  left: 50vw;
  width: 286px;
  height: 266px;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background-color: #ffffff;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.time_selector_popup_title {
  margin-left: 20px;
  margin-top: 24px;
  margin-bottom: 16px;
  color: #121212;
  font-family: "SUIT-Variable", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
}

.time_selector {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  width: 100%;
  height: 162px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time_picker {
  flex: 3;
}

.etc_picker {
  flex: 1;
}

.time_selector_popup_button {
  margin-left: 213px;
  margin-top: 12px;
  color: #1E2352;
  font-family: "SUIT-Variable", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
}
</style>