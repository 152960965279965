<template>
  <div class="page">
    <div class="popup">
      <div class="contents">
        <div class="text">
          <div class="title">{{title}}</div>
          <div class="detail">{{detail}}</div>
        </div>
        <div class="button_area" v-wave @click="closePopup">
          {{buttonText}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.parseMessage(this.msg);
  },
  data() {
    return {
      title: null,
      detail: null,
    };
  },
  props: {
    msg: String,
    buttonText: {
      type: String,
      default: '확인'
    }
  },
  methods: {
    closePopup(){
      this.$emit('close');
    },
    parseMessage(message) {
      const lines = message.split(/[!.]/);
      this.title = lines[0].trim();
      this.detail = lines[1].trim();
    },
  },
}
</script>

<style scoped>
.page{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
}

.popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 320px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 12px;
  background: #FFF;
}

.contents {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 37px 20px 20px 20px;
}

.text{
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 35px;
}

.title{
  margin-bottom: 12px;
  color: #000;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 800;
}

.detail {
  color: #7F7F7F;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.button_area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 289px;
  height: 42px;

  border-radius: 6px;
  background: #1E2352;

  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}
</style>
