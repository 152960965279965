<template>
  <div>
    <div v-web class="start-button">
      <div class="price">
        <div class="bigger-price" v-if="biggerPriceNum > 0">
          {{ formattedBiggerPrice }}
        </div>
        <div class="smaller-price">
          {{ formattedSmallerPrice }}원
        </div>
      </div>
      <div class="cta" @click="goTarotBoxInputPage">
        <span>시작하기</span>
        <span style="font-size: 12px; color: rgba(255,255,255,0.6); font-weight: 400;">먼저 시작해보고 마지막에 결제해요</span>
      </div>
    </div>

    <div v-app v-safe-area-bottom class="start-button2" @click="goTarotBoxInputPage">
      <div class="click-area" @click="goTarotBoxInputPage"/>
      구매하기
      <iframe ref="iframeRef" :srcdoc="computedIframeContent" @load="onIframeLoad" :style="iframeStyle"></iframe>
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.96104 9.96874C8.97418 9.9543 8.98725 9.93979 9.00024 9.9252C9.01323 9.93979 9.02629 9.9543 9.03944 9.96874C9.02629 9.98318 9.01323 9.99769 9.00024 10.0123C8.98725 9.99769 8.97418 9.98318 8.96104 9.96874ZM9.00024 17.238C8.05301 18.3019 6.69307 18.9687 5.18208 18.9687C2.3201 18.9687 0 16.5763 0 13.6252C0 12.2107 0.532967 10.9246 1.40312 9.96874C0.532967 9.01288 0 7.72678 0 6.31232C0 3.36115 2.3201 0.96875 5.18208 0.96875C6.69307 0.96875 8.05301 1.63559 9.00024 2.69943C9.94747 1.63559 11.3074 0.96875 12.8184 0.96875C15.6804 0.96875 18.0005 3.36115 18.0005 6.31232C18.0005 7.72678 17.4675 9.01288 16.5974 9.96874C17.4675 10.9246 18.0005 12.2107 18.0005 13.6252C18.0005 16.5763 15.6804 18.9687 12.8184 18.9687C11.3074 18.9687 9.94747 18.3019 9.00024 17.238Z" fill="#4BD1A9"/>
        <path d="M9.41819 14.1544C8.85656 14.1544 8.33094 14.0572 7.84131 13.8628C7.35169 13.6612 6.92327 13.3839 6.55605 13.0311C6.19603 12.6783 5.91162 12.2679 5.70281 11.7999C5.5012 11.3246 5.40039 10.8134 5.40039 10.2662C5.40039 9.71176 5.5012 9.20053 5.70281 8.73251C5.91162 8.25728 6.19963 7.84326 6.56685 7.49044C6.93407 7.13763 7.35889 6.86401 7.84131 6.6696C8.33094 6.46799 8.85656 6.36719 9.41819 6.36719C9.83581 6.36719 10.239 6.42479 10.6279 6.54C11.0167 6.6552 11.3767 6.81721 11.7079 7.02602C12.0463 7.23483 12.3343 7.48684 12.572 7.78206L11.5135 8.82971C11.2399 8.49129 10.9231 8.23568 10.5631 8.06287C10.2102 7.89006 9.82861 7.80366 9.41819 7.80366C9.07978 7.80366 8.76296 7.86846 8.46774 7.99807C8.17253 8.12048 7.91692 8.29328 7.70091 8.5165C7.4849 8.73251 7.31569 8.99172 7.19328 9.29413C7.07087 9.58935 7.00967 9.91337 7.00967 10.2662C7.00967 10.6118 7.07087 10.9358 7.19328 11.2382C7.31569 11.5334 7.4885 11.7927 7.71171 12.0159C7.93492 12.2319 8.19413 12.4011 8.48935 12.5235C8.79176 12.6459 9.11938 12.7071 9.4722 12.7071C9.86822 12.7071 10.239 12.6243 10.5847 12.4587C10.9303 12.2931 11.2363 12.0555 11.5027 11.7459L12.5287 12.7611C12.2911 13.0491 12.0067 13.2975 11.6755 13.5063C11.3443 13.7152 10.9843 13.8772 10.5955 13.9924C10.2138 14.1004 9.82141 14.1544 9.41819 14.1544Z" fill="#121212"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "TarotDetailStartButton",
  props: {
    biggerPrice: {
      type: [Number, String],
      default: 0,
    },
    smallerPrice: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    biggerPriceNum() {
      return Number(this.biggerPrice);
    },
    smallerPriceNum() {
      return Number(this.smallerPrice);
    },
    formattedBiggerPrice() {
      return this.biggerPriceNum === 0 ? '-' : this.addCommaToPrice(this.biggerPriceNum);
    },
    formattedSmallerPrice() {
      return this.smallerPriceNum === 0 ? '-' : this.addCommaToPrice(this.smallerPriceNum);
    },
    totalPriceLength() {
      return (this.biggerPriceNum > 0 ? this.formattedBiggerPrice.length : 0) + this.formattedSmallerPrice.length;
    },
    iframeStyle() {
      return {
        border: 'none',
        width: `${this.iframeWidth}px`,
        height: '24px',
        display: 'inline-block',
        verticalAlign: 'middle'
      };
    },
    iframeWidth() {
      if (this.totalPriceLength <= 1) return 10;
      if (this.totalPriceLength <= 2) return 20;
      if (this.totalPriceLength <= 3) return 35;
      if (this.totalPriceLength <= 4) return 50;
      if (this.totalPriceLength <= 5) return 65;
      if (this.totalPriceLength <= 6) return 75;
      return 90; // for longer prices
    },
    computedIframeContent() {
      return `
      <html>
        <head>
          <style>
            ${this.getStyles()}
            .price-container {
              display: flex;
              align-items: center;
              justify-content: center;
              width: ${this.iframeWidth}px;
            }
            .original-price2 {
              ${this.biggerPriceNum === 0 ? 'display: none;' : ''}
            }
          </style>
          <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap" rel="stylesheet">
        </head>
        <body>
          <div class="price-container">
            ${this.biggerPriceNum !== 0 ? `<span class="original-price2">${this.formattedBiggerPrice}</span>` : ''}
            <span class="price2">${this.formattedSmallerPrice}</span>
          </div>
          <script>
            function updatePrices(data) {
              const originalPriceElement = document.querySelector('.original-price2');
              const priceContainer = document.querySelector('.price-container');
              const biggerPrice = Number(data.biggerPrice);

              if (biggerPrice === 0) {
                if (originalPriceElement) {
                  originalPriceElement.style.display = 'none';
                }
              } else {
                if (originalPriceElement) {
                  originalPriceElement.style.display = '';
                  originalPriceElement.textContent = data.biggerPrice;
                } else {
                  const newOriginalPrice = document.createElement('span');
                  newOriginalPrice.className = 'original-price2';
                  newOriginalPrice.textContent = data.biggerPrice;
                  priceContainer.insertBefore(newOriginalPrice, priceContainer.firstChild);
                }
              }

              document.querySelector('.price2').textContent = data.smallerPrice;

              // Notify parent about the new content size
              window.parent.postMessage({
                type: 'resize',
                width: document.body.scrollWidth,
                height: document.body.scrollHeight
              }, '*');
            }

            window.addEventListener('message', (event) => {
              if (event.data.type === 'updatePrices') {
                updatePrices(event.data);
              }
            });

            // Initial size notification
            window.parent.postMessage({
              type: 'resize',
              width: document.body.scrollWidth,
              height: document.body.scrollHeight
            }, '*');
          ${"<"}/script>
        </body>
      </html>
    `;
    },
  },
  methods: {
    addCommaToPrice(price) {
      return price === 0 ? '-' : price.toLocaleString('ko-KR');
    },
    goTarotBoxInputPage() {
      this.$emit('goTarotBoxInputPage');
    },
    onIframeLoad() {
      if (this.$refs.iframeRef) {
        this.$refs.iframeRef.style.width = this.$refs.iframeRef.contentWindow.document.body.scrollWidth + 'px';
        this.$refs.iframeRef.style.height = this.$refs.iframeRef.contentWindow.document.body.scrollHeight + 'px';
        this.updateIframePrices();
      }
    },
    getStyles() {
      return `
        body {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-family: 'Noto Sans KR', sans-serif;
          overflow: hidden;
        }
        .price2 {
          font-size: 18px;
          font-weight: 700;
          color: #4BD1A9;
          margin-left: 2px;
          margin-right: 2px;
        }
        .original-price2 {
          font-size: 18px;
          font-weight: 700;
          color: #207e62;
          margin-right: 2px;
          text-decoration: line-through;
          text-decoration-thickness: 2px;
        }
      `;
    },
    updateIframePrices() {
      if (this.$refs.iframeRef) {
        this.$refs.iframeRef.contentWindow.postMessage({
          type: 'updatePrices',
          biggerPrice: this.formattedBiggerPrice,
          smallerPrice: this.formattedSmallerPrice
        }, '*');
      }
    },
  },
  watch: {
    biggerPrice: 'updateIframePrices',
    smallerPrice: 'updateIframePrices',
  },
};
</script>

<style scoped>
.start-button {
  position: fixed;
  bottom: 0px;
  width: 100dvw;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px 16px 16px;
  box-sizing: border-box;
  gap: 14px;
  background-color: var(--Grayscale-Gray12);
  border-top: 0.5px solid #C9CCD1;
  font-size: 18px;
  font-weight: 700;
  transition: transform 200ms ease-in-out;
}

.click-area{
  position: absolute;
  top: 10px;
  left: 50%;
  transform : translateX(-50%);
  width: 300px;
  height: 40px;
  background: transparent;
}

.price {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  flex: 1;
}

.bigger-price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 400;
  color: #B4B4B4;
  text-decoration: line-through;
}

.smaller-price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 800;
  color: var(--Grayscale-Gray1);
}

.cta {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  height: 100%;
  background-color: var(--Color-palette-Primary-Default);
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.start-button2 {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 60px;
  background-color: var(--Color-palette-Primary-Default);
  color: var(--Grayscale-Gray12);
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 200ms ease-in-out;
}

iframe{
  margin-left: 10px;
  margin-right: 2px;
  transform: translateY(-1px);
  overflow: hidden;
}
</style>