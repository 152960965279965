<script>
import IndexResult from "./IndexResult.vue";
import PreResult from "./PreResult.vue";
import NextResult from "./NextResult.vue";

export default {
  components: {
    IndexResult,
    PreResult,
    NextResult,
  },
  props: {
    index: Number,
    max_index: Number,
    sharedLink: Boolean,
  },
  methods: {
    nextStage(){
      this.$emit('next');
    },
    preStage(){
      this.$emit('prev');
    },
  },
}
</script>

<template>
  <div :class="['footer', { 'footer-higher': sharedLink }]">
    <IndexResult :index="index" :max_index="max_index"></IndexResult>
    <div class="button">
      <PreResult @prev="preStage"> </PreResult>
      <img src="../../assets/images/output_images/button_bar.png" alt=""/>
      <NextResult :able="'true'" @next="nextStage"></NextResult>
    </div>
  </div>
</template>


<style scoped>
.footer{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100vw;
  height: 70px;

  position: fixed;
  bottom: 0;

  background: rgba(0, 0, 0, 0.60);
}

.footer-higher{
  height: 60px; /* 더 높은 높이 설정 */
}

.button{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
</style>