<script>
export default {
  name: 'IndexResult',
  props: {
    index: Number,
    max_index: Number
  },
}
</script>

<template>
  <div class="page_index">
    <div class="text">{{index}} / {{max_index}}</div>
  </div>
</template>

<style scoped>
.page_index{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 28px;
}

.text{
  display: flex;
  justify-content: center;

  color: #959595;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
</style>