<template>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="8" fill="none" :stroke="color" stroke-width="1.5"/>
      <line x1="9" y1="9" x2="9" y2="5" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
      <line x1="9" y1="9" x2="12" y2="9" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "TimerIcon",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>