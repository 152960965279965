<template>
  <div class="page" v-safe-area-top>
    <div class="contents"
         :style="{'max-height': index === max_index ? feedbackBoxVisible? 'calc(var(--vh, 1vh) * 100 - 54px - 72px - 85px - 46px - 20px)' : 'calc(var(--vh, 1vh) * 100 - 54px - 72px - 46px - 20px)' : 'calc(var(--vh, 1vh) * 100 - 54px - 46px - 20px)'}">
      <SimpleBar class="simpleBar" data-simplebar-auto-hide="false"
                 :style="{'height': index === max_index ? feedbackBoxVisible? 'calc(var(--vh, 1vh) * 100 - 54px - 72px - 85px - 46px - 20px)' : 'calc(var(--vh, 1vh) * 100 - 54px - 72px - 46px - 20px)' : 'calc(var(--vh, 1vh) * 100 - 54px - 46px - 20px)'}">
        <div class="scrollingArea">
          <img class="headImg" src="../../assets/images/output_images/result_header.png" alt=""/>
          <div class="text">
            <div class="comment_title" v-html="this.$addLineBreak(pageData.title)"></div>
            <div class="contentArea">
              <TenPropertyTable v-if="pageData.content_type==='TenPropertyTable'" :manseData="this.pageData.content_data"/>
            </div>
            <div v-if="viewFormat === 'basic_markdown'" v-html="renderedMarkdown"></div>
            <div class="comment" v-else :style="isWeb? 'padding-bottom: 100px': null" v-html="this.$addLineBreak(pageData.content)"></div>
          </div>
        </div>
      </SimpleBar>
    </div>
    <div class="lower">
      <div class="share" v-if="index === max_index">
        <div class="linkCopyButton" @click="resultLinkCopy">
          <img class="linkImage" src="../../assets/images/output_images/link.png" alt=""/>
          <div class="linkCopyButtonText">{{link_copy_button_text[language]}}</div>
        </div>
        <div class="kakaoShareButton"
             v-if="language==='ko' && buildNumber >= 75 && !isAndroidWebView"
             @click="isKakaoButtonDisabled ? null : resultKakaoShare()"
             :class="{'disabled': isKakaoButtonDisabled}">

          <div v-if="isKakaoButtonLoading" class="loading-indicator">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <template v-else>
            <img class="kakaoImage" src="../../assets/images/output_images/kakakaotalk_logo.png" alt=""/>
            <div class="kakaoShareButtonText">카톡 공유</div>
          </template>
        </div>
      </div>
      <ReviewCTA :language="language"
                 :feedback-reward="feedbackReward"
                 :history-id="historyId"
                 :hash-id="hashId"
                 :title="pageData.title"
                 v-if="sharedLink !== true && index === max_index && feedbackAvailable === 'available'"/>
      <ResultFooter :index="index" :max_index="max_index" :shared-link="sharedLink" @next="nextStage" @prev="preStage"></ResultFooter>
    </div>
  </div>
</template>

<script>
import ResultFooter from "./ResultFooter.vue";
import {mapState} from "vuex";
import SimpleBar from 'simplebar-vue';
import '@/tarot_box/pages/box_input/css/custom_simplebar.css';
import TenPropertyTable from "../output_form_types/TenPropertyTable.vue";
import ReviewCTA from "./ReviewCTA.vue";
import {marked} from "marked";

export default {
  mounted() {
    this.checkWebView();
    this.isWeb = this.$route.query.is_web;
    this.sharedLink = this.$route.query.is_share === 'true';
  },
  computed: {
    renderedMarkdown: function () {
      console.info(this.pageData.content);
      const result = marked(this.pageData.content);
      console.info(result);
      return result;
    },
    ...mapState(['feedbackBoxVisible']),
  },
  components: {
    ReviewCTA,
    TenPropertyTable,
    ResultFooter,
    SimpleBar
  },
  props: {
    language: {
      type: String,
      default: "ko",
    },
    pageData: {
      page_index: "",
      title: "",
      content: "",
      content_type: "",
      content_data: "",
    },
    feedbackAvailable: {
      type: String,
    },
    feedbackReward: Number,
    index: Number,
    max_index: Number,
    historyId: Number,
    hashId: String,
    buildNumber: Number,
    viewFormat: String,
  },
  data() {
    return {
      link_copy_button_text: {
        ko: "링크 복사",
        en: "Copy Link",
      },
      isKakaoButtonLoading: false,
      isKakaoButtonDisabled: false,
      isWeb: false,
      sharedLink: false,
      isAndroidWebView: false,
    }
  },
  methods: {
    checkWebView() {
      const userAgent = navigator.userAgent.toLowerCase();
      this.isAndroidWebView = userAgent.includes('android');
    },
    nextStage() {
      this.$emit('next');
    },
    preStage() {
      this.$emit('prev');
    },
    closePage() {
      this.$emit('close');
    },
    resultLinkCopy() {
      this.$emit('copy');
    },
    resultKakaoShare() {
      this.isKakaoButtonDisabled = true;
      this.isKakaoButtonLoading = true;
      this.$emit('share');
      setTimeout(() => {
        this.isKakaoButtonDisabled = false;
        this.isKakaoButtonLoading = false;
      }, 5000);
    },
  }
}
</script>

<style scoped>
.simpleBar{
  width: 100%;
}

.page {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);

  background: #F9F9F9;
}

.header {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 90px;
}

.contents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 52px 0 20px 20px;
  max-height: calc(var(--vh, 1vh) * 100 - 52px - 46px); /* 90px: header height, 69px: footer height*/
  overflow-y: auto;
}

.contentArea{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.scrollingArea{
  margin-right: 20px; /*20px - contents' margin right*/
}

.headImg {
  width: 73.999px;
  height: 15px;
  padding-top: 30px;
}

.linkCopyButtonText {
  color: #1E2352;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 100%;
}

.kakaoShareButtonText {
  color: #111;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 100%;
}

.linkImage {
  width: 24px;
  height: 22px;
  padding-right: 6px;
}

.kakaoImage {
  width: 25px;
  height: 22px;
  padding-right: 6px;
}

.linkCopyButton {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 109px;
  height: 40px;

  border-radius: 10px;
  border: 1.2px solid #1E2352;
  background: #FFF;
  margin-right: 10px;
}

.linkCopyButton:active {
  border: 1.2px solid #A79CEF;
  background: #D9D3FF;
}

.kakaoShareButton {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 109px;
  height: 40px;

  border-radius: 10px;
  border: 1px solid #F2D529;
  background: #FFDD12;
}

.kakaoShareButton:active {
  border: 1px solid #928434;
  background: #C2A709;
}

.share {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.comment_title {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  padding-top: 12px;
  padding-bottom: 26px;
  word-break: keep-all;
}

.comment {
  color: #000;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 25px;
  text-align: left;
  padding-bottom: 100px;
}

.lower {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 23px;

  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-bottom: 24px;
  margin-bottom: 60px;
}

.kakaoShareButton.disabled {
  opacity: 1; /* Example style for disabled state */
  pointer-events: none; /* Prevents clicks */
}

.loading-indicator {
  display: inline-block;
  position: relative;
  width: 16px; /* 변경됨 */
  height: 16px; /* 변경됨 */
  transform: translateY(-2px);

}
.loading-indicator div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px; /* 변경됨 */
  height: 16px; /* 변경됨 */
  margin: 2px; /* 변경됨 */
  border: 2px solid var(--Color-palette-Primary-Default); /* 변경됨 */
  border-radius: 50%;
  animation: loading-indicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--Color-palette-Primary-Default) transparent transparent transparent;
}
.loading-indicator div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-indicator div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-indicator div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-indicator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
