<template>
  <div class="chart-container">
    <div class="chart-title">
      <div class="title2">{{ titleText }}</div>
    </div>
    <div class="chart-description">
      <canvas ref="canvas" height="140"></canvas>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TarotDetailRatingsChart",
  components: {},
  data() {
    return {
      chartLabel: [],
      chartData: [],
      myChart: null, // 차트 인스턴스를 저장하기 위한 데이터 속성
    };
  },
  props: {
    selectedTarotBox: {
      type: Object,
    },
  },
  mounted() {
    this.updateChartData(); // 차트 데이터 계산
  },
  watch: {
    selectedTarotBox() {
      console.log('selectedTarotBox changed')
      this.myChart.destroy();
      this.$nextTick(() => {
        this.updateChartData(); // 차트 데이터 계산
      });
    },
  },
  computed: {
    ...mapGetters(["tarotBoxes"]),
    titleText() {
      const reactionStats = this.selectedTarotBox.reactionStats;
      if (!reactionStats) return '평가';

      const maxKey = Object.keys(reactionStats).reduce((a, b) => reactionStats[a] > reactionStats[b] ? a : b);
      if (maxKey === '재밌어요') return '많은 분들에게 즐거움을 드렸어요';
      if (maxKey === '도움 돼요') return '많은 분들에게 도움이 됐어요';
      return '평가';
    },
  },
  methods: {
    updateChartData() {
      const reactionStats = this.selectedTarotBox.reactionStats;
      if (!reactionStats) {
        this.chartLabels = [];
        this.chartData = [];
        return;
      }

      const emojiMap = {
        '재밌어요': '😊 재밌어요',
        '도움 돼요': '🙏 도움 돼요',
        '그냥 그래요': '👀 그냥 그래요',
        '별로네요': '👎 별로네요'
      };

      this.chartLabels = Object.keys(reactionStats).map(key => emojiMap[key]);
      this.chartData = Object.values(reactionStats);

      const maxVal = Math.max(...this.chartData);
      const backgroundColors = this.chartData.map(val => val === maxVal ? '#1E2352' : '#D9D9D9');
      const borderColors = this.chartData.map(val => val === maxVal ? '#1E2352' : '#D9D9D9');
      this.renderChart(backgroundColors, borderColors);
    },

    // renderChart(backgroundColors, borderColors) {
    //   if (this.myChart !== null) {
    //     this.myChart.destroy();
    //   }
    //   const ctx = this.$refs.canvas.getContext('2d');
    //   this.myChart = new Chart(ctx, {
    //     type: 'bar',
    //     data: {
    //       labels: this.chartLabels, // 변환된 레이블
    //       datasets: [{
    //         label: '%',
    //         data: this.chartData, // 변환된 데이터
    //         backgroundColor: backgroundColors, // 배경색 배열 적용
    //         borderColor: borderColors,
    //         borderWidth: 1,
    //         borderRadius: 6,
    //         barThickness: 24 // 바 폭 설정
    //       }]
    //     },
    //     options: {
    //       scales: {
    //         y: {
    //           beginAtZero: true,
    //           display: false,
    //         },
    //         x: {
    //           grid: {
    //             display: false,
    //             drawBorder: false,
    //             drawOnChartArea: false
    //           },
    //           ticks: {
    //             display: false
    //           },
    //           barPercentage: 1.0,
    //           categoryPercentage: 0.8,
    //         }
    //       },
    //       plugins: {
    //         legend: {
    //           display: false
    //         },
    //         tooltip: {
    //           enabled: false
    //         }
    //       },
    //       layout: {
    //         padding: {
    //           bottom: 45 // 아래쪽 여백 추가
    //         }
    //       },
    //       animation: {
    //         duration: 1500,
    //         easing: 'easeOutBounce'
    //       },
    //       hover: {
    //         mode: null
    //       },
    //       interaction: {
    //         intersect: false,
    //         mode: 'none'
    //       }
    //     },
    //     plugins: [{
    //       afterDraw: chart => {
    //         ctx.font = 'bold 18px SUIT Variable';
    //         ctx.textAlign = 'center';
    //         ctx.fillStyle = '#555';
    //
    //         chart.data.datasets.forEach((dataset, datasetIndex) => {
    //           const meta = chart.getDatasetMeta(datasetIndex);
    //           meta.data.forEach((bar, index) => {
    //             const value = dataset.data[index];
    //             const x = bar.x;
    //             const y = bar.base + 25; // 막대의 base 위치에 레이블을 배치
    //             ctx.fillText(`${value}%`, x, y);
    //           });
    //         });
    //         ctx.font = '12px SUIT Variable';
    //         ctx.fillStyle = '#555';
    //         chart.data.labels.forEach((label, index) => {
    //           const x = chart.scales.x.getPixelForTick(index) - 4;
    //           const y = chart.scales.x.bottom + 40; // 여기를 조정하여 레이블 위치 변경
    //           ctx.fillText(label, x, y);
    //         });
    //       }
    //     }]
    //   });
    // }

  }
};
</script>


<style scoped>
.chart-container {
  width: calc(100vw - 32px);
  padding: 30px 16px;
  border-bottom: 0.5px solid var(--Grayscale-Gray8);
  text-align: left;
}

.chart-title {
  margin-bottom: 10px;
}

</style>