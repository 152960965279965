<script>
export default {
  name: 'NextButton',
  data(){
    if(this.currentStep === this.totalStep){
      if(this.drawTarotCard) {
        return {
          msg: {
            ko: "타로 카드 고르기",
            en: "Pick Tarot Cards",
          },
        }
      } else {
        return {
          msg: {
            ko: "결과 보러가기",
            en: "View Results",
          },
        }
      }
    } else {
      return {
        msg: {
          ko: "다음",
          en: "Next",
        }
      }
    }
  },
  methods:{
    handleClick(){
      if(this.able){
        this.$emit('next');
      }
    }
  },
  props: {
    language: {
      type: String,
      default: "ko",
    },
    currentStep: Number,
    totalStep: Number,
    able: Boolean,
    drawTarotCard: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<template>
  <div class="button-bottom"
       :class="{able: able}" @click="handleClick">
    {{msg[language]}}
  </div>
</template>

<style scoped>
.button-bottom{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 42px;

  background: var(--Color-palette-Primary-Lighten3);
  border-radius: 8px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}

.able{
  background: var(--Color-palette-Primary-Default);
}

</style>
