<template>
  <div class="page" v-safe-area-top>
    <div class="header"/>
    <div class="contents">
      <div v-if="pageData.cardData.length" class="my_cards">
        <div class="text">{{selectedCardText[language]}}</div>
        <div class="cardsArea">
          <div v-for="i in pageData.cardData.length" :key="i">
            <img class="cardImage" :src="this.pageData.cardData[i-1].card_img_url" @click="openPopup(i)" alt=""/>
            <PopupPage v-if="popupOpen[i-1]" :card_name="pageData.cardData[i-1].name" :card_explain="pageData.cardData[i-1].desc" :card_img="pageData.cardData[i-1].card_img_url" @close="closePopup(i)"/>
          </div>
        </div>
      </div>
      <div>
        <div class="text">{{commentaryText[language]}}</div>
        <div class="listArea">
          <div class="list" v-for="i in pageData.explainOverview.length" :key="i">
            <div class="index">{{i}}</div>
            <div class="comment" v-html="this.$addLineBreak(pageData.explainOverview[i-1])"></div>
          </div>
        </div>
      </div>
    </div>
    <ResultFooter :index="index" :max_index="max_index" @next="nextStage" @prev="preStage"></ResultFooter>
  </div>
</template>

<script>
import ResultFooter from "./ResultFooter.vue";
import PopupPage from "./PopupPage.vue";
export default {
  data(){
    return{
      selectedCardText: {
        ko: "나의 카드 🔮",
        en: "Selected Tarot cards 🔮",
      },
      commentaryText: {
        ko: "이런 해석이 담겨있어요 📖",
        en: "Reading includes:",
      },
      popupOpen: [],
    };
  },
  components: {
    PopupPage,
    ResultFooter,
  },
  props: {
    language: {
      type: String,
      default: "ko"
    },
    pageData:{
      cardData:{
        name: "",
        desc: "",
        card_img_url: "",
      },
      explainOverview:[],
    },
    index: Number,
    max_index: Number,
  },
  methods: {
    nextStage(){
      this.$emit('next');
    },
    preStage(){
      this.$emit('prev');
    },
    closePage(){
      this.$emit('close');
    },
    openPopup(i){
      this.popupOpen[i-1] = true;
    },
    closePopup(i){
      this.popupOpen[i-1] = false;
    }
  },
}
</script>

<style scoped>
.page{
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  width: 100%;
  height: 100dvh;
}

.header{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 54px;
}

.contents{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: calc(54px + 27px) 24px 46px 24px; /* 90px: header height */
  max-height: calc(var(--vh, 1vh) * 100); /* 90px: header height, 69px: footer height*/
  box-sizing: border-box;
}

.my_cards{
  margin-bottom: 34px;
}

.text{
  color: #000;
  text-align: left;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  padding-bottom: 20px;
}

.cardsArea{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-left: 10px;
  overflow-x: auto;
}

.cardImage{
  width: 62px;
  height: 101px;

  margin-right: 10px;
  border-radius: 4px;
  border: 0.5px solid #E7E7E7;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.18);
}

.listArea{
  display: flex;
  flex-flow: column nowrap;
  max-height: calc(var(--vh, 1vh) * 100 - 54px - 27px - 191px - 48px - 27px - 46px); /* 317px: 위에서 떨어진 만큼 */
  overflow: auto;
  white-space: nowrap;
  padding-left: 17px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #d9d9d9;
}

.list{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 21px;

  padding-bottom: 8px;

  color: #000000;
  text-align: left;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.index{
  margin-right: 13px;
  min-width: 24px;

  text-align: center;
  color: #1E2352;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.comment{
  color: #2D2C34;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.footer{
  position: fixed;
  bottom: 0;
  width: 100vw;
}

</style>
