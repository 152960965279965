<template>
  <div class="screen">
    <div class="header-bar">
      <img id="logo" src="@/tarot_box/pages/web_checkout/assets/home-logo-white.png" alt="logo">
      <svg id="xmark" @click="goToHome" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z" fill="white"/>
      </svg>
    </div>

    <div class="greetings">
      <h1>구매 완료되었습니다</h1>
      <h3>해석이 완료되면<br>카카오톡 <strong>알림톡</strong>으로 알려드릴게요!</h3>
      <span class="notification">해석은 5분~최대 30분 소요될 수 있어요</span>
      <img id="app-push" src="@/tarot_box/pages/web_checkout/assets/apppush.png" alt="app-push">
    </div>

    <div class="gift">
      <h2>첫구매 선물을 보내드렸어요!</h2>
      <p>🍀 5,000원 상당의 포인트(클로버)를 보내드렸어요!</p>
      <p>마이타로 앱에서 다른 콘텐츠도 즐겨보세요</p>
      <img id="app-clovers" src="@/tarot_box/pages/web_checkout/assets/clovers.png" alt="app-clovers">
      <button id="open-app" @click="goAppStore">마이타로 앱에서 확인하기</button>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";
import {getTarotBoxItemsByFetch, updateWebPurchaseReferrer} from "@/tarot_box/helper/mytarot_api";
import {snakeToCamelCase} from "@/common/utils";

export default {
  name: "WebCheckoutCompletePage",
  components: {

  },
  data() {
    return {
      bgColor: '#1E2352',
      boxData: null,
      transactionLogging: false,
      purchaseWebReferrer: null,
    }
  },
  mounted() {
    //이 컴포넌트에서 뒤로가기 로직 작동하지 않도록 제어
    history.pushState(null, null, location.href);
    window.addEventListener('popstate', this.handlePopState);
    // URL 디코딩
    const decodedUrl = decodeURIComponent(window.location.href);
    const urlParams = new URLSearchParams(new URL(decodedUrl).search);
    urlParams.set('is_web', 'true');
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);

    this.getTarotBoxInfos();
    this.handleSuccess();
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
    localStorage.removeItem('webReferrer');
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'utmReferrer']),
    webReferrer() {
      return this.user && this.user.webReferrer ? this.user.webReferrer : null;
    }
  },
  methods: {
    ...mapActions(['setTarotBoxesData', 'setUserData']),
    handlePopState() {
      history.pushState(null, null, location.href);
    },
    async getTarotBoxInfos(){
      const urlParams = new URLSearchParams(new URL(decodeURIComponent(window.location.href)).search);
      const tarotBoxId = parseInt(urlParams.get('tarot_box_id'));

      let res = await getTarotBoxItemsByFetch('','', true);
      if (res.result === 'success'){
        const matchedBox = res.data.tarot_boxes.find(box => box.id === tarotBoxId);
        if (matchedBox) {
          this.boxData = snakeToCamelCase(matchedBox);
          console.log(this.boxData)
        } else {
          console.error('Matched tarot box not found');
        }
      }
    },
    //url param 'success' 에 따라 분기하는 함수
    async handleSuccess() {
      const urlParams = new URLSearchParams(new URL(decodeURIComponent(window.location.href)).search);
      const result = urlParams.get('result');
      const historyId = parseInt(urlParams.get('box_history_id'));
      const tarotBoxId = parseInt(urlParams.get('tarot_box_id'));
      const tarotUserId = parseInt(urlParams.get('tuno'));
      if (result === 'success') {
        setTimeout(() => {
          this.completeProcess();
        }, 1500);
      } else if(result === 'fail') {
        logEvent('purchase_pg_failed', {
          "tarot_box_id": tarotBoxId,
          "history_id": historyId,
        });
        this.$router.push(`/tarot_box/checkout/${tarotBoxId}?status=${result}&history_id=${historyId}&tuno=${tarotUserId}&is_web=true`);
      }
    },
    completeProcess(){
      const urlParams = new URLSearchParams(window.location.search);
      const tarotBoxId = parseInt(urlParams.get('tarot_box_id'));
      const tarotBox = this.boxData || this.tarotBoxes.find(box => box.id === tarotBoxId);
      const orderId = urlParams.get('order_id')
      const historyId = parseInt(urlParams.get('box_history_id'));
      const result = urlParams.get('result');

      if (!this.boxData) {
        this.boxData = tarotBox;
      }

      if(this.transactionLogging === false && this.boxData) {
        logEvent('purchase_completed_into', {
          "tarot_box_id": tarotBoxId,
          "tarot_box_name": this.boxData.title,
          "price": this.boxData.salePriceWon,
          "value": this.boxData.salePriceWon,
          "currency": "KRW",
          "items": tarotBoxId,
          "transaction_id": historyId,
          "web_referrer": localStorage.getItem('webReferrer'),
        });
        if(result === 'success'){
          if(this.user.webReferrer){
            console.log('user.ref', this.purchaseWebReferrer);
            this.purchaseWebReferrer = this.user.webReferrer;
          } else if(localStorage.getItem('webReferrer') !== null){
            this.purchaseWebReferrer = localStorage.getItem('webReferrer');
            console.log('local storage', this.purchaseWebReferrer);
          } else if(this.utmReferrer){
            this.purchaseWebReferrer = this.utmReferrer;
            console.log('store ref', this.purchaseWebReferrer);
          }
          updateWebPurchaseReferrer(orderId, this.purchaseWebReferrer);
          window.fbq('track', 'Purchase', {
            value: this.boxData.salePriceWon,
            currency: 'KRW',
            content_ids: [this.boxData.id],
            content_type: 'product',
            content_name: this.boxData.title,
            content_category: this.boxData.category,
          }, {eventID: orderId});
          this.transactionLogging = true;
        }
      }
    },
    goAppStore() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log(userAgent)
      logEvent('purchase_completed_popup_app',{
        "tarot_box_id": this.boxData.id,
        "tarot_box_name": this.boxData.title,
        "price": this.boxData.salePriceWon,
      });
      if (/android/i.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.onez.mytarotgpt&hl=ko&gl=US&pli=1&1z_external_browser=true';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = 'https://apps.apple.com/kr/app/id6446103793?1z_external_browser=true';
      } else {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.onez.mytarotgpt&hl=ko&gl=US&pli=1&1z_external_browser=true';
      }
    },
    goToHome() {
      logEvent('purchase_completed_popup_close',{
        "tarot_box_id": this.boxData.id,
        "tarot_box_name": this.boxData.title,
        "price": this.boxData.salePriceWon,
      });
      this.$router.push('/tarot_box/home?is_web=true');
    }
  },
}
</script>

<style scoped>
.screen {
  background: #1E2352;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
}

.header-bar{
  height: 68px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7px;
  background-color: #1E2352;
}

#logo{
  margin-left: 19px;
  height: 24px;
  width: auto;
}

#xmark{
  margin-right: 22px;
  cursor: pointer;
}

.greetings{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

h1{
  color: #FFF;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: SUIT;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

h3{
  margin-top: 12px;

  color: #D9D9D9;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: SUIT;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

h3 strong{
  color: #D8E1FF;
}

.notification{
  display: inline-flex;
  padding: 4px 9px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #D8E1FF;
  color: #1E2352;
  margin: 12px 0 12px 0;

  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: SUIT;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

#app-push{
  height: calc(var(--vh, 1vh) * 12);
}

.gift{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 40);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;

  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.08);
  text-align: left;
  animation: slideUp 0.3s ease-out;

}

.gift h2{
  margin-bottom: 10px;
  color: var(--Grayscale-Gray1, #121212);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: SUIT;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
}

.gift p{
  margin-top: 2px;
  color: #696969;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: SUIT;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

#app-clovers{
  position: absolute;
  height: calc(var(--vh, 1vh) * 40 - 140px);
  left: 50%;
  bottom: 45px;
  transform: translateX(-40%);
  z-index: 9;
}

#open-app{
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  padding: 16px 0;
  border-radius: 8px;
  background: #1E2352;
  color: #fff;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: SUIT;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  text-align: center;
  cursor: pointer;
  z-index: 9;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>