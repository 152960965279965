<script>
export default {
  name: 'PageIndex',
  props: {
    index: Number,
    max_index: Number,
  },
}
</script>

<template>
  <div class="page_index">
    <div class="text">{{index}}/{{max_index}}</div>
  </div>
</template>

<style scoped>
.page_index{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 57px;
  height: 26px;

  background: #B9B9B9;
  border-radius: 90px;
}

.text{
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  color: #FFFFFF;
}
</style>