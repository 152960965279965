<template>
  <div id="stage">
    <TarotBoxHeader  v-if="!isShare" @close-button-click="exitResultPage"/>
    <div v-if="currentStep===1">
      <OutputStart :language="language" :pageData="startPageData" :tarot-box-id="this.$route.params.boxId"
                   :index="currentStep" :max_index="explainPageData.length+2" @next="nextStep"
                   @close="closePage"></OutputStart>
    </div>
    <div v-else-if="currentStep===2">
      <ResultList :language="language" :pageData="indexPageData"
                  :index="currentStep" :max_index="explainPageData.length+2" @prev="preStage" @next="nextStep"
                  @close="closePage">
      </ResultList>
    </div>
    <div v-for="i in explainPageData.length" :key="i">
      <div v-if="currentStep===i+2">
        <ResultForm :language="language"
                    :pageData="explainPageData[i-1]"
                    :index="currentStep"
                    :max_index="explainPageData.length+2"
                    :feedbackAvailable="startPageData.feedbackRewardStatus"
                    :feedbackReward="startPageData.feedbackReward"
                    :history-id="startPageData.historyId"
                    :hash-id="startPageData.hashId"
                    :build-number="buildNumber"
                    :view-format="startPageData.viewFormat"
                    @prev="preStage"
                    @next="nextStep"
                    @close="closePage"
                    @copy="copyLink"
                    @share="shareResult">
        </ResultForm>
      </div>
    </div>
  </div>

  <div class="toast" v-show="showToast" :class="{ 'show': showToast }">
    {{ toastMessage }}
  </div>

  <ReviewPromptPopup
      :history-id="startPageData.historyId"
      :hash-id="startPageData.hashId"
      :feedback-reward="startPageData.feedbackReward"
      v-if="isReviewPopupOpen"/>
</template>

<script>
import OutputStart from "./components/output_modules/OutputStart.vue";
import {getMyManseDataByFetch, getMyTarotResultByFetch, getTarotBoxInputDataByFetch} from "@/tarot_box/helper/mytarot_api.js";
import ResultList from "./components/output_modules/ResultList.vue";
import ResultForm from "./components/output_modules/ResultForm.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import * as Sentry from "@sentry/vue";
import ReviewPromptPopup from "@/tarot_box/pages/box_result/components/ReviewPromptPopup.vue";
import TarotBoxHeader from "@/tarot_box/pages/box_input/components/TarotBoxHeader.vue";
import {logEvent, showBottomBar} from "@/tarot_box/helper/native_api";


export default {
  async mounted() {
    if (this.language === "") {
      this.language = "ko";
    }
    this.buildNumber = parseInt(this.$route.query.build_number);
    this.isShare = this.$route.query.is_share;
    this.startPageData.hashId = this.$route.query.hash_id;
    this.addSafeAreaColorTop('#FF646464');
    this.addSafeAreaColorBottom('#FF646464');
    await this.initTarotBoxResult();
    await this.initTarotBoxStartPage(this.$route.params.boxId);
    await this.preloadContentsData();
    this.$store.state.feedbackBoxVisible = true;
    window.onBackButtonClick = this.handleBackButton;
    logEvent('mytarot_result_into', {});
  },
  beforeUnmount() {
    this.previousSafeAreaColorTop();
    this.previousSafeAreaColorBottom();
    window.onBackButtonClick = null;
  },
  data() {
    return {
      buildNumber: 0,
      isShare: false,
      currentStep: 1,
      language: this.$route.params.language,
      startPageData: {
        hasHeaderImg: false,
        textColor: "#000000",
        imageObject: '',
        backgroundImage: '',
        userName: "",
        title: "",
        subTitle: "",
        historyId: null,
        hashId: null,
        feedbackRewardStatus: false,
        feedbackReward: 0,
        resultPageUrl: "",
        thumbnailImgUrl: "",
        viewFormat: "default",
      },
      defaultResultTitle: "타로 결과",
      indexPageData: {
        cardData: {
          name: "",
          desc: "",
          card_img_url: "",
        },
        explainOverview: [],
      },
      explainPageData: [
        {
          page_index: "",
          title: "",
          content: "",
        }
      ],
      isReviewPopupOpen: false,
      showToast: false,
      toastMessage: '',
    }
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes']),
    ...mapState(['feedbackBoxVisible']),
  },
  methods: {
    ...mapActions(['addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    nextStep: function () {
      if (this.currentStep !== this.explainPageData.length + 2) {
        this.currentStep++;

/*     오늘의 운세 결과페이지에서 평점팝업 -> 비활성화
        if (parseInt(this.$route.params.boxId) === 54 && this.currentStep === this.explainPageData.length + 2) {
          setTimeout(() => {
            console.log('requestInAppReview')
            requestInAppReview();
          }, 3000);
        }*/
      }
    },
    preStage() {
      this.currentStep--;
    },
    closePage: function () {
      console.log('closePage')
      window.close();
    },
    shareResult() {
      const boxId = parseInt(this.$route.params.boxId);
      console.log(boxId)
      logEvent("result_share_click", {
        "tarot_box_id": boxId,
        "tarot_box_name": this.startPageData.title,
      });

      const kakao = window.Kakao;
      console.log(this.startPageData.thumbnailImgUrl);
      kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: `${this.startPageData.userName}님의 '${this.startPageData.title}'`,
          description: this.startPageData.subTitle,
          imageUrl: this.startPageData.thumbnailImgUrl,
          link: {
            mobileWebUrl: `${this.startPageData.resultPageUrl}&1z_external_browser=true&is_share=true`,
            webUrl: `${this.startPageData.resultPageUrl}&1z_external_browser=true&is_share=true`,
          },
        },
        buttons: [
          {
            title: '타로 결과보기',
            link: {
              mobileWebUrl: this.startPageData.resultPageUrl,
              webUrl: this.startPageData.resultPageUrl
            },
          },
        ],
      })
      console.log(this.startPageData.thumbnailImgUrl);
      console.log(this.startPageData.title)
    },
    copyLink() {
      const shareUrl = this.startPageData.resultPageUrl;
      // 사용 가능한 경우 Clipboard API 사용
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(shareUrl)
            .then(() => {
              this.toastMessage = '링크가 복사되었습니다';
              this.showToast = true;
              console.log('Link copied to clipboard');
              setTimeout(() => {
                this.showToast = false;
                this.toastMessage = '';
              }, 3000);
            })
            .catch(err => {
              console.error('Error in copying text: ', err);
            });
      } else {
        // Fallback: 기존 방식 사용
        const textarea = document.createElement('textarea');
        textarea.value = shareUrl;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 9999);
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    },
    splitTableOfContentsToOverviewArray: function (str) {
      const rawStr = str.replaceAll("•", "");
      let lines = rawStr.split("\n");
      lines = lines.filter(line => line.trim() !== "");

      return lines;
    },
    async initTarotBoxResult() {
      //getting hash_id params from url using query string

      const urlParams = new URLSearchParams(window.location.search);
      let hashId = urlParams.get("hash_id");
      console.log(hashId);

      if (hashId == null) {
        Sentry.captureMessage("don't have hash_id in url")
        console.log("don't have hash_id in url");
        return;
      }

      let resultData = await getMyTarotResultByFetch(hashId);
      console.log(resultData)
      this.startPageData.userName = resultData.user_name;
      this.startPageData.title = resultData.tarot_box.title;
      this.startPageData.subTitle = resultData.tarot_box.sub_title;
      this.startPageData.historyId = resultData.tarot_box_history.id;
      this.startPageData.feedbackRewardStatus = resultData.tarot_box_history.feedback_reward_status;
      this.startPageData.feedbackReward = resultData.tarot_box_history.feedback_reward;
      this.startPageData.resultPageUrl = `${resultData.tarot_box_history.result_page_url}&is_share=true`;
      this.startPageData.viewFormat = resultData.tarot_box_history.view_format;
      if(resultData.tarot_box.thumbnail_img_url == undefined || resultData.tarot_box.thumbnail_img_url == null || resultData.tarot_box.thumbnail_img_url == ""){
        this.startPageData.resultPageUrl = 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/thumbnail_default.jpg?alt=media&token=cbd4c705-25c2-467c-a105-c48e0687e3a2'
      }else {
        this.startPageData.thumbnailImgUrl = resultData.tarot_box.thumbnail_img_url;
      }
      this.indexPageData.cardData = resultData.selected_card_data;
      this.indexPageData.explainOverview = this.splitTableOfContentsToOverviewArray(resultData.tarot_box.table_of_contents);

      this.explainPageData = resultData.tarot_box_history.result_page_data;
    },
    async initTarotBoxStartPage(tarotBoxId) {
      let resultData = await getTarotBoxInputDataByFetch(tarotBoxId);

      if (resultData.hasHeaderImg !== undefined) {
        this.startPageData.hasHeaderImg = resultData.hasHeaderImg;
      } else {
        this.hasHeaderImg = false;
      }

      if (resultData.textColor !== undefined) {
        this.startPageData.textColor = resultData.textColor;
      } else {
        this.textColor = "#000000";
      }

      this.startPageData.imageObject = resultData.startPageImgUrl.imageObject;
      this.startPageData.backgroundImage = resultData.startPageImgUrl.backgroundImage;

      if(resultData.outputPageMetaArr !== undefined) {
        for (let i = 0; i<resultData.outputPageMetaArr.length; i++) {
          let outputPageMetaArr={}

          outputPageMetaArr.title=resultData.outputPageMetaArr[i].outputContentTitle;
          outputPageMetaArr.content=resultData.outputPageMetaArr[i].outputContentDescription;
          outputPageMetaArr.content_type=resultData.outputPageMetaArr[i].outputContentType;

          this.explainPageData.splice(resultData.outputPageMetaArr[i].outputContentPageNumber - 1 + i, 0, outputPageMetaArr);
        }
      }
    },
    async preloadContentsData() {
      for (let i = 0; i < this.explainPageData.length; i++) {
        if(this.explainPageData[i].content_type !== undefined){
          if (this.explainPageData[i].content_type === "TenPropertyTable") {
            this.explainPageData[i].content_data = await this.getMyManseData();
          }
        }
      }
      console.log(this.explainPageData)
    },
    async getMyManseData() {
      const urlParams = new URLSearchParams(window.location.search);
      let hashId = urlParams.get("hash_id");

      if (hashId == null) {
        Sentry.captureMessage("don't have hash_id in url");
        console.log("don't have hash_id in url");
        return;
      }

      return await getMyManseDataByFetch(hashId);
    },
    exitResultPage() {
      if(this.startPageData.feedbackRewardStatus === 'unavailable') {
        showBottomBar();
        this.$router.go(-1);
      } else if(this.isReviewPopupOpen === false && this.$isWeb() !== true){
        this.showReviewPopup()
      } else {
        showBottomBar();
        this.$router.go(-1);
      }
    },
    showReviewPopup() {
      console.log("showReviewPopup")
      this.isReviewPopupOpen = true;
    },
    handleBackButton() {
      if (this.currentStep > 1) {
        this.currentStep--;
        return 'back';
      } else if (this.currentStep === 1) {
        setTimeout(() => {
          this.exitResultPage();
        }, 100);
        return 'back';
      }
    },
  },
  components: {
    TarotBoxHeader,
    ReviewPromptPopup,
    ResultForm,
    ResultList,
    OutputStart,
  },
}
</script>

<style scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 65vw;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 100;
  transition: opacity 0.5s, transform 0.5s;
}
.toast.show {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s, transform 0.5s;
}
</style>
