<script>
export default {
  name: 'LongTextField',
  props: {
    language: {
      type: String,
      default: "ko"
    },
    msg: String,
    initialInputData: String,
    maxInputLength: Number,
  },
  data() {
    return {
      input: this.initialInputData || '',
      exceed: 0
    };
  },
  watch: {
    input() {
      this.$emit('onInputDataChange', this.input);
    }
  },
  computed: {
    placeholderText() {
      if (this.language === 'ko') {
        return `${this.maxInputLength}자까지 입력할 수 있어요`;
      } else if (this.language === 'en') {
        return `Max ${this.maxInputLength} characters allowed`;
      }
      return `${this.maxInputLength}자까지 입력할 수 있어요`;
    },
    isExceeded() {
      return this.input.length >= this.maxInputLength;
    }
  },
  methods: {
    handleInput() {
      if (this.input.length > this.maxInputLength) {
        this.input = this.input.slice(0, this.maxInputLength);
      }
    }
  }
}
</script>

<template>
<div class="text_field">
  <textarea v-model="input" :placeholder="msg" @input="handleInput"/>
  <div class="info">
  <div>
    <div class="warning" v-if="isExceeded">{{placeholderText}}</div>
    </div>
    <div class="count">
      <span>{{input.length}}</span>/{{maxInputLength}}
    </div>
  </div>

</div>
</template>

<style scoped>
.text_field {
  display: flex;
  flex-flow: column;
  height: 209px;
  border-radius: 11px;
  border: 0.5px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
}

.info{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 18px 16px 18px;
}

.count{
  color: #7D7D7D;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.count span{
  color: #7F7F7F;
  font-weight: 500;
}

textarea{
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  padding: 18px 18px 18px 18px;

  outline:none;
  border:none;
  resize: none;
  background: none;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #000;
  font-size: 14px;

  overflow: auto;
}

input ::placeholder {
  color: #8F8F8F;
}

.warning{
  color: #FF6060;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  text-align: left;
}

</style>